// redux core
import { createAppAsyncThunk } from '@/store/redux/createAppAsyncThunk'
import { ReduxState, ReduxDispatch } from '../../../store'

// helper
import FeedHelper from '@/app/(auth)/home/_helper/FeedHelper'
import JsonError from '@/app/api/_helpers/common/JsonError'
import { feedSlice } from '../feedSlice'

const feedHelperInstance = new FeedHelper()

export const fetchFeedAsync = createAppAsyncThunk<
   any,
   { skip: number; limit: number; canonicalName: string },
   { state: ReduxState; rejectWithValue: any }
>('feed/fetchFeed', async (feedOptions, { getState, rejectWithValue, dispatch }) => {
   const { skip, limit, canonicalName } = feedOptions

   try {
      let feedAPIEndpoint = `${process.env.NEXT_PUBLIC_BASE_URL}/api/secure/feed?skip=${skip}&limit=${limit}`
      feedAPIEndpoint += canonicalName?.length ? `&canonicalName=${canonicalName}` : `&page=home`
      const feedResponse = await fetch(feedAPIEndpoint, {
         cache: 'no-store',
      })

      if (feedResponse.status === 502) {
         throw new JsonError(false, feedResponse.status, feedResponse.statusText, {
            code: '',
            message: '',
         })
      }

      const feedResponseBody = await feedResponse.json()

      if (feedResponseBody?.success === false) {
         throw { ...feedResponseBody }
      }

      const {
         feed: { feed },
      } = getState()
      dispatch(feedSlice.actions.recordFeedTotalRailCount(feedResponseBody.totalCount))

      if ('data' in feedResponseBody && feedResponseBody?.data?.rails?.length > 0) {
         return [...feed, ...feedHelperInstance.createFeedData(feedResponseBody?.data?.rails)]
      }

      return []
   } catch (err: any) {
      return rejectWithValue(err)
   }
})

type FetchFeedAsyncQueueArgs = { skip: number; limit: number; canonicalName: string }

export const fetchFeedAsyncQueue =
   ({ skip, limit, canonicalName }: FetchFeedAsyncQueueArgs) =>
   async (dispatch: ReduxDispatch) => {
      await dispatch({
         type: fetchFeedAsync.pending.type,
         meta: { queue: true },
      })

      try {
         const response = await dispatch(fetchFeedAsync({ skip, limit, canonicalName }))
         await dispatch({
            type: fetchFeedAsync.fulfilled.type,
            payload: response.payload,
            meta: { queue: true },
         })
      } catch (error: any) {
         await dispatch({
            type: fetchFeedAsync.rejected.type,
            error: error.message,
            meta: { queue: true },
         })
      }
   }
