// redux core
import type { ReduxState } from '@/store/redux'
import { createSelector } from 'reselect'

// helper
import InformationPageHelper from '@/app/_helper/global-helper/InformationPageHelper'
import CommonHelper from '@/app/_helper/global-helper/CommonHelper'

// types
import { CompiledViewAllItemProps } from './feed.d'

const informationPageHelper = new InformationPageHelper()
const commonHelper = new CommonHelper()

export const selectFeed = (state: ReduxState) => state.feed

export const selectFeedFetchStatus = (state: ReduxState) => state.feed.feedStatus
export const selectFeedAsyncError = (state: ReduxState) => state.feed.feedAsyncError

export const selectFeedAllFetched = (state: ReduxState) => state.feed.feedFetchRockBottom

export const selectComputedFeed = createSelector([selectFeed], (feedRoot) => {
   const { feed } = feedRoot
   return feed
})

// view all
export const selectViewallFetchStatus = (state: ReduxState) => state.feed.viewAllStatus
export const selectViewAllAsyncError = (state: ReduxState) => state.feed.viewAllAsyncError
export const selectViewallFetched = (state: ReduxState) => state.feed.viewAllFetchRockBottom

export const selectViewallLanding = createSelector([selectFeed], (feedRoot) => {
   const { viewAll, viewAllEpisodeWatchProgress } = feedRoot

   let computedViewAll = []

   for (let viewAllIndex = 0; viewAllIndex < viewAll.length; viewAllIndex++) {
      let computeProgress = 0
      let computeProgressPercent = 0
      let computeRemainingTime = ''
      let computeLinkAriaLabel = viewAll[viewAllIndex].card.linkAriaLabel
      let computeLink = viewAll[viewAllIndex].card.link

      const matchedEpisodeDetail = viewAllEpisodeWatchProgress?.filter(
         (episodicProgressItem: any) => episodicProgressItem.assetId === viewAll[viewAllIndex].id,
      )

      if (matchedEpisodeDetail?.length > 0) {
         computeProgress = matchedEpisodeDetail[0]?.progress
         computeProgressPercent = matchedEpisodeDetail[0]?.watchPercentage
         computeRemainingTime = informationPageHelper.calculateTimeLeft(
            matchedEpisodeDetail[0].progress,
            matchedEpisodeDetail[0].videoDuration,
         )

         // Reassign instead of redeclaring
         computeRemainingTime = commonHelper.computeAriaRemainingTime(computeRemainingTime)
         if (computeRemainingTime?.trim()?.length > 0) {
            computeLinkAriaLabel += ', ' + computeRemainingTime
         }
         computeLink = viewAll[viewAllIndex].card.link.replace(/(resume=)[^\&]+/, '$1' + computeProgress / 1000)
      }

      computedViewAll.push({
         ...viewAll[viewAllIndex],
         card: {
            ...viewAll[viewAllIndex].card,
            link: computeLink,
            progress: computeProgressPercent,
            linkAriaLabel: computeLinkAriaLabel,
         },
      })
   }

   return computedViewAll
})

// search

export const selectSearchFetchStatus = (state: ReduxState) => state.feed.searchStatus

export const selectPopularSearchStatus = (state: ReduxState) => state.feed.popularSearchStatus

export const selectSearchFetched = (state: ReduxState) => state.feed.searchFetchRockBottom

export const selectPopularSearch = (state: ReduxState) => state.feed.popularSearch

export const selectRecommendedSearch = (state: ReduxState) => state.feed.recommendedSearch

export const selectIsSearchResultsEmpty = (state: ReduxState) => state.feed.isSearchResultsEmpty

export const selectPreRouteSearchString = (state: ReduxState) => state.feed.preRouteSearchString

export const selectSearchPaginatingCount = (state: ReduxState) => state.feed.searchPaginatingCount

export const selectSearchLanding = createSelector([selectFeed], (feedRoot) => {
   const { search } = feedRoot
   return search
})
export const selectEpisodicWatchProgressStatus = (state: ReduxState) => state.feed.episodicWatchProgressStatus
export const selectViewAllWatchProgressStatus = (state: ReduxState) => state.feed.viewAllEpisodeWatchProgressAsyncStatus

// genre
export const selectGenreAssets = (state: ReduxState) => state.feed.genreAssets

// ymal
export const selectYmalList = createSelector([selectFeed], (feedRoot) => {
   const { ymal } = feedRoot
   return ymal
})
export const selectYmalFetchStatus = (state: ReduxState) => state.feed.ymalFetchStatus

// single promo
export const selectFeedPromoProgressStatus = createSelector([selectFeed], (feed) => {
   const { promoProgressStatus } = feed
   return promoProgressStatus
})

export const selectSearchAsyncError = (state: ReduxState) => state.feed.searchAsyncError
export const selectGenreAssetStatus = (state: ReduxState) => state.feed.genreAssetStatus
export const selectGenreAssetAsyncError = (state: ReduxState) => state.feed.genreAssetAsyncError
export const selectFeedTotalRailCount = (state: ReduxState) => state.feed.feedTotalRailCount - 1
