export enum RAIL_TYPE {
   POSTER_HORIZONTAL = 'h',
   POSTER_VERTICAL = 'v',
   EPISODIC_RAIL = 'eh',
   GENRE = 'g',
}

export interface IPreCompiledViewAll {
   id: number
   name: string
   duration: number
   synopsis: string
   licensor: string
   content_type: string
   release_date: string
   studio: string
   genre: Array<string>
   language: string
   origin: string
   license_start_date: string
   license_end_date: string
   properties: {
      hd_sd: string
      '4k_full_hd': string
      dolby_vision: string
      surround: string
      HDR_10: string
      dolby_atmos: string
      ad_dv: string
   }
   exec_producers: Array<string>
   actors: Array<string>
   directors: Array<string>
   writers: Array<string>
   cue_points: Array<any>
   images: {
      hero_image: string
      h_poster: string
      logo: string
      still_frame: string
      v_poster: string
   }
   tags: Array<any>
   rating: string
   advisory: Array<string>
   is_premium: boolean
   movie_ref: string
   created_at: string
   is_free: string
   is_downloadable: boolean
   playback_start_date: string
   playback_end_date: string
   coming_soon_date: string
   new_season_date: any
   series_title?: string
   season_number?: string
   episode_no?: string
   progress: number
}
