import { createApi, fetchBaseQuery } from '@reduxjs/toolkit/query/react'

// geo location availability
export const geoLocAvailabilityApiSlice = createApi({
   reducerPath: 'configSlice/geoLocAvailabilityApi',
   baseQuery: fetchBaseQuery({
      baseUrl: 'https://edge-auth.api.brightcove.com/playback/v1/accounts/6415624971001/videos',
   }),
   endpoints: (builder) => ({
      fetchGeoAvailability: builder.query({
         query: ({ assetId }) => {
            const dynamicPath = '/' + assetId
            return dynamicPath
         },
         forceRefetch() {
            return true
         },
         keepUnusedDataFor: 0,
      }),
   }),
})

export const { useLazyFetchGeoAvailabilityQuery } = geoLocAvailabilityApiSlice
