// redux core
import { createSlice, type PayloadAction } from '@reduxjs/toolkit'

// thunk
import {
   watchlistLookupAsync,
   watchlistStatusAsync,
   addToWatchlistAsync,
   removeFromWatchlistAsync,
} from './watchlistThunk'

// helper
import WatchListLandingHelper from '@/app/(auth)/watchlist/_helper/WatchListLandingHelper'

// types
import { WatchlistCollectionProps, WatchlistSliceStateProps } from './watchlist.d'

const watchListLandingHelper = new WatchListLandingHelper()

export const watchlistInitialState: WatchlistSliceStateProps = {
   watchlistCollectionStatus: 'idle',
   watchlistCollectionAsyncError: null,
   watchlistCollection: [] as WatchlistCollectionProps[],

   watchlistAllFetched: false,

   watchlistKeys: {},

   addToWatchlistStatus: 'idle',
   addToWatchlistAsyncError: null,

   removeFromWatchlistStatus: 'idle',
   removeFromWatchlistAsyncError: null,

   isInWatchlistStatus: 'idle',
   isInWatchlistAsyncError: null,
   isInWatchlist: false,
   watchedListAssetsInPromoRail: [],
}

export const watchlistSlice = createSlice({
   name: 'watchlist',
   initialState: watchlistInitialState,
   reducers: {
      resetWatchlistSlice: (state) => {
         state.watchlistCollectionStatus = 'idle'
         state.watchlistCollectionAsyncError = null
         state.watchlistCollection = []
         state.watchlistAllFetched = false
         state.watchlistKeys = {}
         state.watchedListAssetsInPromoRail = []
      },
      resetInWatchlistStatus: (state) => {
         state.isInWatchlistStatus = 'idle'
         state.isInWatchlistAsyncError = null
         state.isInWatchlist = false
         state.addToWatchlistStatus = 'idle'
         state.addToWatchlistAsyncError = null
         state.removeFromWatchlistStatus = 'idle'
         state.removeFromWatchlistAsyncError = null
      },
      resetWatchlistKeys: (state) => {
         state.watchlistKeys = {}
      },
   },
   extraReducers: (builder) => {
      builder
         .addCase(watchlistLookupAsync.pending, (state) => {
            state.watchlistCollectionStatus = 'loading'
         })
         .addCase(watchlistLookupAsync.fulfilled, (state, action) => {
            const { payload } = action
            if ('watchlistResponseBody' in payload && !!payload?.watchlistResponseBody) {
               const { watchlistResponseBody } = payload

               if ('LastEvaluatedKey' in watchlistResponseBody.data) {
                  const {
                     LastEvaluatedKey: { id, lastModified },
                  } = watchlistResponseBody.data
                  state.watchlistKeys[id] = lastModified
               } else {
                  state.watchlistAllFetched = true
                  state.watchlistKeys = {}
               }

               state.watchlistCollection = [
                  ...state.watchlistCollection,
                  ...watchListLandingHelper.createWatchlistData(watchlistResponseBody?.data?.elements),
               ]
            }
            state.watchlistCollectionStatus = 'complete'
         })
         .addCase(watchlistLookupAsync.rejected, (state, action) => {
            state.watchlistCollectionStatus = 'failed'
            state.watchlistCollectionAsyncError = action.payload
         })

         .addCase(watchlistStatusAsync.pending, (state) => {
            state.isInWatchlistStatus = 'loading'
         })
         .addCase(watchlistStatusAsync.fulfilled, (state, action) => {
            const { payload }: any = action
            if ('watchlistResponseBody' in payload && !!payload?.watchlistResponseBody) {
               const {
                  watchlistResponseBody: { data },
               } = payload
               // Check if the asset does not exist in the current state
               if (data?.assetId && !state.watchedListAssetsInPromoRail[data.assetId]) {
                  // Append the new asset to the state
                  state.watchedListAssetsInPromoRail = {
                     ...state.watchedListAssetsInPromoRail,
                     [data.assetId]: data.assetInWatchList,
                  }
               }
               state.isInWatchlist = data?.assetInWatchList
            }
            state.isInWatchlistStatus = 'complete'
         })
         .addCase(watchlistStatusAsync.rejected, (state, action) => {
            state.isInWatchlistStatus = 'failed'
            state.isInWatchlistAsyncError = action.payload
         })

         .addCase(addToWatchlistAsync.pending, (state) => {
            state.addToWatchlistStatus = 'loading'
         })
         .addCase(addToWatchlistAsync.fulfilled, (state) => {
            state.addToWatchlistStatus = 'complete'
         })
         .addCase(addToWatchlistAsync.rejected, (state, action) => {
            state.addToWatchlistStatus = 'failed'
            state.addToWatchlistAsyncError = action.payload
         })

         .addCase(removeFromWatchlistAsync.pending, (state) => {
            state.removeFromWatchlistStatus = 'loading'
         })
         .addCase(removeFromWatchlistAsync.fulfilled, (state) => {
            state.removeFromWatchlistStatus = 'complete'
         })
         .addCase(removeFromWatchlistAsync.rejected, (state, action) => {
            state.removeFromWatchlistStatus = 'failed'
            state.removeFromWatchlistAsyncError = action.payload
         })
   },
})
