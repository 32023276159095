'use client'

// redux core
import { configureStore, type ThunkAction, type Action } from '@reduxjs/toolkit'

// react redux
import {
   useSelector as useReduxSelector,
   useDispatch as useReduxDispatch,
   type TypedUseSelectorHook,
} from 'react-redux'

// root reducer
import { reducer } from './rootReducer'

import { middleware } from './middleware'

// storage
import storage from './storage'

import { persistReducer, createMigrate } from 'redux-persist'
// import expireReducer from 'redux-persist-expire'

import migrations from './migrations'

// Persist config
const persistConfig = {
   key: 'root',
   storage,
   version: 26,
   migrate: createMigrate(migrations, { debug: false }),
   whitelist: ['config', 'shared', 'user', 'player'],
   blacklist: ['feed', 'series', 'movie', 'watchlist'],
   // transforms: [
   //    expireReducer('config', {
   //       expireSeconds: 30,
   //       expiredState: configInitialState,
   //       autoExpire: true,
   //    }),
   // ],
}

const persistedReducer = persistReducer(persistConfig, reducer)

// store
export const reduxStore = configureStore({
   reducer: persistedReducer,
   middleware: (getDefaultMiddleware) => getDefaultMiddleware({ serializableCheck: false }).concat(middleware),
})

export const useDispatch = () => useReduxDispatch<ReduxDispatch>()
export const useSelector: TypedUseSelectorHook<ReduxState> = useReduxSelector

// store types
export type ReduxStore = typeof reduxStore
export type ReduxState = ReturnType<typeof reduxStore.getState>
export type ReduxDispatch = typeof reduxStore.dispatch
export type ReduxThunkAction<ReturnType = void> = ThunkAction<ReturnType, ReduxState, unknown, Action>
