// moment core
import moment from 'moment'

// lang
import languagesPlugin from 'language-list'

// utility
import { RAIL_VARIANT } from '@/app/_utility/rail-variants'
import { CONTENT_TYPE } from '@/app/_utility/content-type'
import { CONSTANTS } from '@/app/_utility/shared-constants'
import { IMAGE_SIZES } from '@/app/_utility/image-sizes'
import { BADGE_TYPE } from '@/app/_utility/badge-type'
import { EVERGENT_ERROR } from '@/app/_utility/evergent-error'
import { PAYMENT_CATEGORY } from '@/app/_utility/payment-category'
import { POSTING_STATUS } from '@/app/_utility/posting-status'
import { GA_EVENTS } from '@/app/_utility/ga-events'
import { TranslationsType } from '@/app/global-data'

export default class CommonHelper {
   static instance: CommonHelper
   static readonly moment = moment
   static readonly languagesPlugin = languagesPlugin()
   static readonly defaultLanguage = 'en'
   static readonly CONSTANTS = CONSTANTS
   static readonly IMAGE_SIZES = IMAGE_SIZES
   static readonly CONTENT_TYPE = CONTENT_TYPE
   static readonly RAIL_VARIANT = RAIL_VARIANT
   static readonly BADGE_TYPE = BADGE_TYPE
   static readonly EVERGENT_ERROR = EVERGENT_ERROR
   static readonly POSTING_STATUS = POSTING_STATUS
   static readonly PAYMENT_CATEGORY = PAYMENT_CATEGORY
   static readonly GA_EVENTS = GA_EVENTS

   TRANSLATIONS = {}

   static readonly DEFAULT_MATURITY_RATING = 'TV-MA|R'

   private readonly MOVIE_MATURITY_RATINGS: { [key: string]: number } = {
      'G': 1,
      'PG': 2,
      'PG-13': 3,
      'R': 4,
   }
   private readonly TV_MATURITY_RATINGS: { [key: string]: number } = {
      'TV-Y': 1,
      'TV-Y7': 2,
      'TV-G': 3,
      'TV-PG': 4,
      'TV-14': 5,
      'TV-MA': 6,
   }

   constructor() {
      if (CommonHelper.instance) {
         return CommonHelper.instance
      }
      CommonHelper.instance = this
   }

   debounce<T extends any[]>(func: (...args: T) => void, timeout: number = 300): (...args: T) => void {
      let timer: ReturnType<typeof setTimeout> | null = null
      return (...args: T) => {
         if (timer !== null) clearTimeout(timer)
         timer = setTimeout(() => {
            func.apply(null, args)
         }, timeout)
      }
   }

   generateNumberFromZeroToN(generateTill: number) {
      return Math.floor(Math.random() * (generateTill - 1 + 1) + 1)
   }

   convertDateWithCustomFormat(data: string, format = 'YYYY-MM-DD') {
      return moment(data).format(format)
   }

   convertDaytoSecond(day: number) {
      return day * 86400
   }

   setLocale(data: TranslationsType) {
      this.TRANSLATIONS = data
   }

   getLocale(): { [key: string]: string } {
      return this.TRANSLATIONS
   }

   computeAriaRemainingTime(remainingTime: string) {
      return `${remainingTime}`
         ?.replace('min', 'minute')
         .replace('mins', 'minutes')
         .replace('hr', 'hour')
         .replace('hrs', 'hours')
   }

   isValidPlayBackStartDate(playBackStartDate: string) {
      const playbackStart = CommonHelper.moment(playBackStartDate).utc()
      const now = CommonHelper.moment().utc()
      const startDifference = CommonHelper.moment(now).utc().diff(playbackStart) / 1000
      return startDifference >= 0 ? true : false
   }

   toTitleCase(str: string) {
      return str.replace(/\w\S*/g, (text) => text.charAt(0).toUpperCase() + text.substring(1).toLowerCase())
   }

   interpolate(string: string, values: Record<string, string>): string {
      // Create a regular expression to match all placeholders in the string.
      const regex = /\{([^}]+)\}/g
      const output = string?.replace(regex, (match: string, key: string) => {
         return values[key]
      })
      // Replace each placeholder with the corresponding value from the values object.
      return output
   }

   is4xx(errorCode: any) {
      let error4xx = /\b4(?!000)\d{2}\b/gm
      return error4xx.test(errorCode)
   }
   is5xx(errorCode: any) {
      let error5xx = /\b5(?!000)\d{2}\b/gm
      return error5xx.test(errorCode)
   }

   capitalizeText(text: string) {
      return text
         ?.split(' ')
         .map((word) => word?.charAt(0)?.toUpperCase() + word?.slice(1)?.toLowerCase())
         .join(' ')
   }

   getMovieRating() {
      return Object.keys(this.MOVIE_MATURITY_RATINGS)
   }

   getTVRating() {
      return Object.keys(this.TV_MATURITY_RATINGS)
   }

   compareRatings(
      userRating: string,
      contentRating: string,
      contentType: string,
      isParentalControlEnabled: boolean,
   ): boolean {
      const compiledUserRating =
         contentType?.toLowerCase() === 'series' ? userRating?.split('|')[0] : userRating?.split('|')[1]
      const commonRatingList =
         contentType?.toLowerCase() === 'series' ? this.TV_MATURITY_RATINGS : this.MOVIE_MATURITY_RATINGS

      const userRatingIndex = commonRatingList[compiledUserRating] ?? 0
      const contentRatingIndex = commonRatingList[contentRating] ?? 0
      return userRatingIndex >= contentRatingIndex || !isParentalControlEnabled
   }

   executeBasedOnComparison(
      condition: boolean,
      truthyCallbacks: ((...args: any) => void)[],
      falsyCallbacks: ((...args: any) => void)[],
      callbackArg: any,
   ) {
      const callbacks = condition ? truthyCallbacks : falsyCallbacks
      callbacks.forEach((callback) => callback(callbackArg))
   }

   computeMaturityRating(
      contentRating: string,
      contentType: string,
      followingUrl: string,
      userMaturityRating: string,
      isParentalControlEnabled: boolean,
      truthyCallbacks: ((...args: any) => void)[],
      falsyCallbacks: ((...args: any) => void)[],
   ) {
      switch (contentType?.toLowerCase()) {
         case 'series':
            const canNavigateSeries = this.compareRatings(
               userMaturityRating,
               contentRating,
               'series',
               isParentalControlEnabled,
            )
            this.executeBasedOnComparison(canNavigateSeries, truthyCallbacks, falsyCallbacks, followingUrl)
            break
         case 'movie':
         case 'movies':
            const canNavigateMovie = this.compareRatings(
               userMaturityRating,
               contentRating,
               'movie',
               isParentalControlEnabled,
            )
            this.executeBasedOnComparison(canNavigateMovie, truthyCallbacks, falsyCallbacks, followingUrl)
            break
         default:
            if (followingUrl?.trim()?.length > 0) {
               truthyCallbacks.forEach((callback) => callback(followingUrl))
            }
            break
      }
   }
}
