import { createAppAsyncThunk } from '@/store/redux/createAppAsyncThunk'
import { ReduxState, ReduxDispatch } from '../../../store'

export const fetchFeedEpisodicProgressAsync = createAppAsyncThunk<
   any,
   { assetIds: Array<number>; railRowIndex: number },
   { state: ReduxState; rejectWithValue: any }
>('feed/fetchEpisodicProgressAsync', async (data, { getState, rejectWithValue }) => {
   let { assetIds, railRowIndex } = data

   try {
      const response = await fetch(`${process.env.NEXT_PUBLIC_BASE_URL}/api/secure/series/seasons/watchprogress`, {
         body: JSON.stringify({
            assetIds,
         }),
         method: 'POST',
      })

      const { data } = await response.json()

      const {
         feed: { feed },
      } = getState()

      return { episodicRailProgress: data, currentEpisodicRail: feed[railRowIndex], railRowIndex }
   } catch (err: any) {
      return rejectWithValue(err)
   }
})

type FetchEpisodicRailWatchprogressAsyncArgs = { assetIds: Array<number>; railRowIndex: number }

export const fetchFeedEpisodicProgressAsyncQueue =
   ({ assetIds, railRowIndex }: FetchEpisodicRailWatchprogressAsyncArgs) =>
   async (dispatch: ReduxDispatch) => {
      await dispatch({
         type: fetchFeedEpisodicProgressAsync.pending.type,
         meta: { queue: true },
      })

      try {
         const response = await dispatch(fetchFeedEpisodicProgressAsync({ assetIds, railRowIndex }))
         await dispatch({
            type: fetchFeedEpisodicProgressAsync.fulfilled.type,
            payload: response.payload,
            meta: { queue: true },
         })
      } catch (error: any) {
         await dispatch({
            type: fetchFeedEpisodicProgressAsync.rejected.type,
            error: error.message,
            meta: { queue: true },
         })
      }
   }
