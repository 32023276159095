// helper
import CommonHelper from '@/app/_helper/global-helper/CommonHelper'
import InformationPageHelper from '@/app/_helper/global-helper/InformationPageHelper'

// utility
import { getTransformedImageUrl } from '@/app/_utility/image-transformation'
// type
import { PreCompiledYmalProps, CardItemProps, PreCompiledYmalElementProps } from './type'

// images
import movieFallback from '@/public/images/card-movie-fallback.png'
import seriesFallback from '@/public/images/card-series-fallback.png'

/**
 * ThematicHelper helps generate Thematic poster-vertical
 * related data and manage relavant calculations before
 * storing declarative data into redux store.
 */
export default class YmalHelper {
   commonHelper!: CommonHelper
   informationPageHelper: InformationPageHelper
   title: string = ''
   type: string = ''
   item = {} as CardItemProps
   elements = [] as CardItemProps[]
   isInViewDetection = false
   showViewAll = false
   playlistId = ''
   preCompiledDetail = [] as PreCompiledYmalElementProps[]

   constructor(preCompiledDetail: PreCompiledYmalProps) {
      this.commonHelper = new CommonHelper()
      this.informationPageHelper = new InformationPageHelper()
      this.preCompiledDetail = preCompiledDetail?.list
   }

   /**
    * Generates fallback image per content type
    * @param {string} contentType - content type for conditional cases
    * @returns {StaticImageData} - fallback image src object
    */
   computeFallbackImage(contentType: string) {
      return contentType === CommonHelper.CONTENT_TYPE.EPISODE || contentType === CommonHelper.CONTENT_TYPE.SERIES
         ? seriesFallback.src
         : movieFallback.src
   }

   /**
    * Generates designated image per content type along with dimensions
    * @param {object} railType - rail type for conditional cases
    * @param {object} railItem - individual rail item from rail collection
    * @returns {object} Compiled, UI declarative collection of image and dimension
    */
   computeImage(railType: string, railItem: { [key: string]: any }) {
      let dynamicImage = ''
      let dynamicImageDimension: { width: number; height: number; borderRadius: number } = {
         width: 0,
         height: 0,
         borderRadius: 0,
      }

      dynamicImage = getTransformedImageUrl(
         railItem?.images?.h_poster,
         CommonHelper.IMAGE_SIZES['poster_horizontal'].high.width,
         CommonHelper.IMAGE_SIZES['poster_horizontal'].high.height,
         CommonHelper.IMAGE_SIZES['poster_horizontal'].high.borderRadius,
      )
      dynamicImageDimension = {
         width: CommonHelper.IMAGE_SIZES['poster_horizontal'].high.width,
         height: CommonHelper.IMAGE_SIZES['poster_horizontal'].high.height,
         borderRadius: CommonHelper.IMAGE_SIZES['poster_horizontal'].high.borderRadius,
      }

      return { 'image': dynamicImage, 'dimension': dynamicImageDimension }
   }

   /**
    * Generate expected link that encompasses respective rail card
    * @param {object} railItem - individualrail item from rail collection
    * @returns {string} calculated link
    */
   computeLink(railItem: { [key: string]: any }, badgeType: string) {
      let calculatedLink = ''

      switch (railItem.content_type) {
         case CommonHelper.CONTENT_TYPE.EPISODE:
            if (badgeType === CommonHelper.BADGE_TYPE.COMINGSOON) {
               calculatedLink = `/series/${railItem?.series_ref}?isref=true`
            } else if (railItem?.isDirectVideoLink) {
               /**
                * This is temporary check, that will be
                * removed when all video url is made unique.
                */
               if (this.commonHelper.isValidPlayBackStartDate(railItem.playback_start_date)) {
                  calculatedLink = `/watch?video=${railItem.video_id}`
               } else {
                  calculatedLink = `/series/${railItem?.series_ref}?isref=true`
               }
            } else {
               if (this.commonHelper.isValidPlayBackStartDate(railItem.playback_start_date)) {
                  calculatedLink = `/watch?video=${railItem.video_id}`
               } else {
                  calculatedLink = `/watch?video=${railItem.video_id}`
               }
            }

            break
         case CommonHelper.CONTENT_TYPE.MOVIE:
            if (this.type === CommonHelper.RAIL_VARIANT.CONTINUE_WATCHING) {
               calculatedLink = `/watch?video=${railItem.video_id}`
            } else {
               calculatedLink = `/movies/${railItem.video_id || railItem.id}`
            }

            break
         case CommonHelper.CONTENT_TYPE.SERIES:
            calculatedLink = `/series/${railItem.video_id || railItem.id}`
            break
      }

      return calculatedLink
   }

   /**
    * Generate expected category and label for respective rail card
    * @param {string} playbackStartDate - day when streaming start
    * @param {string} playbackEndDate - day when streaming end
    * @param {string} comingSoonDateTime - day when streaming is yet to be started being announced
    * @returns {object} Compiled, UI declarative collection of label and category
    */
   computeTimeline(playbackStartDateTime: string, playbackEndDateTime: string, comingSoonDateTime: string) {
      const timeDifference = this.informationPageHelper.mediaReleaseTimelineDiff(
         playbackStartDateTime,
         playbackEndDateTime,
         comingSoonDateTime,
      )
      let label = ''
      let category = ''
      if (timeDifference.comingSoonDifference >= 0 && timeDifference.startDifference <= 0) {
         category = CommonHelper.BADGE_TYPE.COMINGSOON
         label = `COMING SOON`
      } else {
         const remainingDuration = Math.abs(timeDifference.endDifference)
         if (this.informationPageHelper.hasPlaybackStartedWithinXDays(playbackStartDateTime)) {
            category = CommonHelper.BADGE_TYPE.NEW
            label = `NEW`
         }
         if (
            remainingDuration > CommonHelper.CONSTANTS.DAY_IN_SECONDS &&
            remainingDuration <=
               this.commonHelper.convertDaytoSecond(InformationPageHelper.CONSTANTS.CONTENT_EXPIRY_WINDOW) &&
            Math.sign(timeDifference.endDifference) == -1
         ) {
            category = CommonHelper.BADGE_TYPE.LEAVING
            label = `LEAVING IN  ${this.informationPageHelper.getRemainingDays(remainingDuration)}  `
         } else if (
            remainingDuration <= CommonHelper.CONSTANTS.DAY_IN_SECONDS &&
            Math.sign(timeDifference.endDifference) == -1
         ) {
            category = CommonHelper.BADGE_TYPE.LEAVING
            label = 'LEAVING TODAY'
         }
      }
      return { category, label }
   }

   /**
    * Tailor raw response into UI declarative object
    * @param-none
    * @returns-none
    */
   tailoredCollection() {
      const compiledElements = []

      for (let posterIndex = 0; posterIndex < this.preCompiledDetail.length; posterIndex++) {
         const posterItem = this.preCompiledDetail[posterIndex]
         const { image, dimension } = this.computeImage(this.type, posterItem)
         const timelineAnnouncement = this.computeTimeline(
            posterItem.playback_start_date as string,
            posterItem.playback_end_date as string,
            posterItem?.coming_soon_date,
         )
         this.item = {
            contentType: posterItem.content_type,
            id: Number(posterItem.video_id),
            card: {
               link: this.computeLink(posterItem, timelineAnnouncement?.category),
               linkAriaLabel: `${this.preCompiledDetail[posterIndex].name}, ${posterIndex + 1} of ${
                  this.preCompiledDetail.length
               }`,
               picture: image,
               dimension,
               title: this.preCompiledDetail[posterIndex].name as string,
               isPlayable: this.commonHelper.isValidPlayBackStartDate(
                  this.preCompiledDetail[posterIndex].playback_start_date,
               ),
               progress: 0,
               isLazyloadDisabled: false,
               timelineAnnouncement: timelineAnnouncement,
               isContentExpired: CommonHelper.moment(posterItem.playback_end_date)
                  .utc()
                  .isBefore(CommonHelper.moment().utc()),
               playbackEndDate: posterItem.playback_end_date,
            },
         }

         compiledElements.push(this.item)
      }
      return compiledElements
   }
}
