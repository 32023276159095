// initial states per slice
import { sharedInitialState } from '../redux/slices/sharedSlice'
import { seriesInitialState } from '../redux/slices/seriesSlice'
import { movieInitialState } from '../redux/slices/movieSlice'
import { userInitialState } from '../redux/slices/userSlice'
import { configInitialState } from '../redux/slices/configSlice'
import { playerInitialState } from '../redux/slices/playerSlice'

const migrations = {
   0: (state) => {
      return {
         ...state,
         shared: sharedInitialState,
         series: seriesInitialState,
         movie: movieInitialState,
         user: userInitialState,
         config: configInitialState,
         player: playerInitialState,
      }
   },
   1: (state) => {
      return {
         ...state,
         user: {
            ...userInitialState,
            profiles: state.profiles.map((profile) => ({
               ...profile,
               playerAudioLanguage: 'en',
               playerCaptionLanguage: 'off',
            })),
         },
      }
   },
   3: (state) => {
      return {
         ...state,
         player: {
            ...playerInitialState,
            upnextTimerDisabled: false,
         },
      }
   },
   4: (state) => {
      return {
         ...state,
         player: {
            userPreferenceVolume: 0.3,
         },
      }
   },
   5: (state) => {
      return {
         ...state,
         player: {
            ...playerInitialState,
            captionIndex: 1,
         },
      }
   },
   6: (state) => {
      return {
         ...state,
         player: {
            ...playerInitialState,
         },
      }
   },
   7: (state) => {
      return {
         ...state,
         player: {
            ...playerInitialState,
         },
      }
   },
   8: (state) => {
      return {
         ...state,
         player: {
            ...playerInitialState,
         },
      }
   },
   9: (state) => {
      return {
         ...state,
         player: {
            ...playerInitialState,
         },
      }
   },
   10: (state) => {
      return {
         ...state,
         user: {
            ...userInitialState,
            profiles: state.profiles.map((profile) => ({
               ...profile,
               textTrackConfiguration: [],
            })),
         },
         player: {
            ...playerInitialState,
         },
      }
   },
   11: (state) => {
      return {
         ...state,
         user: {
            ...userInitialState,
            profiles: state.profiles.map((profile) => {
               const { textTrackSetting, ...rest } = profile

               return { textTrackConfiguration, ...rest }
            }),
         },
         player: {
            ...playerInitialState,
         },
      }
   },
   12: (state) => {
      return {
         ...state,
         user: {
            ...userInitialState,
            profiles: state.profiles.map((profile) => {
               const { textTrackSetting, ...rest } = profile
               let textTrackConfiguration = []
               return { textTrackConfiguration, ...rest }
            }),
         },
         player: {
            ...playerInitialState,
         },
      }
   },
   13: (state) => {
      return {
         ...state,
         player: {
            ...playerInitialState,
            languageCaptionsIndex: false,
         },
      }
   },
   14: (state) => {
      return {
         ...state,
         shared: {
            ...sharedInitialState,
            userLastActivityTime: null,
            isAutoPlayed: false,
         },
      }
   },
   15: (state) => {
      return {
         ...state,
         player: {
            ...playerInitialState,
            autoBackNavDisabled: true,
         },
      }
   },
   16: (state) => {
      return {
         ...state,
         player: {
            ...playerInitialState,
            upnextMeta: {
               seasonIndex: '',
               episodeIndex: '',
               episodePoster: '',
               episodeTile: '',
               episodeNumber: '',
            },
            upnextMetaAsyncStatus: 'idle',
            upnextMetaAsyncError: {},
            autoBackNavDisabled: true,
         },
      }
   },
   17: (state) => {
      return {
         ...state,
         user: {
            ...userInitialState,
            subscription: {},
            fetchSubscriptionAsyncStatus: 'idle',
            fetchSubscriptionAsyncError: null,
            paymentMethods: {},
            fetchPaymentMethodAsyncStatus: 'idle',
            fetchPaymentMethodAsyncError: null,
         },
      }
   },
   18: (state) => {
      return {
         ...state,
         shared: {
            ...sharedInitialState,
            skipBumper: false,
         },
      }
   },
   19: (state) => {
      return {
         ...state,
         shared: {
            ...sharedInitialState,
            isMaturityRestricted: false,
            maturityAccessAllowedUrl: '',
            allowUpdatingParentalControl: false,
         },
      }
   },
   20: (state) => {
      return {
         ...state,
         shared: {
            ...Object.fromEntries(
               Object.entries(sharedInitialState).filter(([key]) => key !== 'isMaturityRestricted'),
               Object.entries(sharedInitialState).filter(([key]) => key !== 'enableMaturityRatingModal'),
            ),
            isUserAllowedByMaturityRating: false,
            parentalControlAuthorised: false,
         },
      }
   },
   21: (state) => {
      return {
         ...state,
         shared: {
            ...sharedInitialState,
            didHaveParentalUrlParam: false,
         },
      }
   },
   22: (state) => {
      return {
         ...state,
         user: {
            ...userInitialState,
            customerId: '',
            subscriptionType: '',
         },
      }
   },
   23: (state) => {
      return {
         ...state,
         user: {
            ...userInitialState,
            profiles: state.profiles.map((profile) => ({
               ...profile,
               contactID: '',
            })),
         },
      }
   },
   24: (state) => {
      return {
         ...state,
         shared: {
            ...sharedInitialState,
            changePinRouteState: false,
         },
      }
   },
   25: (state) => {
      return {
         ...state,
         shared: {
            ...sharedInitialState,
            isAuthenticated: false,
         },
      }
   },
   26: (state) => {
      const { isGeoAvailability, fetchGeoAvailabilityStatus, ...otherSharedInitialStates } = sharedInitialState
      return {
         ...state,
         shared: otherSharedInitialStates,
      }
   },
}

export default migrations
