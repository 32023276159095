// redux core
import { createSelector } from 'reselect'
import type { ReduxState } from '@/store/redux'

// selectors
import { selectUserIsGuest, selectActiveUserProfile } from '../userSlice/userSelector'

export const selectShared = (state: ReduxState) => state.shared

export const selectIsAuthenticated = (state: ReduxState) => state.shared.isAuthenticated

export const selectIsOpenCastCrew = (state: ReduxState) => state.shared.isShowCastCrew

export const selectCastCrewTabIndex = (state: ReduxState) => state.shared.castCrewTabIndex

export const selectChosenSubscription = (state: ReduxState) => state.shared.subscription

export const selectPaymentStatus = (state: ReduxState) => state.shared.paymentStatus
export const selectPlanChangeAction = (state: ReduxState) => state.shared.isPlanChangeAction

export const selectCheckoutProcessingDetails = createSelector(
   [selectShared, selectUserIsGuest, selectActiveUserProfile, selectPlanChangeAction],
   (userState, isGuestUser, activeProfile, isPlanChangeAction) => {
      /* eslint-disable camelcase */
      const { subscription } = userState
      const { skuORQuickCode, productName, retailPrice, currencyCode } = subscription!
      const activeEnv = process.env.NEXT_PUBLIC_ENVIRONMENT === 'production' ? 'PROD' : 'DEV'
      const select_env = ['LOCAL', 'DEV', 'STAGE', 'STAGE-Migration', 'PROD', 'prod-test'].map((env) => ({
         value: env,
         isActive: activeEnv === env ? true : false,
      }))

      return {
         formData: {
            successCallBackURL: process.env.NEXT_PUBLIC_BASE_URL + '/payment-success',
            failureCallBackURL:
               process.env.NEXT_PUBLIC_BASE_URL +
               (isGuestUser ? '/guest/choose-plan' : isPlanChangeAction ? '/change-plan' : '/choose-plan'),
            backButtonURL:
               process.env.NEXT_PUBLIC_BASE_URL +
               (isGuestUser ? '/guest/choose-plan' : isPlanChangeAction ? '/change-plan' : '/choose-plan'),
            locale: 'en',
            sku: skuORQuickCode,
            productName,
            price: retailPrice,
            planChange: 'true',
            country: 'US',
            currency: currencyCode,
            platform: 'Web',
            name: activeProfile.firstName + ' ' + activeProfile.lastName,
            select_env,
         },
         formAction: process.env.NEXT_PUBLIC_CAPRERGENT_CHECKOUT_URL,
      }
      /* eslint-disable camelcase */
   },
)

export const selectPlayerIsNoContent = (state: ReduxState) => state.shared.isNoContent
export const selectReturnUrl = (state: ReduxState) => state.shared.returnURL
export const selectUserTrackSetting = (state: ReduxState) => state.shared.userTrackSetting
export const selectUserLastActivityTime = (state: ReduxState) => state.shared.userLastActivityTime
export const selectIsAutoPlayed = (state: ReduxState) => state.shared.isAutoPlayed
export const selectSkipBumper = (state: ReduxState) => state.shared.skipBumper
export const selectMaturityAccessAllowedUrl = (state: ReduxState) => state.shared.maturityAccessAllowedUrl
export const selectAllowUpdatingParentalControl = (state: ReduxState) => state.shared.allowUpdatingParentalControl
export const selectParentalControlAuthorised = (state: ReduxState) => state.shared.parentalControlAuthorised
export const selectDidHaveParentalUrlParam = (state: ReduxState) => state.shared.didHaveParentalUrlParam
export const selectNewEmail = (state: ReduxState) => state.shared.newEmail
export const selectChangePinRouteState = (state: ReduxState) => state.shared.changePinRouteState
