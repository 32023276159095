// redux core
import JsonError from '@/app/api/_helpers/common/JsonError'
import { createAppAsyncThunk } from '@/store/redux/createAppAsyncThunk'

export const fetchSeriesDetailAsync = createAppAsyncThunk(
   'feed/fetchSeriesDetail',
   async (series: { id: string; isAssetByRefId: boolean }, { rejectWithValue }) => {
      try {
         const { id, isAssetByRefId } = series
         const computedUrl = new URL(`${process.env.NEXT_PUBLIC_BASE_URL}/api/secure/series/${id}`)
         if (isAssetByRefId) computedUrl.searchParams.set('isref', 'true')

         const feedResponse = await fetch(computedUrl)
         if (feedResponse.status === 502) {
            throw new JsonError(false, feedResponse.status, feedResponse.statusText, {
               code: '',
               message: '',
            })
         }
         const feedResponseBody = await feedResponse.json()
         if (feedResponseBody?.success === false) {
            throw { ...feedResponseBody }
         }
         return { feedResponseBody }
      } catch (err: any) {
         return rejectWithValue(err)
      }
   },
)

export const fetchSeasonsAsync = createAppAsyncThunk(
   'series/fetchSeasons',
   async (season: { seasonIndex: string; seriesRef: string }, { rejectWithValue }) => {
      const { seasonIndex, seriesRef } = season

      try {
         const response =
            seasonIndex.toLowerCase() === 'extras'
               ? await fetch(`${process.env.NEXT_PUBLIC_BASE_URL}/api/secure/series/extras?seriesId=${seriesRef}`)
               : await fetch(
                    `${process.env.NEXT_PUBLIC_BASE_URL}/api/secure/series/seasons?seasonNumber=${seasonIndex}&seriesRef=${seriesRef}`,
                 )

         if (response.status === 502) {
            throw new JsonError(false, response.status, response.statusText, {
               code: '',
               message: '',
            })
         }

         const responseBody = await response.json()
         if (responseBody?.success === false) {
            throw { ...responseBody }
         }
         return { responseBody }
      } catch (err: any) {
         return rejectWithValue(err)
      }
   },
)

export const fetchSeriesWatchProgressAsync = createAppAsyncThunk(
   'series/fetchSeriesWatchProgress',
   async (series: { seriesRef: string }, { rejectWithValue }) => {
      const { seriesRef } = series
      try {
         const response = await fetch(
            `${process.env.NEXT_PUBLIC_BASE_URL}/api/secure/series/watchprogress?seriesRef=${seriesRef}`,
         )
         if (response.status === 502) {
            throw new JsonError(false, response.status, response.statusText, {
               code: '',
               message: '',
            })
         }
         const responseBody = await response.json()
         if (responseBody?.success === false) {
            throw { ...responseBody }
         }
         return { responseBody }
      } catch (err: any) {
         return rejectWithValue(err)
      }
   },
)

export const fetchEpisodesWatchProgressAsync = createAppAsyncThunk(
   'series/fetchEpisodesWatchProgress',
   async (season: { currentSeasonIndex: string; episodes: Array<number> }, { rejectWithValue }) => {
      const { currentSeasonIndex, episodes } = season

      try {
         const response = await fetch(`${process.env.NEXT_PUBLIC_BASE_URL}/api/secure/series/seasons/watchprogress`, {
            body: JSON.stringify({
               seasonIndex: currentSeasonIndex,
               assetIds: episodes,
            }),
            method: 'POST',
         })

         return await response.json()
      } catch (err: any) {
         return rejectWithValue(err)
      }
   },
)
