// redux core
import JsonError from '@/app/api/_helpers/common/JsonError'
import { createAppAsyncThunk } from '@/store/redux/createAppAsyncThunk'

export const fetchMovieAsync = createAppAsyncThunk(
   'feed/fetchMovieLanding',
   async (movie: { skip: number; limit: number; canonicalName: string }, { getState, rejectWithValue }) => {
      const { skip, limit, canonicalName } = movie
      const {
         shared: { translation },
      } = getState()

      try {
         let feedAPIEndpoint = `${process.env.NEXT_PUBLIC_BASE_URL}/api/secure/movie?skip=${skip}&limit=${limit}`
         feedAPIEndpoint += canonicalName?.length ? `&canonicalName=${canonicalName}` : `&page=movies`
         const feedResponse = await fetch(feedAPIEndpoint, {
            cache: 'no-store',
         })
         const feedResponseBody = await feedResponse.json()
         if (feedResponseBody?.success === false) {
            throw { ...feedResponseBody }
         }
         return feedResponseBody
      } catch (err: any) {
         return rejectWithValue(err)
      }
   },
)

export const fetchMovieDetailAsync = createAppAsyncThunk(
   'feed/fetchMovieDetail',
   async (movie: { id: string; isAssetByRefId: boolean }, { rejectWithValue }) => {
      try {
         const { id, isAssetByRefId } = movie
         const computedUrl = new URL(`${process.env.NEXT_PUBLIC_BASE_URL}/api/secure/movie/${id}`)
         if (isAssetByRefId) computedUrl.searchParams.set('isref', 'true')

         const feedResponse = await fetch(computedUrl)
         if (feedResponse.status === 502) {
            throw new JsonError(false, feedResponse.status, feedResponse.statusText, {
               code: '',
               message: '',
            })
         }
         const feedResponseBody = await feedResponse.json()

         if (feedResponseBody?.success === false) {
            throw { ...feedResponseBody }
         }
         return { feedResponseBody }
      } catch (err: any) {
         return rejectWithValue(err)
      }
   },
)

export const fetchMovieWatchProgressAsync = createAppAsyncThunk(
   'movie/fetchWatchProgress',
   async (movie: { assetId: string; profileId: string }, { rejectWithValue }) => {
      const { assetId, profileId } = movie
      try {
         const response = await fetch(`${process.env.NEXT_PUBLIC_BASE_URL}/api/secure/movie/watchprogress`, {
            body: JSON.stringify({
               assetId,
               profileId,
            }),
            method: 'POST',
         })
         const responseBody = await response.json()

         if (responseBody?.success === false) {
            throw { ...responseBody }
         }
         return { responseBody }
      } catch (err: any) {
         return rejectWithValue(err)
      }
   },
)

export const fetchMovieExtrasAsync = createAppAsyncThunk(
   'movie/fetchMovieExtras',
   async (movie: { videoId: string }, { rejectWithValue }) => {
      const { videoId } = movie

      try {
         const response = await fetch(`${process.env.NEXT_PUBLIC_BASE_URL}/api/secure/movie/extras?videoId=${videoId}`)
         const responseBody = await response.json()
         if (responseBody?.success === true) {
            return { responseBody }
         }
      } catch (err: any) {
         return rejectWithValue(err)
      }
   },
)

export const fetchMovieExtrasWatchprogressAsync = createAppAsyncThunk(
   'movie/fetchMovieExtrasWatchprogress',
   async (season: { extrasIds: Array<number> }, { rejectWithValue }) => {
      const { extrasIds } = season
      try {
         const response = await fetch(`${process.env.NEXT_PUBLIC_BASE_URL}/api/secure/movie/extras/watchprogress`, {
            body: JSON.stringify({
               extrasIds,
            }),
            method: 'POST',
         })
         const responseBody = await response.json()

         if (responseBody?.success === true) {
            return { responseBody }
         }
      } catch (err: any) {
         return rejectWithValue(err)
      }
   },
)
