declare module 'react-use-keypress'

export enum COOKIE_IDENTIFIER {
   AUTH_TOKEN = '_t',
   REFRESH_TOKEN = '_rt',
   ACCOUNT_TYPE = '_accT',
   ACCOUNT_ID = '_a',
   CUSTOMER_ID = '_c',
   PROFILES = '_prs',
   USER_ROLE = '_ur',
   ACTIVE_PROFILE = '_actP',
   AUTHORISE = '_at',
   LASTEVALUATED = '_le',
   LASTMODIFIED = '_lm',
   GEO_AVAILABILITY = '_geoa',
}

export enum PAYMENT_STATUS {
   NUETRAL = '0',
   PREPAYMENT = '1',
   POSTPAYMENT = '2',
   POSTPLANCHANGE = '3',
}

export enum USER_TYPES {
   SUBSCRIBED = 'subscribed',
   UNSUBSCRIBED = 'unsubscribed',
   NEW = 'new',
}

export enum PUBLIC_PAGES {
   SIGNIN = '/signin',
   SIGNUP = '/signup',
   CHOOSE_PLAN = '/choose-plan',
   FORGOT_PASSWORD = '/forgot-password',
   RESET_PASSWORD = '/reset-password',
   AUTHORISE = '/authorise',
   CHANGE_PASSWORD_SUCCESS = '/change-password-success',
}

export enum SECURE_PAGES {
   FEED = '/home',
   MOVIES = '/movies',
   SERIES = '/series',
   WATCH = '/watch',
   VIEWALL = '/viewall',
   SEARCH = '/search',
   GENRE = '/genre',
   ACCOUNT = '/account',
   PROFILE = '/account/profile',
   ADDPROFILE = '/account/profile/add',
   EDITPROFILE = '/account/profile/edit',
   ACTIVATE = '/activate',
   CHANGE_PLAN = '/change-plan',
   BILLING_HISTORY = '/billing-history',
   CHANGE_PASSWORD = '/account/change-password',
   MANAGE_MEMBERSHIP = '/account/membership/manage',
   MEMBERSHIP = '/account/membership',
   CANCEL_MEMBERSHIP = '/account/cancel-membership',
   CANCEL_MEMBERSHIP_REASON = '/account/cancel-membership/reason',
   CANCEL_MEMBERSHIP_SUCCESS = '/account/cancel-membership/success',
   PARENTAL_CONTROL = '/account/parental-control',
   CHANGE_EMAIL_SUCCESS = '/account/change-email-success',
   RECEIPT = '/receipt',
}

export enum SECURE_GUEST_PAGES {
   RESUME_MEMBERSHIP = '/guest/resume-membership',
   CHOOSE_PLAN = '/guest/choose-plan',
}

export enum SECURE_PAYMENT_PAGES {
   PROCESS_PAYMENT = '/processing-subscription',
   PAYMENT_SUCCESS = '/payment-success',
}

export enum CARD_CONTENT_TYPE {
   POSTER_HORIZONTAL = 'h',
   POSTER_VERTICAL = 'v',
   GENRE = 'g',
   EPISODIC_RAIL = 'eh',
}
