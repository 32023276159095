// redux core
import { createSlice, type PayloadAction } from '@reduxjs/toolkit'

// thunk
import {
   fetchSearchAsync,
   fetchPaginatedSearchAsync,
   fetchPopularSearchAsync,
   fetchGenreAssetsAsync,
   ymalLookupAsync,
} from './feedThunk'

// thunk that support queing
import { fetchFeedAsync } from './queuedFeedThunk'
import { fetchResumeWatchingAsync } from './queuedResumeWatchingThunk'
import { fetchFeedEpisodicProgressAsync } from './queuedEpisodeProgressThunk'
import { fetchViewAllAsync } from './queuedViewAllThunk'

// helper
import FeedHelper from '@/app/(auth)/home/_helper/FeedHelper'
import ViewallHelper from '@/app/(auth)/viewall/_helper/ViewallHelper'
import SearchHelper from '@/app/(auth)/search/_helper/SearchHelper'
import GenreHelper from '@/app/(auth)/genre/_helper/GenreHelper'

// types
import {
   IFeedSliceState,
   CompiledFeedCollectionProps,
   CompiledViewAllItemProps,
   CompiledSearchItemProps,
   CompiledPopularItemProps,
   CompiledGenreProps,
   CompiledYmalItemProps,
} from './feed.d'
import { fetchPromoProgressAsync } from './queuedPromoProgressThunk'
import { CONSTANTS } from '@/app/_utility/shared-constants'
import { RAIL_VARIANT } from '@/app/_utility/rail-variants'

export const feedInitialState: IFeedSliceState = {
   feedStatus: 'idle',
   feedAsyncError: null,
   feed: [] as CompiledFeedCollectionProps[],
   feedTotalRailCount: 0,
   feedFetchRockBottom: false,
   resumeWatchingStatus: 'idle',
   resumeWatchingAsyncError: null,
   resumeWatching: {} as CompiledFeedCollectionProps,

   episodicWatchProgressStatus: 'idle',
   episodicWatchProgressAsyncError: null,

   viewAllStatus: 'idle',
   viewAllAsyncError: null,
   viewAll: [] as CompiledViewAllItemProps[],
   viewAllFetchRockBottom: false,

   viewAllEpisodeWatchProgress: [] as any,
   viewAllEpisodeWatchProgressAsyncStatus: 'idle',
   viewAllEpisodeWatchProgressAsyncError: null,

   genreAssetStatus: 'idle',
   genreAssetAsyncError: null,
   genreAssets: [] as CompiledGenreProps[],

   searchStatus: 'idle',
   searchAsyncError: null,
   search: [] as CompiledSearchItemProps[],
   searchFetchRockBottom: false,
   isSearchResultsEmpty: false,
   searchPaginatingCount: 0,
   preRouteSearchString: '',

   popularSearchStatus: 'idle',
   popularSearchAsyncError: null,
   popularSearch: { title: '', data: [] as CompiledPopularItemProps[] },

   recommendedSearch: { title: '', data: [] as CompiledPopularItemProps[] },

   ymalFetchStatus: 'idle',
   ymalCollectionAsyncError: null,
   ymal: [] as CompiledYmalItemProps[],

   promoProgressStatus: 'idle',
   singlePromoProgress: [] as any,
}

const feedHelperInstance = new FeedHelper()
const viewallHelperInstance = new ViewallHelper()
const searchHelperInstance = new SearchHelper()
const genreHelperInstance = new GenreHelper()

export const feedSlice = createSlice({
   name: 'feed',
   initialState: feedInitialState,
   reducers: {
      resetFeedSlice: () => feedInitialState,

      resetViewAllSlice: (state) => {
         state.viewAllStatus = 'idle'
         state.viewAllAsyncError = null
         state.viewAll = [] as CompiledViewAllItemProps[]
         state.viewAllFetchRockBottom = false
         state.viewAllEpisodeWatchProgress = [] as any
         state.viewAllEpisodeWatchProgressAsyncStatus = 'idle'
         state.viewAllEpisodeWatchProgressAsyncError = null
      },
      resetSearchSlice: (state) => {
         state.searchStatus = 'idle'
         state.searchAsyncError = null
         state.search = [] as CompiledSearchItemProps[]
         state.searchFetchRockBottom = false
         state.isSearchResultsEmpty = false
         state.searchPaginatingCount = 0
      },
      resetPopularSearch: (state) => {
         state.popularSearchStatus = 'idle'
         state.popularSearchAsyncError = null
         state.popularSearch = { title: '', data: [] as CompiledPopularItemProps[] }
         state.recommendedSearch = { title: '', data: [] as CompiledPopularItemProps[] }
      },
      resetSearchEmpty: (state) => {
         state.isSearchResultsEmpty = false
      },
      recordPreRouteSearchQuery: (state, action) => {
         const { payload } = action
         state.preRouteSearchString = payload
      },
      resetGenre: (state) => {
         state.genreAssetStatus = 'idle'
         state.genreAssetAsyncError = null
         state.genreAssets = []
      },
      recordFeedFetchRockBottom: (state, action) => {
         state.feedFetchRockBottom = action.payload
      },
      recordViewAllFetchRockBottom: (state, action) => {
         state.viewAllFetchRockBottom = action.payload
      },
      resetYmalSlice: (state) => {
         state.ymalFetchStatus = 'idle'
         state.ymalCollectionAsyncError = null
         state.ymal = []
      },
      recordFeedTotalRailCount: (state, actions: PayloadAction<number>) => {
         state.feedTotalRailCount = actions.payload
      },
   },
   extraReducers: (builder) => {
      builder
         .addCase(fetchFeedAsync.pending, (state) => {
            state.feedStatus = 'loading'
         })
         .addCase(fetchFeedAsync.fulfilled, (state, action) => {
            const { payload } = action
            if (payload?.length > 0) {
               state.feed = payload
            } else {
               state.feedFetchRockBottom = true
            }
            state.feedStatus = 'complete'
         })
         .addCase(fetchFeedAsync.rejected, (state, action) => {
            state.feedStatus = 'failed'
            state.feedAsyncError = action.payload
         })

         .addCase(fetchResumeWatchingAsync.pending, (state) => {
            state.resumeWatchingStatus = 'loading'
         })
         .addCase(fetchResumeWatchingAsync.fulfilled, (state, action) => {
            state.resumeWatchingStatus = 'complete'
            const { payload } = action

            if (payload?.success === false) {
               throw { ...payload }
            }
            if (payload.elements?.length > 0) {
               const indexOfContinueWatching = state.feed.findIndex(
                  (oldFeedItem) => oldFeedItem.type === 'continue_watching',
               )
               state.feed.splice(indexOfContinueWatching, 1, payload)
            }
         })
         .addCase(fetchResumeWatchingAsync.rejected, (state, action) => {
            state.resumeWatchingStatus = 'failed'
            state.resumeWatchingAsyncError = action.payload
         })

         .addCase(fetchViewAllAsync.pending, (state) => {
            state.viewAllStatus = 'loading'
         })
         .addCase(fetchViewAllAsync.fulfilled, (state, action) => {
            const {
               payload: { newViewAllResults, newViewAllWatchprogressResults, railType },
            } = action
            if (newViewAllResults?.length > 0 && railType !== '') {
               state.viewAll = newViewAllResults
            } else {
               state.viewAllFetchRockBottom = true
            }

            if (newViewAllWatchprogressResults?.length > 0) {
               state.viewAllEpisodeWatchProgress = newViewAllWatchprogressResults
            }
            state.viewAllStatus = 'complete'
         })
         .addCase(fetchViewAllAsync.rejected, (state, action) => {
            state.viewAllStatus = 'failed'
            state.viewAllAsyncError = action.payload
            state.viewAllFetchRockBottom = true
         })

         .addCase(fetchSearchAsync.pending, (state) => {
            state.searchStatus = 'loading'
         })
         .addCase(fetchSearchAsync.fulfilled, (state, action) => {
            const {
               payload: {
                  searchResponseBody: { data },
                  railType,
               },
            } = action

            state.searchPaginatingCount = Math.ceil(data?.count / 30)

            if (data?.count > 0) {
               state.search = searchHelperInstance.createSearchData(railType, data?.list)
            } else {
               if (state.searchPaginatingCount === 0) {
                  state.search = []
                  state.isSearchResultsEmpty = true
               }
            }
            state.searchStatus = 'complete'
         })
         .addCase(fetchSearchAsync.rejected, (state, action) => {
            state.searchStatus = 'failed'
            if (state.searchPaginatingCount === 0) {
               state.search = []
               state.isSearchResultsEmpty = true
            }
            state.searchAsyncError = action.payload
         })

         .addCase(fetchPaginatedSearchAsync.pending, (state) => {
            state.searchStatus = 'loading'
         })
         .addCase(fetchPaginatedSearchAsync.fulfilled, (state, action) => {
            const {
               payload: {
                  searchResponseBody: { data },
                  railType,
               },
            } = action
            if (data?.count > 0) {
               state.search = [...state.search, ...searchHelperInstance.createSearchData(railType, data?.list)]
            } else {
               state.searchFetchRockBottom = true
            }
            state.searchStatus = 'complete'
         })
         .addCase(fetchPaginatedSearchAsync.rejected, (state, action) => {
            state.searchStatus = 'failed'
            if (state.searchPaginatingCount === 0) {
               state.search = []
               state.isSearchResultsEmpty = true
            }
            state.searchAsyncError = action.payload
         })

         .addCase(fetchPopularSearchAsync.pending, (state) => {
            state.popularSearchStatus = 'loading'
         })
         .addCase(fetchPopularSearchAsync.fulfilled, (state, action) => {
            const { payload } = action
            const {
               popularResponseBody: {
                  data: { rails },
               },
               railType,
            } = payload

            const popularSearch = rails.filter((raildata: any) => {
               if (raildata.rail_type === 'popular_searches') {
                  return raildata
               }
            })

            const recommendedSearch = rails.filter((raildata: any) => {
               if (raildata.rail_type === 'no_results') {
                  return raildata
               }
            })

            state.popularSearch = {
               title: popularSearch[0].title,
               data: searchHelperInstance.createPopularSearchData(railType, popularSearch[0].elements),
            }
            state.recommendedSearch = {
               title: recommendedSearch[0].title,
               data: searchHelperInstance.createPopularSearchData(railType, recommendedSearch[0].elements),
            }

            state.popularSearchStatus = 'complete'
         })
         .addCase(fetchPopularSearchAsync.rejected, (state, action) => {
            state.popularSearchStatus = 'failed'
            state.popularSearchAsyncError = action.payload
         })

         .addCase(fetchFeedEpisodicProgressAsync.pending, (state) => {
            state.episodicWatchProgressStatus = 'loading'
         })
         .addCase(fetchFeedEpisodicProgressAsync.fulfilled, (state, action) => {
            const { payload } = action
            const { episodicRailProgress, currentEpisodicRail, railRowIndex } = payload
            state.feed.splice(
               railRowIndex,
               1,
               feedHelperInstance.createEpisodicRailData(currentEpisodicRail, episodicRailProgress),
            )
            state.episodicWatchProgressStatus = 'complete'
         })
         .addCase(fetchFeedEpisodicProgressAsync.rejected, (state, action) => {
            state.episodicWatchProgressStatus = 'failed'
            state.episodicWatchProgressAsyncError = action.payload
         })

         .addCase(fetchGenreAssetsAsync.pending, (state) => {
            state.genreAssetStatus = 'loading'
         })
         .addCase(fetchGenreAssetsAsync.fulfilled, (state, action) => {
            const { payload } = action

            if (payload?.data) {
               state.genreAssets = genreHelperInstance.createGenreFeedData(payload?.data) as any
            }

            state.genreAssetStatus = 'complete'
         })
         .addCase(fetchGenreAssetsAsync.rejected, (state, action) => {
            state.genreAssetStatus = 'failed'
            state.genreAssetAsyncError = action.payload
         })

         .addCase(ymalLookupAsync.pending, (state) => {
            state.ymalFetchStatus = 'loading'
         })
         .addCase(ymalLookupAsync.fulfilled, (state, action) => {
            const { payload } = action
            if ('feedResponseBody' in payload && !!payload?.feedResponseBody) {
               const { feedResponseBody } = payload
               const data = feedHelperInstance.createYmalData(feedResponseBody.data)
               state.ymal = data
               state.ymalFetchStatus = 'complete'
            }
         })
         .addCase(ymalLookupAsync.rejected, (state, action) => {
            state.ymalFetchStatus = 'failed'
            state.ymalCollectionAsyncError = action.payload
         })

         .addCase(fetchPromoProgressAsync.pending, (state) => {
            state.promoProgressStatus = 'loading'
         })
         .addCase(fetchPromoProgressAsync.fulfilled, (state, action: any) => {
            state.promoProgressStatus = 'complete'
            const { payload } = action
            const { promoProgress, currentPromoRail, railRowIndex, movieDetail } = payload
            state.feed.splice(
               railRowIndex,
               1,
               feedHelperInstance.createPromoRailData(currentPromoRail, promoProgress, movieDetail),
            )
         })
         .addCase(fetchPromoProgressAsync.rejected, (state, action) => {
            state.promoProgressStatus = 'failed'
            state.resumeWatchingAsyncError = action.payload
         })
   },
})
