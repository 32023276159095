// redux core
import { createSlice, type PayloadAction } from '@reduxjs/toolkit'

import { videoHelper } from '@/app/(player)/watch/_services/common/VideoHelper'

// thunk
import {
   updateProfileAsync,
   fetchProfileAsync,
   switchProfileAsync,
   initialAvatarAsync,
   signOutAsync,
   getActiveSubscriptionAsync,
   getPaymentMethodsAsync,
   getReasonCodesAsync,
   reasonForCancellationAsync,
   removeSubscriptionAsync,
} from './userThunk'

// types
import {
   IUserSliceState,
   TProfile,
   TContactMessage,
   AudioLanguagePreferencesProps,
   CaptionLanguagePreferencesProps,
   TextTrackPreferenceProps,
   RestoreTextTrackPreferenceProps,
   ParentalRatingProps,
} from './user.d'
import { USER_TYPES } from '@/middleware.d'
import AccountHelper from '@/app/(auth)/account/_helper'

export const userInitialState: IUserSliceState = {
   updateProfileAsyncStatus: 'idle',
   fetchProfileAsyncStatus: 'idle',
   switchProfileAsyncStatus: 'idle',
   initialAvatarAsyncStatus: 'idle',
   userRole: USER_TYPES.NEW,
   avatar: '',
   accountId: 0,
   profiles: [],
   initialAvatar: {},
   updateProfileAsyncStatusError: null,
   fetchProfileAsyncError: null,
   switchProfileAsyncStatusError: null,
   initialAvatarStatusError: null,
   hasApiError: {},
   signOutStatus: false,
   subscription: {},
   fetchSubscriptionAsyncStatus: 'idle',
   fetchSubscriptionAsyncError: null,
   paymentMethods: [],
   fetchPaymentMethodAsyncStatus: 'idle',
   fetchPaymentMethodAsyncError: null,

   getReasonCodesAsyncStatus: 'idle',
   getReasonCodesAsyncError: null,
   getReasonCodes: [],

   reasonForCancellationAsyncStatus: 'idle',
   reasonForCancellationAsyncError: null,
   reasonForCancellation: [],

   removeSubscriptionAsyncStatus: 'idle',
   removeSubscriptionAsyncError: null,
   removeSubscriptionResponse: {},
   customerId: '',
   subscriptionType: '',
}

const accountHelperInstance = new AccountHelper()
export const userSlice = createSlice({
   name: 'user',
   initialState: userInitialState,
   reducers: {
      recordProfile: (state, actions: PayloadAction<TProfile>) => {
         const contactMessage = actions.payload
         const formattedProfiles: TContactMessage[] = []

         // retain device level pref
         for (let k = 0; k < contactMessage?.length; k++) {
            let historyPreferences = state.profiles
               .filter((profile) => profile.ID === contactMessage[k].ID)
               .map((profile) => ({
                  playerAudioLanguage: profile.playerAudioLanguage ?? 'en',
                  playerCaptionLanguage: profile.playerCaptionLanguage ?? 'off',
                  textTrackConfiguration: profile.textTrackConfiguration ?? videoHelper.TRACK_SETTING_CONFIG,
               }))

            formattedProfiles[k] = {
               ...contactMessage[k],
               ...historyPreferences[0],
            }
         }

         for (let i = 0; i < formattedProfiles.length; i++) {
            if (contactMessage[i].isPrimaryContact === true) {
               formattedProfiles[i] = {
                  ...formattedProfiles[i],
                  isActiveProfile: true,
               }
            } else {
               formattedProfiles[i] = {
                  ...formattedProfiles[i],
                  isActiveProfile: false,
               }
            }
         }
         state.profiles = formattedProfiles
      },
      recordUserRating: (state, actions: PayloadAction<Array<ParentalRatingProps>>) => {
         state.profiles.forEach((profile) => {
            const matchingData = actions.payload.find((item) => item.profileId === profile.ID)
            if (matchingData) {
               profile.parentalControlLevel = matchingData.parentalControlLevel
            }
         })
      },
      recordUserRole: (state, actions: PayloadAction<USER_TYPES>) => {
         state.userRole = actions.payload
      },
      recordAudioLanguagePreference: (state, actions: PayloadAction<AudioLanguagePreferencesProps>) => {
         const { playerAudioLanguage, profileId } = actions.payload
         const formattedProfiles: TContactMessage[] = []
         for (let i = 0; i < state.profiles.length; i++) {
            if (state.profiles[i].ID === profileId) {
               formattedProfiles[i] = {
                  ...state.profiles[i],
                  playerAudioLanguage: playerAudioLanguage,
               }
            } else {
               formattedProfiles[i] = {
                  ...state.profiles[i],
               }
            }
         }
         state.profiles = formattedProfiles
      },
      recordCaptionLanguagePreference: (state, actions: PayloadAction<CaptionLanguagePreferencesProps>) => {
         const { playerCaptionLanguage, profileId } = actions.payload
         const formattedProfiles: TContactMessage[] = []
         for (let i = 0; i < state.profiles.length; i++) {
            if (state.profiles[i].ID === profileId) {
               formattedProfiles[i] = {
                  ...state.profiles[i],
                  playerCaptionLanguage: playerCaptionLanguage,
               }
            } else {
               formattedProfiles[i] = {
                  ...state.profiles[i],
               }
            }
         }
         state.profiles = formattedProfiles
      },
      recordTextTrackPreference: (state, actions: PayloadAction<TextTrackPreferenceProps>) => {
         const { selectedLabel, selectedIndex, configurationIndex, currentTextTrackConfiguration, profileId } =
            actions.payload

         const updatedTextTrackConfiguration = currentTextTrackConfiguration.map((config, configIndex) => {
            if (configIndex === configurationIndex) {
               return {
                  ...config,
                  property: (
                     config.property as Array<{
                        label: string
                        options: Array<string | number | string[] | number[]>
                        selected: number
                     }>
                  ).map((property, propertyIndex) => {
                     if (property.label === selectedLabel) {
                        return {
                           ...property,
                           selected: selectedIndex,
                        }
                     }
                     return property
                  }),
               }
            }
            return config
         })

         const formattedProfiles: TContactMessage[] = []
         for (let i = 0; i < state.profiles.length; i++) {
            if (state.profiles[i].ID === profileId) {
               formattedProfiles[i] = {
                  ...state.profiles[i],
                  textTrackConfiguration: updatedTextTrackConfiguration,
               }
            } else {
               formattedProfiles[i] = {
                  ...state.profiles[i],
               }
            }
         }
         state.profiles = formattedProfiles
      },
      resetTextTrackPreference: (state, actions: PayloadAction<RestoreTextTrackPreferenceProps>) => {
         const { profileId } = actions.payload
         const formattedProfiles: TContactMessage[] = []
         for (let i = 0; i < state.profiles.length; i++) {
            if (state.profiles[i].ID === profileId) {
               formattedProfiles[i] = {
                  ...state.profiles[i],
                  textTrackConfiguration: videoHelper.TRACK_SETTING_CONFIG,
               }
            } else {
               formattedProfiles[i] = {
                  ...state.profiles[i],
               }
            }
         }
         state.profiles = formattedProfiles
      },
      resetUserSlice: (state) => {
         state.updateProfileAsyncStatus = 'idle'
         state.fetchProfileAsyncStatus = 'idle'
         state.switchProfileAsyncStatus = 'idle'
         state.initialAvatarAsyncStatus = 'idle'
         state.userRole = USER_TYPES.NEW
         state.avatar = ''
         state.accountId = 0
         state.initialAvatar = {}
         state.updateProfileAsyncStatusError = null
         state.fetchProfileAsyncError = null
         state.switchProfileAsyncStatusError = null
         state.initialAvatarStatusError = null
         state.hasApiError = {}
         state.subscription = {}
         state.paymentMethods = []
         state.signOutStatus = false
         state.removeSubscriptionResponse = {}
         state.customerId = ''
         state.subscriptionType = ''
      },
      resetHasAPIError: (state) => {
         state.fetchProfileAsyncStatus = 'idle'
         state.hasApiError = {}
      },
      recordCustomerId: (state, action: PayloadAction<string>) => {
         state.customerId = action.payload
      },
      recordSubscriptionType: (state, action: PayloadAction<string>) => {
         state.subscriptionType = action.payload
      },
   },
   extraReducers: (builder) => {
      builder
         .addCase(updateProfileAsync.pending, (state) => {
            state.updateProfileAsyncStatus = 'loading'
         })
         .addCase(updateProfileAsync.fulfilled, (state, action) => {
            state.updateProfileAsyncStatus = 'idle'
            const {
               meta: {
                  arg: { picUrl, profileId },
               },
               payload: { success },
            } = action
         })
         .addCase(updateProfileAsync.rejected, (state, action) => {
            state.updateProfileAsyncStatus = 'failed'
            state.updateProfileAsyncStatusError = action.payload
         })

         .addCase(fetchProfileAsync.pending, (state) => {
            state.fetchProfileAsyncStatus = 'loading'
            state.hasApiError = {}
         })
         .addCase(fetchProfileAsync.fulfilled, (state, action) => {
            state.fetchProfileAsyncStatus = 'complete'
            const {
               payload: { success, data: contactMessage, currentProfileId },
            } = action

            if (success === true) {
               const formattedProfiles: TContactMessage[] = []

               for (let k = 0; k < contactMessage?.length; k++) {
                  let historyPreferences = state.profiles
                     .filter((profile) => profile.ID === contactMessage[k].ID)
                     .map((profile) => ({
                        playerAudioLanguage: profile.playerAudioLanguage ?? 'en',
                        playerCaptionLanguage: profile.playerCaptionLanguage ?? 'off',
                        textTrackConfiguration: profile.textTrackConfiguration ?? videoHelper.TRACK_SETTING_CONFIG,
                     }))

                  formattedProfiles[k] = {
                     ...contactMessage[k],
                     ...historyPreferences[0],
                  }
               }

               for (let i = 0; i < formattedProfiles?.length; i++) {
                  // set primary profile based on existing data or isPrimaryContact flag
                  let profileMatchExpression = false
                  if (!!currentProfileId) {
                     profileMatchExpression = formattedProfiles[i].ID === currentProfileId
                  } else {
                     profileMatchExpression = formattedProfiles[i].isPrimaryContact === true
                  }

                  if (profileMatchExpression) {
                     formattedProfiles[i] = {
                        ...formattedProfiles[i],
                        isActiveProfile: true,
                     }
                  } else {
                     formattedProfiles[i] = {
                        ...formattedProfiles[i],
                        isActiveProfile: false,
                     }
                  }
               }
               state.hasApiError = {}
               state.profiles = formattedProfiles
            } else {
               const { GetContactResponseMessage } = action.payload
               if ('failureMessage' in GetContactResponseMessage) {
                  const { failureMessage } = GetContactResponseMessage
                  const { errorCode, errorMessage } = failureMessage[0]
                  state.hasApiError = {
                     success: false,
                     code: errorCode,
                     message: errorMessage,
                     requestName: 'fetchProfile',
                  }
               }
            }
         })
         .addCase(fetchProfileAsync.rejected, (state, action) => {
            state.fetchProfileAsyncStatus = 'failed'
            state.fetchProfileAsyncError = action.payload
         })

         .addCase(switchProfileAsync.pending, (state) => {
            state.switchProfileAsyncStatus = 'loading'
         })
         .addCase(switchProfileAsync.fulfilled, (state, action) => {
            state.switchProfileAsyncStatus = 'complete'
            const {
               payload: { profileId },
            } = action

            const formattedProfiles: TContactMessage[] = []
            for (let i = 0; i < state.profiles.length; i++) {
               if (state.profiles[i].ID === profileId) {
                  formattedProfiles[i] = {
                     ...state.profiles[i],
                     isActiveProfile: true,
                  }
               } else {
                  formattedProfiles[i] = {
                     ...state.profiles[i],
                     isActiveProfile: false,
                  }
               }
            }
            state.profiles = formattedProfiles
         })
         .addCase(switchProfileAsync.rejected, (state, action) => {
            state.switchProfileAsyncStatus = 'failed'
            state.fetchProfileAsyncError = action.payload
         })
         .addCase(initialAvatarAsync.pending, (state) => {
            state.initialAvatarAsyncStatus = 'loading'
         })
         .addCase(initialAvatarAsync.fulfilled, (state, action) => {
            state.initialAvatarAsyncStatus = 'complete'
            const {
               payload: { imgURL, imgKey },
            } = action
            state.initialAvatar = { imgURL, imgKey }
         })
         .addCase(initialAvatarAsync.rejected, (state, action) => {
            state.initialAvatarAsyncStatus = 'failed'
            state.initialAvatarStatusError = action.payload
         })
         .addCase(signOutAsync.fulfilled, (state, action) => {
            state.signOutStatus = true
         })
         .addCase(signOutAsync.rejected, (state, action) => {
            state.signOutStatus = false
         })

         .addCase(getActiveSubscriptionAsync.pending, (state) => {
            state.fetchSubscriptionAsyncStatus = 'loading'
         })
         .addCase(getActiveSubscriptionAsync.fulfilled, (state, action) => {
            state.fetchSubscriptionAsyncStatus = 'complete'
            const {
               payload: {
                  data: { AccountServiceMessage },
               },
            } = action

            state.subscription = accountHelperInstance.createSubscriptionData(AccountServiceMessage)
         })
         .addCase(getActiveSubscriptionAsync.rejected, (state, action) => {
            state.fetchSubscriptionAsyncStatus = 'failed'
            state.fetchSubscriptionAsyncError = action.payload
         })
         .addCase(getPaymentMethodsAsync.pending, (state) => {
            state.fetchPaymentMethodAsyncStatus = 'loading'
         })
         .addCase(getPaymentMethodsAsync.fulfilled, (state, action) => {
            state.fetchPaymentMethodAsyncStatus = 'complete'
            const {
               payload: {
                  data: { PaymentMethods },
               },
            } = action
            state.paymentMethods = accountHelperInstance.createPaymentInfoData(PaymentMethods)
         })
         .addCase(getPaymentMethodsAsync.rejected, (state, action) => {
            state.fetchPaymentMethodAsyncStatus = 'failed'
            state.fetchPaymentMethodAsyncError = action.payload
         })

         .addCase(getReasonCodesAsync.pending, (state) => {
            state.getReasonCodesAsyncStatus = 'loading'
         })
         .addCase(getReasonCodesAsync.fulfilled, (state, action) => {
            state.getReasonCodesAsyncStatus = 'complete'
            const {
               payload: {
                  data: { reasonCodes },
               },
            } = action
            state.getReasonCodes = reasonCodes
         })
         .addCase(getReasonCodesAsync.rejected, (state, action) => {
            state.getReasonCodesAsyncStatus = 'failed'
            state.getReasonCodesAsyncError = action.payload
         })

         .addCase(reasonForCancellationAsync.pending, (state) => {
            state.getReasonCodesAsyncStatus = 'loading'
         })
         .addCase(reasonForCancellationAsync.fulfilled, (state, action) => {
            state.getReasonCodesAsyncStatus = 'complete'
            const {
               payload: { data },
            } = action
            state.reasonForCancellation = data
         })
         .addCase(reasonForCancellationAsync.rejected, (state, action) => {
            state.getReasonCodesAsyncStatus = 'failed'
            state.getReasonCodesAsyncError = action.payload
         })

         .addCase(removeSubscriptionAsync.pending, (state) => {
            state.removeSubscriptionAsyncStatus = 'loading'
         })
         .addCase(removeSubscriptionAsync.fulfilled, (state, action) => {
            state.removeSubscriptionAsyncStatus = 'complete'
            const {
               payload: { data },
            } = action
            state.removeSubscriptionResponse = data
         })
         .addCase(removeSubscriptionAsync.rejected, (state, action) => {
            state.removeSubscriptionAsyncStatus = 'failed'
            state.removeSubscriptionAsyncError = action.payload
         })
   },
})
