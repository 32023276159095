// types
import { getTransformedImageUrl } from '@/app/_utility/image-transformation'
import CommonHelper from '../../global-helper/CommonHelper'
import { IPreCompiledCollectionsProps, IPreCompiledCollectionsElementProps, ICardItemProps } from './type'

/**
 * CollectionsHelper helps generate collections-rail related data and manage relavant calculations before
 * storing declarative data into redux store.
 */
export default class CollectionsHelper {
   commonHelper!: CommonHelper
   type: string = ''
   item = {} as ICardItemProps
   elements = [] as ICardItemProps[]
   preCompiledDetail = [] as IPreCompiledCollectionsElementProps[]

   constructor(preCompiledDetail: IPreCompiledCollectionsProps) {
      this.commonHelper = new CommonHelper()
      this.type = preCompiledDetail?.rail_type
      this.preCompiledDetail = preCompiledDetail?.elements
   }

   /**
    * Tailor raw response into UI declarative object
    * @param-none
    * @returns-none
    */
   tailoredCollection() {
      const compiledElements = []

      for (let posterIndex = 0; posterIndex < this.preCompiledDetail.length; posterIndex++) {
         const collectionItem = this.preCompiledDetail[posterIndex]

         this.item = {
            card: {
               link: `/viewall?id=${collectionItem.playlistId}&title=${encodeURIComponent(
                  collectionItem.titleText,
               )}&type=h`,
               linkAriaLabel: `${collectionItem.titleText}, ${posterIndex + 1} of ${this.preCompiledDetail.length}`,
               title: collectionItem.titleText,
               picture:
                  getTransformedImageUrl(
                     collectionItem.imageUrl,
                     CommonHelper.IMAGE_SIZES['poster_horizontal'].high.width,
                     CommonHelper.IMAGE_SIZES['poster_horizontal'].high.height,
                     CommonHelper.IMAGE_SIZES['poster_horizontal'].high.borderRadius,
                  ) || '/',
               genres: Array.isArray(collectionItem?.genre)
                  ? collectionItem?.genre?.join(',')
                  : collectionItem?.genre?.trim() ?? '',
               contentId: String(collectionItem?.video_id || ''),
               contentRef: '',
            },
         }
         compiledElements.push(this.item)
      }

      return {
         type: this.type,
         elements: compiledElements,
      }
   }
}
