export enum CONTENT_TYPE {
   MOVIE = 'movie',
   BANNER_MOVIE = 'movies',
   SERIES = 'series',
   MOVIE_TRAILER = 'movie_trailer',
   SERIES_TRAILER = 'series_trailer',
   TRAILER = 'trailer',
   EPISODE = 'episode',
   SPOTLIGHT = 'spotlight',
}
