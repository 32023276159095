// types
import { CardItemProps, PreCompiledPosterProps, PreCompiledGenreElementProps } from './GenreHelper'

/**
 * GenreHelper helps generate poster-horizontal related data and manage relavant calculations before
 * storing declarative data into redux store.
 */
export default class GenreHelper {
   title: string = ''
   type: string = ''
   item = {} as CardItemProps
   elements = [] as CardItemProps[]
   showViewAll = false

   preCompiledDetail = [] as PreCompiledGenreElementProps[]

   constructor(preCompiledDetail: PreCompiledPosterProps) {
      this.title = preCompiledDetail?.title
      this.type = preCompiledDetail?.rail_type
      this.preCompiledDetail = preCompiledDetail?.elements
   }

   /**
    * Tailor raw response into UI declarative object
    * @param-none
    * @returns-none
    */
   tailoredCollection() {
      const compiledElements = []

      for (let posterIndex = 0; posterIndex < this.preCompiledDetail.length; posterIndex++) {
         const posterItem = this.preCompiledDetail[posterIndex]

         this.item = {
            card: {
               link: `/genre?title=${posterItem.genreTitle}&type=${posterItem.tags}`,
               linkAriaLabel: ` ${posterItem.genreTitle}, ${posterIndex + 1} of ${this.preCompiledDetail.length}`,
               title: posterItem.genreTitle,
               picture: '/dummy.jpg',
            },
         }
         compiledElements.push(this.item)
      }

      return {
         title: this.title,
         type: this.type,
         elements: compiledElements,
      }
   }
}
