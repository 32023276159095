import CommonHelper from '@/app/_helper/global-helper/CommonHelper'

// image
import visaCardIcon from '@/public/images/visa-card-icon.svg?url'
import masterCardIcon from '@/public/images/mastercard-card-icon.svg?url'
import amexCardIcon from '@/public/images/amex-card-icon.svg?url'
import fallbackCardIcon from '@/public/images/fallback-card-icon.svg?url'
export default class AccountHelper {
   commonHelper!: CommonHelper
   static instance: AccountHelper | null = null
   numberWords = {
      '0': 'zero',
      '1': 'one',
      '2': 'two',
      '3': 'three',
      '4': 'four',
      '5': 'five',
      '6': 'six',
      '7': 'seven',
      '8': 'eight',
      '9': 'nine',
   }
   constructor() {
      if (AccountHelper.instance) {
         return AccountHelper.instance
      }
      this.commonHelper = new CommonHelper()
      AccountHelper.instance = this
   }

   private pinUpdateStatus: boolean = false
   private pinMRdateStatus: boolean = false
   private pinDisabledStatus: boolean = false

   getPinUpdatedStatus(): boolean {
      return this.pinUpdateStatus
   }

   setPinUpdatedStatus(data: boolean) {
      this.pinUpdateStatus = data
   }

   getMRUpdatedStatus(): boolean {
      return this.pinMRdateStatus
   }

   setMRUpdatedStatus(data: boolean) {
      this.pinMRdateStatus = data
   }

   getPinDisabledStatus(): boolean {
      return this.pinDisabledStatus
   }

   setPinDisabledStatus(data: boolean) {
      this.pinDisabledStatus = data
   }

   getIAP(pyamentMethod: string) {
      let isIAP = false
      switch (pyamentMethod) {
         case 'Amazon App Store Billing':
            isIAP = true
            break
         case 'Roku Payment':
            isIAP = true
            break
         default:
            isIAP = false
      }
      return isIAP
   }
   createSubscriptionData(activeSubscriptionResponse: any) {
      const activePlan = activeSubscriptionResponse[0]
      const subscriptionData = {
         currentPlan: activePlan?.displayName,
         currencySymbol: activePlan?.currencySymbol,
         retailPrice: activePlan?.retailPrice,
         nextBillingDate: this.commonHelper.convertDateWithCustomFormat(activePlan?.validityTill, 'MMMM DD, YYYY'),
         isManageSubscription: this.getIAP(activePlan?.paymentMethod) ? false : true,
         isIAP: this.getIAP(activePlan?.paymentMethod),
         paymentMethod: activePlan?.paymentMethod,
         productId: activePlan?.prodAttribute,
         serviceID: activePlan?.serviceID,
         serviceType: activePlan?.serviceType,
         ordProdId: activePlan?.opId,
      }
      return subscriptionData
   }

   createPaymentInfoData(paymentInfoResponse: any) {
      const activePaymentInfo = paymentInfoResponse[0]
      const subscriptionData = {
         cardNumber: this.replaceAndAddSpace(activePaymentInfo.CardInfoMessage.cardNumber),
         cardTypeImage: this.getCardImage(activePaymentInfo.CardInfoMessage.cardType),
         cardType: activePaymentInfo.CardInfoMessage.cardType,
         ariaLabel: this.generateAriaLabel(activePaymentInfo.CardInfoMessage.cardNumber),
      }
      return subscriptionData
   }

   getCardImage(cardType: string) {
      switch (cardType) {
         case 'Visa':
            return visaCardIcon
         case 'MasterCard':
            return masterCardIcon
         case 'American Express':
            return amexCardIcon
         default:
            return fallbackCardIcon
      }
   }

   replaceAndAddSpace(input: string) {
      let result = input.replace(/x/g, '*')
      let spacedResult = ''
      for (let i = 0; i < result.length; i++) {
         spacedResult += result[i]
         if ((i + 1) % 4 === 0 && i !== result.length - 1) {
            spacedResult += ' '
         }
      }
      return spacedResult
   }

   maskEmail(email: string) {
      const [username, domain] = email.split('@')

      let maskedUsername
      if (username.length > 4) {
         // Show first 2 and last 2 characters, mask the rest
         maskedUsername = `${username.slice(0, 2)}${'*'.repeat(username.length - 4)}${username.slice(-2)}`
      } else if (username.length > 1) {
         // Show first character, mask the rest
         maskedUsername = `${username.charAt(0)}${'*'.repeat(username.length - 1)}`
      } else {
         // Single character username, show it as is
         maskedUsername = username
      }

      return `${maskedUsername}@${domain}`
   }

   // Converts last 4 digits of the card number into words
   readNumber(number: string) {
      const digits = number.toString().split('')
      const words = digits.map((digit) => this.numberWords[digit as keyof typeof this.numberWords])
      return words.join(' ')
   }

   // Generate the aria-label in the format '**** **** **** four two four two'
   generateAriaLabel(cardNumber: string) {
      const lastFourDigits = cardNumber.slice(-4)
      const numberAsWords = this.readNumber(lastFourDigits)
      return `**** **** **** ${numberAsWords}`
   }
}
