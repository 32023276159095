// redux core
import { createAppAsyncThunk } from '@/store/redux/createAppAsyncThunk'

export const watchlistLookupAsync = createAppAsyncThunk(
   'watchlist/fetchWatchlistLookup',
   async (series: { limit: number; lastEvaluatedKey?: string; lastModifiedKey?: string }, { rejectWithValue }) => {
      const { limit, lastEvaluatedKey, lastModifiedKey } = series

      try {
         const watchlistResponse = await fetch(
            `${process.env.NEXT_PUBLIC_BASE_URL}/api/secure/watchlist?type=lookup&limit=${limit}&le=${lastEvaluatedKey}&lm=${lastModifiedKey}`,
         )
         const watchlistResponseBody = await watchlistResponse.json()

         if (watchlistResponseBody?.success === false) {
            throw { ...watchlistResponseBody }
         }

         return { watchlistResponseBody }
      } catch (err: any) {
         return rejectWithValue(err)
      }
   },
)

export const watchlistStatusAsync = createAppAsyncThunk(
   'watchlist/fetchWatchlistStatus',
   async (watchlist: { id: string }, { rejectWithValue }) => {
      const { id } = watchlist

      try {
         const watchlistResponse = await fetch(
            `${process.env.NEXT_PUBLIC_BASE_URL}/api/secure/watchlist?type=status&assetId=${id}`,
         )
         const watchlistResponseBody = await watchlistResponse.json()
         if (watchlistResponseBody?.success === false) {
            throw { ...watchlistResponseBody }
         }
         return { watchlistResponseBody }
      } catch (err: any) {
         return rejectWithValue(err)
      }
   },
)

export const addToWatchlistAsync = createAppAsyncThunk(
   'watchlist/addToWatchlist',
   async (watchlist: { id: string }, { rejectWithValue }) => {
      const { id } = watchlist
      try {
         const watchlistResponse = await fetch(`${process.env.NEXT_PUBLIC_BASE_URL}/api/secure/watchlist`, {
            body: JSON.stringify({ assetId: id }),
            method: 'POST',
         })
         const watchlistResponseBody = await watchlistResponse.json()

         if (watchlistResponseBody?.success === false) {
            throw { ...watchlistResponseBody }
         }
         return { watchlistResponseBody }
      } catch (err: any) {
         return rejectWithValue(err)
      }
   },
)

export const removeFromWatchlistAsync = createAppAsyncThunk(
   'watchlist/removeFromWatchlist',
   async (watchlist: { id: string }, { rejectWithValue }) => {
      const { id } = watchlist

      try {
         const watchlistResponse = await fetch(`${process.env.NEXT_PUBLIC_BASE_URL}/api/secure/watchlist`, {
            body: JSON.stringify({ assetId: id }),
            method: 'DELETE',
         })
         const watchlistResponseBody = await watchlistResponse.json()

         if (watchlistResponseBody?.success === false) {
            throw { ...watchlistResponseBody }
         }
         return { watchlistResponseBody }
      } catch (err: any) {
         return rejectWithValue(err)
      }
   },
)
