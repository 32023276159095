// redux core
import { createSlice, type PayloadAction } from '@reduxjs/toolkit'

// helper
import MovieHelper from '@/app/(auth)/movies/_helper/MovieHelper'
import MovieInformationPageHelper from '@/app/(auth)/movies/_helper/MovieInformationPageHelper'

// thunk
import {
   fetchMovieAsync,
   fetchMovieWatchProgressAsync,
   fetchMovieExtrasAsync,
   fetchMovieExtrasWatchprogressAsync,
   fetchMovieDetailAsync,
} from './movieThunk'

// types
import { IMovieSliceState, TWatchProgress } from './movie.d'
import { fetchPromoProgressAsync } from './queuedPromoProgressThunk'
import FeedHelper from '@/app/(auth)/home/_helper/FeedHelper'

const movieHelperInstance = new MovieHelper()
const movieInformationPageHelper = new MovieInformationPageHelper()
const feedHelperInstance = new FeedHelper()
export const movieInitialState: IMovieSliceState = {
   status: 'idle',

   movieLandingStatus: 'idle',
   movieLandingAsyncError: null,
   movieLanding: [],
   movieLandingFetchRockBottom: false,

   extrasStatus: 'idle',
   extrasAsyncError: null,
   extras: [],

   extrasWatchProgressStatus: 'idle',
   extrasWatchProgressAsyncError: null,
   extrasWatchProgress: [],

   watchProgressStatus: 'idle',
   watchProgressAsyncError: null,
   watchProgress: {} as TWatchProgress,

   movieDetail: {} as any,
   movieDetailAsyncStatus: 'idle',
   movieDetailAsyncError: null,
   promoProgressStatus: 'idle',
   resumeWatchingAsyncError: null,
}

export const movieSlice = createSlice({
   name: 'movie',
   initialState: movieInitialState,
   reducers: {
      resetMovieSlice: () => movieInitialState,
   },
   extraReducers: (builder) => {
      builder
         .addCase(fetchMovieAsync.pending, (state) => {
            state.movieLandingStatus = 'loading'
         })
         .addCase(fetchMovieAsync.fulfilled, (state, action) => {
            state.movieLandingStatus = 'complete'
            const { payload } = action
            if ('data' in payload && payload?.data?.rails?.length > 0) {
               state.movieLanding = [
                  ...state.movieLanding,
                  ...movieHelperInstance.createMovieFeedData(payload?.data?.rails),
               ]
            } else {
               state.movieLandingFetchRockBottom = true
            }
         })
         .addCase(fetchMovieAsync.rejected, (state, action) => {
            state.movieLandingStatus = 'failed'
            state.movieLandingAsyncError = action.payload
         })

         .addCase(fetchMovieWatchProgressAsync.pending, (state) => {
            state.watchProgressStatus = 'loading'
         })
         .addCase(fetchMovieWatchProgressAsync.fulfilled, (state, action) => {
            const {
               payload: { responseBody },
            } = action
            state.watchProgress = movieInformationPageHelper.createMovieExtrasProgress(responseBody.data)
            state.watchProgressStatus = 'complete'
         })
         .addCase(fetchMovieWatchProgressAsync.rejected, (state, action) => {
            state.watchProgressStatus = 'failed'
            state.watchProgressAsyncError = action.payload
         })

         .addCase(fetchMovieExtrasAsync.pending, (state) => {
            state.extrasStatus = 'loading'
         })
         .addCase(fetchMovieExtrasAsync.fulfilled, (state, action) => {
            if (action.payload) {
               const {
                  payload: { responseBody },
               }: any = action

               state.extras = movieInformationPageHelper.createMovieExtras(responseBody.data)
            }
            state.extrasStatus = 'complete'
         })
         .addCase(fetchMovieExtrasAsync.rejected, (state, action) => {
            state.extrasStatus = 'failed'
            state.extrasAsyncError = action.payload
         })

         .addCase(fetchMovieExtrasWatchprogressAsync.pending, (state) => {
            state.extrasWatchProgressStatus = 'loading'
         })
         .addCase(fetchMovieExtrasWatchprogressAsync.fulfilled, (state, action) => {
            if (action.payload) {
               const { payload }: any = action
               if ('responseBody' in payload && !!payload?.responseBody) {
                  const {
                     responseBody: { data },
                  } = payload
                  state.extrasWatchProgress = data ?? []
               }
            }
            state.extrasWatchProgressStatus = 'complete'
         })
         .addCase(fetchMovieExtrasWatchprogressAsync.rejected, (state, action) => {
            state.extrasWatchProgressStatus = 'failed'
            state.extrasAsyncError = action.payload
         })

         .addCase(fetchMovieDetailAsync.pending, (state) => {
            state.movieDetailAsyncStatus = 'loading'
         })
         .addCase(fetchMovieDetailAsync.fulfilled, (state, action) => {
            state.movieDetailAsyncStatus = 'complete'
            const {
               payload: { feedResponseBody },
            } = action
            state.movieDetail = movieInformationPageHelper.createInfomationPageData(feedResponseBody.data)
         })
         .addCase(fetchMovieDetailAsync.rejected, (state, action) => {
            state.movieDetailAsyncStatus = 'failed'
            state.movieDetailAsyncError = action.payload
         })

         // single promo
         .addCase(fetchPromoProgressAsync.pending, (state) => {
            state.promoProgressStatus = 'loading'
         })
         .addCase(fetchPromoProgressAsync.fulfilled, (state, action: any) => {
            state.promoProgressStatus = 'complete'
            const { payload } = action
            const { promoProgress, currentPromoRail, railRowIndex, movieDetail } = payload
            state.movieLanding.splice(
               railRowIndex,
               1,
               feedHelperInstance.createPromoRailData(currentPromoRail, promoProgress, movieDetail),
            )
         })
         .addCase(fetchPromoProgressAsync.rejected, (state, action) => {
            state.promoProgressStatus = 'failed'
            state.resumeWatchingAsyncError = action.payload
         })
   },
})
