// browser-detect core
import browser from 'browser-detect'

export const getTransformedImageUrl = (
   url: string,
   width: number,
   height: number,
   borderRadius: number,
   usePNG?: boolean,
) => {
   if (/^(https?):\/\/[^\s$.?#].[^\s]*$/.test(url) === false) return ''

   let dyamicStr = 'upload/'

   dyamicStr += width ? `w_${width},` : ''
   dyamicStr += height ? `h_${height}` : ''
   if (borderRadius) {
      dyamicStr += borderRadius ? `,r_${borderRadius}` : ''
   }
   let transformedImg = url.replace(/upload/g, dyamicStr)

   if (browser()?.name !== 'safari') {
      transformedImg = transformedImg.replace(/.jpg/g, '.webp')

      if (!usePNG) {
         transformedImg = transformedImg.replace(/.png/g, '.webp')
      }
   }
   return transformedImg
}
