'use client'

// redux core
import { createSlice, type PayloadAction } from '@reduxjs/toolkit'

// types
import { PlayerSliceStateProps, TextTrackFormattingOptionsProps } from './player.d'

// thunk
import { recordUpNextMetaAsync } from './playerThunk'

export const playerInitialState: PlayerSliceStateProps = {
   userPreferenceVolume: 30,
   captionIndex: 1,
   languageCaptionsIndex: false,
   textTrackFormattingOptions: {} as TextTrackFormattingOptionsProps,
   upnextMeta: {
      seasonIndex: '',
      episodeIndex: '',
      episodePoster: '',
      episodeTile: '',
      episodeNumber: '',
   },

   upnextMetaAsyncStatus: 'idle',
   upnextMetaAsyncError: {},
   autoBackNavDisabled: true,
   nextEpisodeMeta: {
      episodeNumber: '',
   },
}

export const playerSlice = createSlice({
   name: 'player',
   initialState: playerInitialState,
   reducers: {
      recordUserPreferenceVolume: (state, { payload }) => {
         state.userPreferenceVolume = payload
      },
      recordCaptionsIndex: (state, { payload }) => {
         state.captionIndex = payload
      },
      recordlLanguageCaptionsIndex: (state, { payload }) => {
         state.languageCaptionsIndex = payload
      },
      recordTextTrackFormattingOptions: (state, { payload }) => {
         state.textTrackFormattingOptions = payload
      },
      recordDisableAutoBackNavigation: (state, { payload }) => {
         state.autoBackNavDisabled = payload
      },
      recordMainAssetDetails: (state, { payload }) => {
         state.mainAssetDetails = payload
      },
      resetPlayerSlice: (state) => {
         state.captionIndex = 1
         state.languageCaptionsIndex = false
         state.textTrackFormattingOptions = {} as TextTrackFormattingOptionsProps
         state.upnextMeta = {
            seasonIndex: '',
            episodeIndex: '',
            episodePoster: '',
            episodeTile: '',
            episodeNumber: '',
         }
         ;(state.nextEpisodeMeta = {
            episodeNumber: '',
         }),
            (state.upnextMetaAsyncStatus = 'idle')
         state.upnextMetaAsyncError = {}
      },
   },
   extraReducers: (builder) => {
      builder
         .addCase(recordUpNextMetaAsync.pending, (state) => {
            state.upnextMetaAsyncStatus = 'loading'
         })
         .addCase(recordUpNextMetaAsync.fulfilled, (state, action) => {
            state.upnextMetaAsyncStatus = 'complete'
            const { payload } = action
            if (Object.keys(payload?.data ?? {})?.length > 0) {
               state.upnextMeta = {
                  seasonIndex: payload?.data?.custom_fields?.season_number,
                  episodeIndex: payload?.data?.custom_fields?.episode_no,
                  episodePoster: payload?.data?.custom_fields?.still_frame,
                  episodeTile: payload?.data?.name,
                  episodeNumber: payload?.data?.id,
               }
            }
            if (Object.keys(payload?.data ?? {})?.length > 0) {
               state.nextEpisodeMeta = {
                  episodeNumber: payload?.data?.id,
               }
            } else {
               state.nextEpisodeMeta = {
                  episodeNumber: ' ',
               }
            }
         })
         .addCase(recordUpNextMetaAsync.rejected, (state, action) => {
            state.upnextMetaAsyncStatus = 'failed'
            state.upnextMetaAsyncError = action.payload
         })
   },
})
