// redux core
import type { ReduxState } from '@/store/redux'
import { createSelector } from 'reselect'

export const selectPlayer = (state: ReduxState) => state.player
export const selectUserPreferenceVolume = (state: ReduxState) => state.player.userPreferenceVolume
export const selectCaptionIndex = (state: ReduxState) => state.player.captionIndex
export const selectLanguageCaptionsIndex = (state: ReduxState) => state.player.languageCaptionsIndex
export const selectTextTrackFormattingOptions = (state: ReduxState) => state.player.textTrackFormattingOptions
export const selectUpnextMeta = (state: ReduxState) => state.player.upnextMeta
export const selectNextEpisodeMeta = (state: ReduxState) => state.player.nextEpisodeMeta
export const selectIsAutoBackNavDisabled = (state: ReduxState) => state.player.autoBackNavDisabled
export const selectMainAssetDetails = (state: ReduxState) => state.player.mainAssetDetails
