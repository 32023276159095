// helpers
import CommonHelper from '@/app/_helper/global-helper/CommonHelper'
import InformationPageHelper from '@/app/_helper/global-helper/InformationPageHelper'
import PosterHelper from '@/app/_helper/rail-helper/PosterHelper'

// types
import { IPreCompiledWatchlist } from './WatchListLandingHelper'

export default class WatchlistHelper {
   static instance: WatchlistHelper | null = null
   commonHelper!: CommonHelper
   informationPageHelper!: InformationPageHelper
   posterHelper!: PosterHelper

   constructor() {
      if (WatchlistHelper.instance) {
         return WatchlistHelper.instance
      }

      this.commonHelper = new CommonHelper()
      this.informationPageHelper = new InformationPageHelper()

      WatchlistHelper.instance = this
   }

   createSecondaryText(preCompiledWatchlist: IPreCompiledWatchlist) {
      if (preCompiledWatchlist.content_type === 'movie') {
         return [
            !!preCompiledWatchlist?.duration &&
               this.informationPageHelper.getRoundedDuration(preCompiledWatchlist.duration!),
            !!preCompiledWatchlist?.release_date && CommonHelper.moment(preCompiledWatchlist.release_date).year(),
            !!preCompiledWatchlist?.rating && preCompiledWatchlist.rating,
         ]
            .filter(Boolean)
            .join(' · ')
      } else {
         return [
            preCompiledWatchlist?.seasonCount &&
               this.informationPageHelper.createSeasonCount(preCompiledWatchlist.seasonCount!),
            !!preCompiledWatchlist?.rating && preCompiledWatchlist.rating,
         ]
            .filter(Boolean)
            .join(' · ')
      }
   }

   createWatchlistData(preCompiledWatchlist: IPreCompiledWatchlist[]) {
      let computedWatchlistItem = []
      for (let watchlist of preCompiledWatchlist) {
         /* eslint-disable camelcase */
         this.posterHelper = new PosterHelper({
            rail_type: CommonHelper.RAIL_VARIANT.POSTER_HORIZONTAL,
            elements: [
               {
                  primaryText: watchlist.title, // watchlist.title,
                  secondaryText: this.createSecondaryText(watchlist), // this.createSecondaryText(watchlist),
                  video_id: watchlist.assetId,
                  name: watchlist.title,
                  images: {
                     still_frame: watchlist?.hPoster,
                     h_poster: watchlist?.hPoster,
                  },
                  isHavingFallbackImage: true,
                  ...watchlist,
               },
            ],
            banners: [],
            id: '',
            playlistId: '',
            title: '',
            showViewAll: false,
         })
         /* eslint-disable camelcase */
         computedWatchlistItem.push(this.posterHelper.tailoredCollection()?.elements[0])
      }
      return computedWatchlistItem
   }
}
