// redux core
import { combineReducers } from '@reduxjs/toolkit'

// slices
import {
   seriesSlice,
   movieSlice,
   sharedSlice,
   playerSlice,
   userSlice,
   feedSlice,
   configSlice,
   watchlistSlice,
   billingHistoryApiSlice,
   parentalControlPinApiSlice,
   logoutApiSlice,
   geoLocAvailabilityApiSlice,
} from './slices'

export const reducer = combineReducers({
   config: configSlice.reducer,
   series: seriesSlice.reducer,
   movie: movieSlice.reducer,
   shared: sharedSlice.reducer,
   player: playerSlice.reducer,
   user: userSlice.reducer,
   [billingHistoryApiSlice.reducerPath]: billingHistoryApiSlice.reducer,
   [parentalControlPinApiSlice.reducerPath]: parentalControlPinApiSlice.reducer,
   [logoutApiSlice.reducerPath]: logoutApiSlice.reducer,
   [geoLocAvailabilityApiSlice.reducerPath]: geoLocAvailabilityApiSlice.reducer,
   feed: feedSlice.reducer,
   watchlist: watchlistSlice.reducer,
})
