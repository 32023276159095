import moment from 'moment'

class VideoHelper {
   private static instance: VideoHelper

   public videoFastSize = 15
   public fullscreenDiv: any
   public thumbnail: any
   private backNavigationUrl = ''

   private constructor() {}

   public readonly CAPTION_COLORS: Readonly<{ [key: string]: string | number | string[] | number[] }> = {
      COLOR_WHITE: ['#FFFFFF', 'White'],
      COLOR_BLACK: ['#000000', 'Black'],
      COLOR_RED: ['#FF0000', 'Red'],
      COLOR_GREEN: ['#00ff00', 'Green'],
      COLOR_BLUE: ['#0000ff', 'Blue'],
      COLOR_YELLOW: ['#ffff00', 'Yellow'],
      OPACITY_OPAQUE: ['1', 'Opaque'],
      OPACITY_SEMI: ['0.5', 'Semi-Transparent'],
      OPACITY_TRANS: ['0', 'Transparent'],
   }

   public readonly CAPTION_OPACITY: Readonly<{ [key: string]: string | number | string[] | number[] }> = {
      OPACITY_OPAQUE: ['1', 'Opaque'],
      OPACITY_SEMI: ['0.5', 'Semi-Transparent'],
      OPACITY_TRANS: ['0', 'Transparent'],
   }

   public readonly CAPTION_EDGE: Readonly<{ [key: string]: string | number | string[] | number[] }> = {
      EDGE_NONE: ['none', 'None'],
      EDGE_RAISED: ['raised', 'Raised'],
      OPACITY_TRANS: ['depressed', 'Depressed'],
      EDGE_UNIFORM: ['uniform', 'Uniform'],
      EDGE_DROPSHADOW: ['dropshadow', 'Dropshadow'],
   }
   public readonly CAPTION_SIZE: Readonly<{ [key: string]: string | number | string[] | number[] }> = {
      SIZE_SMALL: ['1.875rem', 'Small'],
      SIZE_MEDIUM: ['2.25rem', 'Medium'],
      SIZE_LARGE: ['3.125rem', 'Large'],
   }

   public readonly INITIAL_CONFIGURATION: Readonly<{
      textColor: string
      textOpacity: string
      textSize: string
      textEdgeStyle: string
      textBgColor: string
      textBgOpacity: string
      caBgColor: string
      caBgOpacity: string
   }> = {
      textColor: (this.CAPTION_COLORS.COLOR_WHITE as string[])[0],
      textOpacity: (this.CAPTION_OPACITY.OPACITY_OPAQUE as string[])[0],
      textSize: (this.CAPTION_SIZE.SIZE_MEDIUM as string[])[0],
      textEdgeStyle: (this.CAPTION_EDGE.EDGE_NONE as string[])[0],
      textBgColor: (this.CAPTION_COLORS.COLOR_BLACK as string[])[0],
      textBgOpacity: (this.CAPTION_OPACITY.OPACITY_OPAQUE as string[])[0],
      caBgColor: (this.CAPTION_COLORS.COLOR_WHITE as string[])[0],
      caBgOpacity: (this.CAPTION_OPACITY.OPACITY_TRANS as string[])[0],
   }

   public readonly TRACK_SETTING_CONFIG: Readonly<
      Array<{
         [key: string]:
            | string
            | Array<{ label: string; options: Array<string | number | string[] | number[]>; selected: number }>
      }>
   > = [
      {
         title: 'Text',
         property: [
            {
               label: 'Color',
               options: [
                  this.CAPTION_COLORS.COLOR_WHITE,
                  this.CAPTION_COLORS.COLOR_BLACK,
                  this.CAPTION_COLORS.COLOR_RED,
                  this.CAPTION_COLORS.COLOR_GREEN,
                  this.CAPTION_COLORS.COLOR_BLUE,
                  this.CAPTION_COLORS.COLOR_YELLOW,
               ],
               selected: 0,
            },
            {
               label: 'Opacity',
               options: [this.CAPTION_OPACITY.OPACITY_OPAQUE, this.CAPTION_OPACITY.OPACITY_SEMI],
               selected: 0,
            },
            {
               label: 'Font Size',
               options: [this.CAPTION_SIZE.SIZE_SMALL, this.CAPTION_SIZE.SIZE_MEDIUM, this.CAPTION_SIZE.SIZE_LARGE],
               selected: 1,
            },
            {
               label: 'Text Edge Style',
               options: [
                  this.CAPTION_EDGE.EDGE_NONE,
                  this.CAPTION_EDGE.EDGE_RAISED,
                  this.CAPTION_EDGE.OPACITY_TRANS,
                  this.CAPTION_EDGE.EDGE_UNIFORM,
                  this.CAPTION_EDGE.EDGE_DROPSHADOW,
               ],
               selected: 0,
            },
         ],
      },
      {
         title: 'Text Background',
         property: [
            {
               label: 'Color',
               options: [
                  this.CAPTION_COLORS.COLOR_WHITE,
                  this.CAPTION_COLORS.COLOR_BLACK,
                  this.CAPTION_COLORS.COLOR_RED,
                  this.CAPTION_COLORS.COLOR_GREEN,
                  this.CAPTION_COLORS.COLOR_BLUE,
                  this.CAPTION_COLORS.COLOR_YELLOW,
               ],
               selected: 1,
            },
            {
               label: 'Opacity',
               options: [
                  this.CAPTION_OPACITY.OPACITY_OPAQUE,
                  this.CAPTION_OPACITY.OPACITY_SEMI,
                  this.CAPTION_OPACITY.OPACITY_TRANS,
               ],
               selected: 0,
            },
         ],
      },
      {
         title: 'Caption Area Background',
         property: [
            {
               label: 'Color',
               options: [
                  this.CAPTION_COLORS.COLOR_WHITE,
                  this.CAPTION_COLORS.COLOR_BLACK,
                  this.CAPTION_COLORS.COLOR_RED,
                  this.CAPTION_COLORS.COLOR_GREEN,
                  this.CAPTION_COLORS.COLOR_BLUE,
                  this.CAPTION_COLORS.COLOR_YELLOW,
               ],
               selected: 0,
            },
            {
               label: 'Opacity',
               options: [
                  this.CAPTION_OPACITY.OPACITY_OPAQUE,
                  this.CAPTION_OPACITY.OPACITY_SEMI,
                  this.CAPTION_OPACITY.OPACITY_TRANS,
               ],
               selected: 2,
            },
         ],
      },
   ]

   public readonly USER_IDLE_EVENTS: Readonly<Array<string>> = [
      'mousemove',
      'mousedown',
      'mouseup',
      'mouseenter',
      'mouseleave',
      'click',
      'dblclick',
      'wheel',
      'pointermove',
      'keydown',
      'keyup',
      'keypress',
      'touchstart',
      'touchmove',
      'touchend',
      'scroll',
      'resize',
      'focus',
      'blur',
      'input',
      'change',
      'contextmenu',
   ]

   public static getInstance(): VideoHelper {
      if (!VideoHelper.instance) {
         VideoHelper.instance = new VideoHelper()
      }
      return VideoHelper.instance
   }

   public getVideoDuration(seconds: number): string {
      const utcSeconds = moment.utc(seconds * 1000)
      if (moment.duration(Number(utcSeconds)).hours() > 0) {
         return utcSeconds.format('H:mm:ss')
      }
      return utcSeconds.format('mm:ss')
   }

   public hex2rgba(hex: any, alpha = 1) {
      const [r, g, b] = hex.match(/\w\w/g).map((color: any) => parseInt(color, 16))
      return `rgba(${r},${g},${b},${alpha})`
   }

   public setBackNavigationUrl(data: string) {
      this.backNavigationUrl = data
   }

   public getBackNavigationUrl() {
      return this.backNavigationUrl
   }
}

export const videoHelper = VideoHelper.getInstance()
