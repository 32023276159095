export default class JsonError extends Error {
   public success: boolean
   public code: number | null
   public data: any

   constructor(success: boolean, code: number | null, message: string, data?: any) {
      super(message)
      this.success = success
      this.code = code
      this.name = this.constructor.name
      this.data = data
   }

   toJson() {
      return {
         success: this.success,
         code: this.code,
         message: this.message,
         data: this.data,
      }
   }
}
