// redux core
import { createAppAsyncThunk } from '@/store/redux/createAppAsyncThunk'
import { ReduxState, ReduxDispatch } from '../../../store'

// helper

import JsonError from '@/app/api/_helpers/common/JsonError'
import { RAIL_VARIANT } from '@/app/_utility/rail-variants'
import { IndividualViewAllEpisodicProps } from '../feed'
import ViewallHelper from '@/app/(auth)/viewall/_helper/ViewallHelper'

const viewallHelperInstance = new ViewallHelper()

export const fetchViewAllAsync = createAppAsyncThunk<
   any,
   { railType: string; playlistId: string; skip: number; limit: number },
   { state: ReduxState; rejectWithValue: any }
>('feed/fetchViewAll', async (feedOptions, { getState, rejectWithValue }) => {
   const { railType, playlistId, skip, limit } = feedOptions

   let viewAllWatchProgressResults = []
   let newViewAllResults = [] as any
   let newViewAllWatchprogressResults = [] as any
   try {
      const viewAllResponse = await fetch(
         `${process.env.NEXT_PUBLIC_BASE_URL}/api/secure/viewall?id=${playlistId}&skip=${skip}&limit=${limit}`,
         {
            cache: 'no-store',
         },
      )
      if (viewAllResponse.status === 502) {
         throw new JsonError(false, viewAllResponse.status, viewAllResponse.statusText, {
            code: '',
            message: '',
         })
      }

      const {
         feed: { viewAll, viewAllEpisodeWatchProgress },
      } = getState()

      const viewAllResponseBody = await viewAllResponse.json()
      const { data: viewAllResults } = viewAllResponseBody
      if (viewAllResponseBody?.success === false) {
         throw { ...viewAllResponseBody }
      }

      if (railType?.toLowerCase() === RAIL_VARIANT.EPISODIC_RAIL) {
         const viewAllWatchprogressResponse = await fetch(
            `${process.env.NEXT_PUBLIC_BASE_URL}/api/secure/series/seasons/watchprogress`,
            {
               body: JSON.stringify({
                  assetIds: viewAllResults?.map((asset: IndividualViewAllEpisodicProps) => Number(asset.id)),
               }),
               method: 'POST',
            },
         )
         const { data: viewAllWatchProgressResults } = await viewAllWatchprogressResponse.json()
         if (viewAllWatchProgressResults?.length > 0) {
            newViewAllWatchprogressResults = [...viewAllEpisodeWatchProgress, ...viewAllWatchProgressResults]
         }
      }

      if (viewAllResults?.length > 0) {
         newViewAllResults = [...viewAll, ...viewallHelperInstance.createViewAllData(railType, viewAllResults)]
      }

      return { newViewAllResults, newViewAllWatchprogressResults, railType }
   } catch (err: any) {
      return rejectWithValue(err)
   }
})

type FetchViewAllAsyncQueueArgs = { railType: string; playlistId: string; skip: number; limit: number }

export const fetchViewAllAsyncQueue =
   ({ railType, playlistId, skip, limit }: FetchViewAllAsyncQueueArgs) =>
   async (dispatch: ReduxDispatch) => {
      await dispatch({
         type: fetchViewAllAsync.pending.type,
         meta: { queue: true },
      })

      try {
         const response = await dispatch(fetchViewAllAsync({ railType, playlistId, skip, limit }))
         await dispatch({
            type: fetchViewAllAsync.fulfilled.type,
            payload: response.payload,
            meta: { queue: true },
         })
      } catch (error: any) {
         await dispatch({
            type: fetchViewAllAsync.rejected.type,
            error: error.message,
            meta: { queue: true },
         })
      }
   }
