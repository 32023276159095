// redux core
import { createSlice, type PayloadAction } from '@reduxjs/toolkit'

// helper
import InformationPageHelper from '@/app/_helper/global-helper/InformationPageHelper'
import SeriesHelper from '@/app/(auth)/series/_helper/SeriesHelper'
import SeriesInformationPageHelper from '@/app/(auth)/series/_helper/SeriesInformationPageHelper'

// thunk
import {
   fetchSeasonsAsync,
   fetchSeriesWatchProgressAsync,
   fetchSeriesDetailAsync,
   fetchEpisodesWatchProgressAsync,
} from './seriesThunk'

import { fetchSeriesAsync } from './queuedSeriesFeedThunk'
import { fetchSeriesEpisodicProgressAsync } from './queuedSeriesEpisodeProgressThunk'

// types
import { ISeriesSliceState, TWatchProgress, TEpisode } from './series.d'
import FeedHelper from '@/app/(auth)/home/_helper/FeedHelper'
import { fetchPromoProgressAsync } from './queuedPromoProgressThunk'

const informationPageHelper = new InformationPageHelper()
const seriesInformationPageHelperInstance = new SeriesInformationPageHelper()
const feedHelperInstance = new FeedHelper()
export const seriesInitialState: ISeriesSliceState = {
   activeSeasonsEpisodesTab: '',

   seriesLandingStatus: 'idle',
   seriesLandingAsyncError: null,
   seriesLanding: [],

   seriesLandingSingle: [],
   seriesLandingFetchRockBottom: false,

   seasons: {},
   seasonsAsyncStatus: 'idle',
   seasonsAsyncError: null,

   episodicWatchProgressStatus: 'idle',
   episodicWatchProgressAsyncError: null,

   seriesDetail: {} as any,
   seriesDetailAsyncStatus: 'idle',
   seriesDetailAsyncError: null,

   episodeWatchProgress: {} as any,
   episodeWatchProgressAsyncStatus: 'idle',
   episodeWatchProgressAsyncError: null,

   watchProgressStatus: 'idle',
   watchProgressAsyncError: null,
   watchProgress: {} as TWatchProgress,

   promoProgressStatus: 'idle',
   resumeWatchingAsyncError: null,
}

export const seriesSlice = createSlice({
   name: 'series',
   initialState: seriesInitialState,
   reducers: {
      recordSeasonsEpisodesTab: (state, action: PayloadAction<string>) => {
         state.activeSeasonsEpisodesTab = action.payload
      },
      resetSeriesSlice: () => seriesInitialState,
   },
   extraReducers: (builder) => {
      builder
         .addCase(fetchSeriesAsync.pending, (state) => {
            state.seriesLandingStatus = 'loading'
         })
         .addCase(fetchSeriesAsync.fulfilled, (state, action) => {
            state.seriesLandingStatus = 'complete'
            const { payload } = action
            if (payload?.length > 0) {
               state.seriesLanding = payload
            } else {
               state.seriesLandingFetchRockBottom = true
            }
         })
         .addCase(fetchSeriesAsync.rejected, (state, action) => {
            state.seriesLandingStatus = 'failed'
            state.seriesLandingAsyncError = action.payload
         })

         .addCase(fetchSeasonsAsync.pending, (state) => {
            state.seasonsAsyncStatus = 'loading'
         })
         .addCase(fetchSeasonsAsync.fulfilled, (state, action) => {
            state.seasonsAsyncStatus = 'idle'
            const {
               meta: {
                  arg: { seasonIndex },
               },
               payload: { responseBody },
            } = action
            if (Array.isArray(responseBody.data)) {
               state.seasons[seasonIndex] = seriesInformationPageHelperInstance.createSeasonsEpisodes(
                  responseBody.data,
                  seasonIndex,
               )
            }
         })
         .addCase(fetchSeasonsAsync.rejected, (state, action) => {
            state.seasonsAsyncStatus = 'failed'
            state.seasonsAsyncError = action.payload
         })

         .addCase(fetchSeriesWatchProgressAsync.pending, (state) => {
            state.watchProgressStatus = 'loading'
         })
         .addCase(fetchSeriesWatchProgressAsync.fulfilled, (state, action) => {
            state.watchProgressStatus = 'complete'
            const {
               payload: { responseBody },
            } = action
            const data = responseBody.data
            const strTimeLeft =
               data?.progress > 0 ? informationPageHelper.calculateTimeLeft(data?.progress, data.videoDuration) : ''
            state.watchProgress = {
               episodeId: data?.video_id,
               episodeNumber: data?.episode_no,
               seasonNumber: data?.season_number,
               progressDuration: (data?.progress ?? 0) / 1000, // convert ms to s
               progressPercent: (data?.progress / data?.videoDuration) * 100,
               timeLeft: strTimeLeft ? strTimeLeft.replace('min', 'minute').replace('hrs', 'hours') : '',
               isEpisodeContentExpired: false,
               playbackEndDate: data?.playback_end_date,
               isPrestine: !!data?.isWatchNow,
            }
         })
         .addCase(fetchSeriesWatchProgressAsync.rejected, (state, action) => {
            state.watchProgressStatus = 'failed'
            state.watchProgressAsyncError = action.payload
         })

         .addCase(fetchSeriesEpisodicProgressAsync.pending, (state) => {
            state.episodeWatchProgressAsyncStatus = 'loading'
         })
         .addCase(fetchSeriesEpisodicProgressAsync.fulfilled, (state, action) => {
            state.episodeWatchProgressAsyncStatus = 'idle'
            const { payload } = action
            const { episodicRailProgress, currentEpisodicRail, railRowIndex } = payload

            state.seriesLanding.splice(
               railRowIndex,
               1,
               feedHelperInstance.createEpisodicRailData(currentEpisodicRail, episodicRailProgress),
            )
         })
         .addCase(fetchSeriesEpisodicProgressAsync.rejected, (state, action) => {
            state.episodeWatchProgressAsyncStatus = 'failed'
            state.episodeWatchProgressAsyncError = action.payload
         })

         .addCase(fetchSeriesDetailAsync.pending, (state) => {
            state.seriesDetailAsyncStatus = 'loading'
         })
         .addCase(fetchSeriesDetailAsync.fulfilled, (state, action) => {
            state.seriesDetailAsyncStatus = 'complete'
            const {
               payload: { feedResponseBody },
            } = action

            state.seriesDetail = seriesInformationPageHelperInstance.createInfomationPageData(feedResponseBody.data)
         })
         .addCase(fetchSeriesDetailAsync.rejected, (state, action) => {
            state.seriesDetailAsyncStatus = 'failed'
            state.seriesDetailAsyncError = action.payload
            state.seasonsAsyncError = action.payload
         })

         .addCase(fetchEpisodesWatchProgressAsync.pending, (state) => {
            state.episodeWatchProgressAsyncStatus = 'loading'
         })
         .addCase(fetchEpisodesWatchProgressAsync.fulfilled, (state, action) => {
            state.episodeWatchProgressAsyncStatus = 'idle'
            const {
               meta: {
                  arg: { currentSeasonIndex },
               },
               payload: { data },
            } = action

            state.episodeWatchProgress[currentSeasonIndex] = data
         })
         .addCase(fetchEpisodesWatchProgressAsync.rejected, (state, action) => {
            state.episodeWatchProgressAsyncStatus = 'failed'
            state.episodeWatchProgressAsyncError = action.payload
         })

         .addCase(fetchPromoProgressAsync.pending, (state) => {
            state.promoProgressStatus = 'loading'
         })
         .addCase(fetchPromoProgressAsync.fulfilled, (state, action: any) => {
            state.promoProgressStatus = 'complete'
            const { payload } = action
            const { promoProgress, currentPromoRail, railRowIndex, seriesDetail } = payload
            state.seriesLanding.splice(
               railRowIndex,
               1,
               feedHelperInstance.createPromoRailData(currentPromoRail, promoProgress, seriesDetail),
            )
         })
         .addCase(fetchPromoProgressAsync.rejected, (state, action) => {
            state.promoProgressStatus = 'failed'
            state.resumeWatchingAsyncError = action.payload
         })
   },
})
