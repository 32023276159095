import { createApi, fetchBaseQuery } from '@reduxjs/toolkit/query/react'

// billing
export const billingHistoryApiSlice = createApi({
   reducerPath: 'userSlice/billingHistoryApi',
   baseQuery: fetchBaseQuery({ baseUrl: `${process.env.NEXT_PUBLIC_BASE_URL}/api/account/billing-history` }),
   endpoints: (builder) => ({
      fetchBillingHistory: builder.query({
         query: () => '',
         forceRefetch() {
            return true
         },
         keepUnusedDataFor: 0,
      }),
   }),
})

export const { useFetchBillingHistoryQuery } = billingHistoryApiSlice

// logout
export const logoutApiSlice = createApi({
   reducerPath: 'userSlice/logoutApi',
   baseQuery: async (args, api, extraOptions) => {
      const baseQuery = fetchBaseQuery({ baseUrl: `${process.env.NEXT_PUBLIC_BASE_URL}/api/account/logout` })

      const result = await baseQuery(args, api, extraOptions)

      if (result.error) {
         return {
            ...result,
            error: {
               code: result.error.status,
               success: false,
            },
         }
      }

      return result
   },
   endpoints: (builder) => ({
      logout: builder.query({
         query: () => ({
            url: '',
            method: 'POST',
         }),
         forceRefetch() {
            return true
         },
         keepUnusedDataFor: 0,
      }),
   }),
})

export const { useLazyLogoutQuery } = logoutApiSlice

export const parentalControlPinApiSlice = createApi({
   reducerPath: 'userSlice/fetchParentalPinApi',
   baseQuery: fetchBaseQuery({ baseUrl: `${process.env.NEXT_PUBLIC_BASE_URL}/api/account/parentalcontrols` }),
   endpoints: (builder) => ({
      fetchParentalControl: builder.query({
         query: () => '',
         forceRefetch() {
            return true
         },
         keepUnusedDataFor: 0,
      }),
      addParentalPin: builder.query({
         query: ({ newParentalControlPin }) => ({
            url: '',
            method: 'POST',
            body: { newParentalControlPin },
         }),
         forceRefetch() {
            return true
         },
         keepUnusedDataFor: 0,
      }),
      updateParentalPin: builder.query({
         query: ({ oldParentalControlPin, newParentalControlPin }) => ({
            url: '',
            method: 'PUT',
            body: { oldParentalControlPin, newParentalControlPin },
         }),
         forceRefetch() {
            return true
         },
         keepUnusedDataFor: 0,
      }),
      disableParentalPin: builder.query({
         query: ({ parentalControlPin }) => ({
            url: '',
            method: 'DELETE',
            body: { parentalControlPin },
         }),
         forceRefetch() {
            return true
         },
         keepUnusedDataFor: 0,
      }),
      updateParentalControlRatingLevel: builder.query({
         query: ({ oldParentalControlPin, parentalControlLevel, contactID, isPrimary, picUrl }) => ({
            url: '/updaterating',
            method: 'POST',
            body: { oldParentalControlPin, parentalControlLevel, contactID, isPrimary, picUrl },
         }),
         forceRefetch() {
            return true
         },
         keepUnusedDataFor: 0,
      }),
      validateParentalPin: builder.query({
         query: ({ parentalPin }) => ({
            url: '/validate',
            method: 'POST',
            body: { parentalPin },
         }),
         forceRefetch() {
            return true
         },
         keepUnusedDataFor: 0,
      }),
      verifyPassword: builder.query({
         query: ({ email, password }) => ({
            url: '/verifypassword',
            method: 'POST',
            body: { email, password },
         }),
         forceRefetch() {
            return true
         },
         keepUnusedDataFor: 0,
      }),
   }),
})

export const {
   useLazyFetchParentalControlQuery,
   useLazyAddParentalPinQuery,
   useLazyValidateParentalPinQuery,
   useLazyUpdateParentalControlRatingLevelQuery,
   useLazyUpdateParentalPinQuery,
   useLazyDisableParentalPinQuery,
   useLazyVerifyPasswordQuery,
} = parentalControlPinApiSlice
