// helper
import CommonHelper from '@/app/_helper/global-helper/CommonHelper'
import InformationPageHelper from '@/app/_helper/global-helper/InformationPageHelper'

// utility
import { getTransformedImageUrl } from '@/app/_utility/image-transformation'

// type
import { PreCompiledSingularPromoProps } from './type'

// images
import movieFallback from '@/public/images/movie-promo-fallback.png'
import seriesFallback from '@/public/images/series-promo-fallback.png'

/**
 * SingularPromoHelper helps generate Singular Promo Rail
 * related data and manage relavant calculations before
 * storing declarative data into redux store.
 */
export default class SingularPromoHelper {
   commonHelper!: CommonHelper
   informationPageHelper: InformationPageHelper
   title: string = ''
   type: string = ''
   isInViewDetection = false
   showViewAll = false
   playlistId = ''
   item = {} as { [key: string]: any }
   preCompiledDetail = {} as PreCompiledSingularPromoProps

   constructor(preCompiledDetail: PreCompiledSingularPromoProps) {
      this.commonHelper = new CommonHelper()
      this.informationPageHelper = new InformationPageHelper()
      this.preCompiledDetail = preCompiledDetail
   }

   /**
    * Generates fallback image per content type
    * @param {string | null} isMovie - isMovie check of movie content type
    * @returns {string | null} isSeries - isSeries check of series content type
    */
   computeFallbackImage(isMovie: string | null, isSeries: string | null) {
      if (isMovie) {
         return movieFallback.src
      }
      if (isSeries) {
         return seriesFallback.src
      }
   }

   /**
    * Generates designated image per content type along with dimensions
    * @param {object} railType - rail type for conditional cases
    * @param {object} railItem - individual rail item from rail collection
    * @returns {object} Compiled, UI declarative collection of image and dimension
    */
   computeImage(railType: string, railItem: { [key: string]: any }) {
      let dynamicImage = ''
      let dynamicImageDimension: { width: number; height: number; borderRadius: number } = {
         width: 0,
         height: 0,
         borderRadius: 0,
      }

      dynamicImage = getTransformedImageUrl(
         railItem?.imageUrl,
         CommonHelper.IMAGE_SIZES['singular_promo'].high.width,
         CommonHelper.IMAGE_SIZES['singular_promo'].high.height,
         CommonHelper.IMAGE_SIZES['singular_promo'].high.borderRadius,
      )

      return dynamicImage
   }

   /**
    * Generate expected link that encompasses respective rail card
    * @param {object} railItem - individualrail item from rail collection
    * @returns {string} calculated link
    */
   computeLink(railItem: { [key: string]: any }) {
      let calculatedLink = ''
      if (railItem?.seriesRef !== null) {
         calculatedLink = `/watch?video=${railItem.seriesRef}`
      }
      if (railItem?.movieRef !== null) {
         calculatedLink = `/watch?video=${railItem.id}`
      }

      return calculatedLink
   }

   /**
    * Generate expected category and label for respective rail card
    * @param {string} playbackStartDate - day when streaming start
    * @param {string} playbackEndDate - day when streaming end
    * @param {string} comingSoonDateTime - day when streaming is yet to be started being announced
    * @returns {object} Compiled, UI declarative collection of label and category
    */
   computeTimeline(playbackStartDateTime: string, playbackEndDateTime: string, comingSoonDateTime: string) {
      const timeDifference = this.informationPageHelper.mediaReleaseTimelineDiff(
         playbackStartDateTime,
         playbackEndDateTime,
         comingSoonDateTime,
      )
      let label = ''
      let category = ''
      if (timeDifference.comingSoonDifference >= 0 && timeDifference.startDifference <= 0) {
         category = CommonHelper.BADGE_TYPE.COMINGSOON
         label = `COMING SOON`
      } else {
         const remainingDuration = Math.abs(timeDifference.endDifference)
         if (this.informationPageHelper.hasPlaybackStartedWithinXDays(playbackStartDateTime)) {
            category = CommonHelper.BADGE_TYPE.NEW
            label = `NEW`
         }
         if (
            remainingDuration > CommonHelper.CONSTANTS.DAY_IN_SECONDS &&
            remainingDuration <=
               this.commonHelper.convertDaytoSecond(InformationPageHelper.CONSTANTS.CONTENT_EXPIRY_WINDOW) &&
            Math.sign(timeDifference.endDifference) == -1
         ) {
            category = CommonHelper.BADGE_TYPE.LEAVING
            label = `LEAVING IN  ${this.informationPageHelper.getRemainingDays(remainingDuration)}  `
         } else if (
            remainingDuration <= CommonHelper.CONSTANTS.DAY_IN_SECONDS &&
            Math.sign(timeDifference.endDifference) == -1
         ) {
            category = CommonHelper.BADGE_TYPE.LEAVING
            label = 'LEAVING TODAY'
         }
      }
      return { category, label }
   }

   /**
    * Tailor raw response into UI declarative object
    * @param-none
    * @returns-none
    */
   tailoredCollection() {
      const promo = this.preCompiledDetail
      this.item = {
         id: Number(promo.id),
         link: this.computeLink(promo),
         linkAriaLabel: `${promo.title}`,
         picture: this.computeImage(promo.rail_type, promo),
         fallback: this.computeFallbackImage(promo.movieRef, promo.seriesRef),
         isPlayable: this.commonHelper.isValidPlayBackStartDate(promo.playbackStartDate),
         progress: 0,
         isContentExpired: CommonHelper.moment(promo.playbackEndDate).utc().isBefore(CommonHelper.moment().utc()),
         playbackStartDate: promo.playbackStartDate,
         playbackEndDate: promo.playbackEndDate,
         comingSoonDate: promo.comingSoonDate,
         synopsis: promo.synopsis,
         seriesRef: promo.seriesRef,
         movieRef: promo.movieRef,
         title: promo.title,
         watchNow: promo.watchNow,
         watchList: promo.watchList,
         newSeasonDate: promo.newSeasonDate,
         episodeNo: null,
         seasonNumber: null,
         videoId: null,
         rating: promo.rating,
         contentType: promo.seriesRef !== 'null' ? 'series' : 'movie',
         genres: Array.isArray(promo?.genre) ? promo?.genre?.join(',') : promo?.genre?.trim() ?? '',
      }

      return {
         title: this.preCompiledDetail?.title,
         type: this.preCompiledDetail?.rail_type,
         card: this.item,
         elements: [],
         isInViewDetection: this.isInViewDetection,
      }
   }
}
