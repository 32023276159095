export const IMAGE_SIZES = {
   'poster_horizontal': {
      'small': {
         width: 384,
         height: 216,
         borderRadius: 0,
      },
      'medium': {
         width: 384,
         height: 216,
         borderRadius: 0,
      },
      'high': {
         width: 384,
         height: 216,
         borderRadius: 0,
      },
   },
   'still_frame': {
      'small': {
         width: 640,
         height: 360,
         borderRadius: 0,
      },
      'medium': {
         width: 640,
         height: 360,
         borderRadius: 0,
      },
      'high': {
         width: 640,
         height: 360,
         borderRadius: 0,
      },
   },
   'poster_vertical': {
      'small': {
         width: 320,
         height: 480,
         borderRadius: 0,
      },
      'medium': {
         width: 320,
         height: 480,
         borderRadius: 0,
      },
      'high': {
         width: 320,
         height: 480,
         borderRadius: 0,
      },
   },
   'logo': {
      'small': {
         width: 400,
         height: 300,
         borderRadius: 0,
      },
      'medium': {
         width: 400,
         height: 300,
         borderRadius: 0,
      },
      'high': {
         width: 400,
         height: 300,
         borderRadius: 0,
      },
   },
   'hero_carousel': {
      'small': {
         width: 1280,
         height: 720,
         borderRadius: 0,
      },
      'medium': {
         width: 1280,
         height: 720,
         borderRadius: 0,
      },
      'high': {
         width: 1280,
         height: 720,
         borderRadius: 0,
      },
   },
   'profile': {
      'small': {
         width: 128,
         height: 128,
         borderRadius: 0,
      },
      'medium': {
         width: 128,
         height: 128,
         borderRadius: 0,
      },
      'high': {
         width: 128,
         height: 128,
         borderRadius: 0,
      },
   },
   'singular_promo': {
      'small': {
         width: 960,
         height: 540,
         borderRadius: 0,
      },
      'medium': {
         width: 960,
         height: 540,
         borderRadius: 17,
      },
      'high': {
         width: 960,
         height: 540,
         borderRadius: 0,
      },
   },
   'singular_promo_logo': {
      'small': {
         width: 280,
         height: 280,
         borderRadius: 0,
      },
      'medium': {
         width: 280,
         height: 280,
         borderRadius: 0,
      },
      'high': {
         width: 280,
         height: 280,
         borderRadius: 0,
      },
   },
   'thematic_background': {
      'small': {
         width: 960,
         height: 540,
         borderRadius: 0,
      },
      'medium': {
         width: 960,
         height: 540,
         borderRadius: 0,
      },
      'high': {
         width: 960,
         height: 540,
         borderRadius: 0,
      },
   },
   'thematic_foreground': {
      'small': {
         width: 280,
         height: 280,
         borderRadius: 0,
      },
      'medium': {
         width: 280,
         height: 280,
         borderRadius: 0,
      },
      'high': {
         width: 280,
         height: 280,
         borderRadius: 0,
      },
   },
}
