// helpers
import CommonHelper from '@/app/_helper/global-helper/CommonHelper'
import InformationPageHelper from '@/app/_helper/global-helper/InformationPageHelper'
import PosterHelper from '@/app/_helper/rail-helper/PosterHelper'

// types
import { GenreAssetsProps } from './GenreHelper'

export default class GenreHelper {
   static instance: GenreHelper | null = null
   commonHelper!: CommonHelper
   informationPageHelper!: InformationPageHelper
   posterHelper!: PosterHelper

   constructor() {
      if (GenreHelper.instance) {
         return GenreHelper.instance
      }

      this.commonHelper = new CommonHelper()
      this.informationPageHelper = new InformationPageHelper()

      GenreHelper.instance = this
   }

   createGenreFeedData(preCompiledGenreAssets: GenreAssetsProps[]) {
      let computedGenreItem = []
      for (let preCompiledGenreAsset of preCompiledGenreAssets) {
         /* eslint-disable camelcase */
         this.posterHelper = new PosterHelper({
            rail_type: CommonHelper.RAIL_VARIANT.POSTER_HORIZONTAL,
            elements: [
               {
                  video_id: Number(preCompiledGenreAsset.id),
                  ...preCompiledGenreAsset,
               },
            ] as any,
            banners: [],
            id: '',
            playlistId: '',
            title: '',
            showViewAll: false,
         })
         /* eslint-disable camelcase */
         computedGenreItem.push(this.posterHelper.tailoredCollection()?.elements[0])
      }
      return computedGenreItem
   }
}
