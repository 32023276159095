// helper
import CommonHelper from '@/app/_helper/global-helper/CommonHelper'
import InformationPageHelper from '@/app/_helper/global-helper/InformationPageHelper'

// utility
import { getTransformedImageUrl } from '@/app/_utility/image-transformation'

// type
import {
   IPreCompiledHeroCarousel,
   ICardItem,
   IgenerateTimeYearGenreRating,
   IPreCompiledHeroCarouselElement,
} from './type'

// images
import movieFallback from '@/public/images/card-movie-fallback.png'
import seriesFallback from '@/public/images/card-series-fallback.png'

/**
 * HeroCarouselHelper helps generate hero-carousel related data and manage relavant calculations
 * before storing declarative data into redux store.
 */
export default class HeroCarouselHelper {
   commonHelper!: CommonHelper
   informationPageHelper: InformationPageHelper
   title: string = ''
   type: string = ''
   item = {} as ICardItem
   elements = [] as ICardItem[]
   isInViewDetection = true
   preCompiledDetail = [] as IPreCompiledHeroCarouselElement[]

   static readonly CONSTANTS = {
      HERO_WATCH_NOW: 'HERO.WATCH_NOW',
      HERO_WATCH_TRAILER: 'HERO.WATCH_TRAILER',
      HERO_MORE_INFO: 'HERO.MORE_INFO',
      HERO_BROWSE: 'HERO.BROWSE',
   }

   constructor(preCompiledDetail: IPreCompiledHeroCarousel) {
      this.commonHelper = new CommonHelper()
      this.informationPageHelper = new InformationPageHelper()
      this.type = preCompiledDetail?.rail_type
      this.preCompiledDetail = preCompiledDetail?.banners
   }

   /**
    * Generates fallback image per content type
    * @param {string} contentType - content type for conditional cases
    * @returns {StaticImageData} - fallback image src object
    */
   computeFallbackImage(contentType: string) {
      return contentType === CommonHelper.CONTENT_TYPE.EPISODE ? seriesFallback.src : movieFallback.src
   }

   /**
    * Generates category and label
    *
    * @param playbackStartDate playback start date (PSSD) from brightcove
    * @param playbackEndDate playback end date (PSED) from brightcove
    * @param comingSoonDateTime coming soon date from brightcove
    * @param isOriginal isOriginal from brightcove
    * @param isTrailer isTrailer from brightcove
    * @returns {object} label and category
    */
   computeTimeline(
      playbackStartDateTime: string,
      playbackEndDateTime: string,
      comingSoonDateTime: string,
      isOriginal: boolean,
      isTrailer: boolean,
   ) {
      const timeDifference = this.informationPageHelper.mediaReleaseTimelineDiff(
         playbackStartDateTime,
         playbackEndDateTime,
         comingSoonDateTime,
      )

      let label = ''
      let category = ''

      if (isTrailer) {
         if (this.informationPageHelper.hasPlaybackStartedWithinXDays(playbackStartDateTime)) {
            category = CommonHelper.BADGE_TYPE.NEW
            label = `NEW`
         }
         return { category, label }
      }

      const remainingDuration = Math.abs(timeDifference.endDifference)

      if (timeDifference.comingSoonDifference >= 0 && timeDifference.startDifference <= 0) {
         category = CommonHelper.BADGE_TYPE.COMINGSOON
         label = `COMING SOON`
      } else if (timeDifference.startDifference <= 0) {
         if (isOriginal) {
            category = CommonHelper.BADGE_TYPE.PREMIERING
            label = `PREMIERING ${CommonHelper.moment(timeDifference.startAt).format('MMMM D')}`
         } else {
            category = CommonHelper.BADGE_TYPE.AVAILABLE
            label = `AVAILABLE ${CommonHelper.moment(timeDifference.startAt).format('MMMM D')}`
         }
      } else if (this.informationPageHelper.hasPlaybackStartedWithinXDays(playbackStartDateTime)) {
         category = CommonHelper.BADGE_TYPE.NEW
         label = `NEW`
      } else if (
         remainingDuration > CommonHelper.CONSTANTS.DAY_IN_SECONDS &&
         remainingDuration <=
            this.commonHelper.convertDaytoSecond(InformationPageHelper.CONSTANTS.CONTENT_EXPIRY_WINDOW) &&
         Math.sign(timeDifference.endDifference) == -1
      ) {
         category = CommonHelper.BADGE_TYPE.LEAVING
         label = `LEAVING IN  ${this.informationPageHelper.getRemainingDays(remainingDuration)}  `
      } else if (
         remainingDuration <= CommonHelper.CONSTANTS.DAY_IN_SECONDS &&
         Math.sign(timeDifference.endDifference) == -1
      ) {
         category = CommonHelper.BADGE_TYPE.LEAVING
         label = 'LEAVING TODAY'
      }
      return { category, label }
   }

   /**
    * Generates designated image per content type along with dimensions
    * @param {object} railItem - individual rail item from rail collection
    * @returns {object} Compiled, UI declarative collection of image and dimension
    */
   computeImage(railItem: { [key: string]: any }) {
      let dynamicImage = ''
      let dynamicImageDimension: { width: number; height: number; borderRadius: number } = {
         width: 0,
         height: 0,
         borderRadius: 0,
      }

      dynamicImageDimension = {
         width: CommonHelper.IMAGE_SIZES['hero_carousel'].high.width,
         height: CommonHelper.IMAGE_SIZES['hero_carousel'].high.height,
         borderRadius: CommonHelper.IMAGE_SIZES['hero_carousel'].high.borderRadius,
      }

      dynamicImage = getTransformedImageUrl(
         railItem?.image_url,
         CommonHelper.IMAGE_SIZES['hero_carousel'].high.width,
         CommonHelper.IMAGE_SIZES['hero_carousel'].high.height,
         CommonHelper.IMAGE_SIZES['hero_carousel'].high.borderRadius,
      )

      return { 'image': dynamicImage, 'dimension': dynamicImageDimension }
   }

   /**
    * Generate expected link that encompasses respective rail card
    * @param {object} railItem - individualrail item from rail collection
    * @returns {string} calculated link
    */
   computeLink(railItem: { [key: string]: any }) {
      let calculatedLink = ''
      if (railItem.content_type === CommonHelper.CONTENT_TYPE.EPISODE) {
         if (this.commonHelper.isValidPlayBackStartDate(railItem.playback_start_date)) {
            calculatedLink = `/watch?video=${railItem.video_id}`
         } else {
            return `/series/${railItem?.series_ref}?isref=true`
         }
      } else {
         calculatedLink = `/watch?video=${railItem.video_id}`
      }
      return calculatedLink
   }

   /**
    * Generates declarative hero-carousel meta
    */
   generateHeroBannerMeta(bannerItem: IgenerateTimeYearGenreRating) {
      let primaryCtaLink = ''
      let secondaryCtalink = ''
      let isSecondaryCtaLinkBackTrack = false
      let bannerType = bannerItem?.banner_type
      let timeYearGenreRating = ''
      let primaryCTALabel = ''
      let secondaryCTALabel = ''
      let hasPrimaryButtonIcon = false
      let hasSecondaryButtonIcon = false
      let primary = ''
      let synopsis = ''
      let isSecondaryLinkByRef = false
      let seasonCount = null

      switch (bannerItem?.banner_type?.toLowerCase()) {
         case CommonHelper.CONTENT_TYPE.BANNER_MOVIE:
            timeYearGenreRating = [
               !!bannerItem?.duration && this.informationPageHelper.getRoundedDuration(bannerItem?.duration),
               !!bannerItem?.initial_release_year && CommonHelper.moment(bannerItem?.initial_release_year).year(),
               !!bannerItem?.genre && bannerItem?.genre?.split(',')[0],
               !!bannerItem?.rating && bannerItem?.rating,
            ]
               .filter(Boolean)
               .join(' · ')
            if (bannerItem.video_id) {
               primaryCTALabel = HeroCarouselHelper.CONSTANTS.HERO_WATCH_NOW
               secondaryCTALabel = HeroCarouselHelper.CONSTANTS.HERO_MORE_INFO
               primaryCtaLink = `/watch?video=${bannerItem?.video_id}`
               secondaryCtalink = `/movies/${bannerItem?.video_id}`
            }
            hasPrimaryButtonIcon = true
            primary = HeroCarouselHelper.CONSTANTS.HERO_WATCH_NOW
            seasonCount = null
            break
         case CommonHelper.CONTENT_TYPE.SERIES:
            timeYearGenreRating = [
               bannerItem?.season_count && this.informationPageHelper.createSeasonCount(bannerItem?.season_count),
               !!bannerItem?.initial_release_year?.trim() &&
                  !!bannerItem?.latest_release_year?.trim() &&
                  this.informationPageHelper.createProductionYearRange(
                     bannerItem?.initial_release_year,
                     bannerItem?.latest_release_year,
                     bannerItem?.season_count,
                  ),
               !!bannerItem?.genre && bannerItem?.genre?.split(',')[0],
               !!bannerItem?.rating && bannerItem?.rating,
            ]
               .filter(Boolean)
               .join(' · ')
            if (bannerItem.series_ref) {
               primaryCtaLink = `/watch?video=${bannerItem?.series_ref}`
               primaryCTALabel = HeroCarouselHelper.CONSTANTS.HERO_WATCH_NOW
               isSecondaryLinkByRef = true
            }
            if (bannerItem.video_id) {
               secondaryCtalink = `/series/${bannerItem?.video_id}`
               secondaryCTALabel = HeroCarouselHelper.CONSTANTS.HERO_MORE_INFO
            }
            hasPrimaryButtonIcon = true
            seasonCount = bannerItem?.season_count
            break
         case CommonHelper.CONTENT_TYPE.TRAILER:
            const isContentPublished = CommonHelper.moment()
               .utc()
               .startOf('day')
               .isSameOrAfter(CommonHelper.moment(bannerItem.playback_start_date).utc())

            timeYearGenreRating = [
               !!bannerItem?.genre && bannerItem?.genre?.split(',')[0],
               !!bannerItem?.rating && bannerItem?.rating,
            ]
               .filter(Boolean)
               .join(' · ')
            if (bannerItem?.video_id) {
               primaryCtaLink = `/watch?video=${bannerItem?.video_id}`
               if (bannerItem?.series_ref) {
                  secondaryCtalink = isContentPublished
                     ? `/series/${bannerItem?.series_ref}?isref=true`
                     : `/series/${bannerItem?.video_id}`
                  isSecondaryLinkByRef = true
               } else {
                  secondaryCtalink = isContentPublished
                     ? `/movies/${bannerItem?.movie_ref}?isref=true`
                     : `/movies/${bannerItem?.video_id}`
               }
               primaryCTALabel = HeroCarouselHelper.CONSTANTS.HERO_WATCH_TRAILER
               secondaryCTALabel = HeroCarouselHelper.CONSTANTS.HERO_MORE_INFO
               hasPrimaryButtonIcon = true
            }

            break
         case CommonHelper.CONTENT_TYPE.SPOTLIGHT:
            if (bannerItem.playlist_id) {
               primaryCtaLink = `viewall?title=${bannerItem.title_text}&type=h&id=${bannerItem.playlist_id}`
               primaryCTALabel = HeroCarouselHelper.CONSTANTS.HERO_BROWSE
            }
            if (bannerItem.video_id) {
               secondaryCtalink = `/watch?video=${bannerItem?.video_id}`
               isSecondaryCtaLinkBackTrack = true
               secondaryCTALabel = HeroCarouselHelper.CONSTANTS.HERO_WATCH_TRAILER
            }
            synopsis = `${bannerItem?.synopsis}`
            hasSecondaryButtonIcon = true
            break
      }

      return {
         logo: (bannerItem?.logo_url?.indexOf('res.cloudinary.com') !== -1
            ? getTransformedImageUrl(
                 bannerItem?.logo_url as string,
                 CommonHelper.IMAGE_SIZES['logo'].high.width,
                 CommonHelper.IMAGE_SIZES['logo'].high.height,
                 CommonHelper.IMAGE_SIZES['logo'].high.borderRadius,
                 true,
              )
            : '') as string,
         primaryCtaLink,
         secondaryCtalink,
         isSecondaryLinkByRef,
         isSecondaryCtaLinkBackTrack,
         bannerType,
         timeYearGenreRating,
         primaryCTALabel,
         secondaryCTALabel,
         synopsis,
         hasPrimaryButtonIcon,
         hasSecondaryButtonIcon,
         seasonCount,
      }
   }

   /**
    * Tailor raw response into UI declarative object
    * @param-none
    * @returns-none
    */
   tailoredCollection(totalRailCount?: number) {
      const compiledElements = []

      for (let bannerIndex = 0; bannerIndex < this.preCompiledDetail.length; bannerIndex++) {
         const bannerItem = this.preCompiledDetail[bannerIndex]
         const isTrailer = bannerItem.banner_type === 'trailer'
         const { image, dimension } = this.computeImage(bannerItem)

         this.item = {
            primaryText: '',
            secondaryText: '',
            contentType: this.type,
            card: {
               link: '',
               picture: image,
               fallbackSrc: '',
               dimension,
               title: bannerItem?.title_text,
               isPlayable: false,
               progress: 0,
               isLazyloadDisabled: true,
               timelineAnnouncement: this.computeTimeline(
                  bannerItem.playback_start_date as string,
                  bannerItem.playback_end_date as string,
                  bannerItem.coming_soon_date as string,
                  bannerItem.isOriginal as boolean,
                  isTrailer,
               ),
               heroBannerMeta: { ...this.generateHeroBannerMeta(bannerItem) },
               rating: bannerItem?.rating,
               contentType: bannerItem?.series_ref !== null ? 'series' : 'movie',
               genres: Array.isArray(bannerItem?.genre)
                  ? bannerItem?.genre?.join(',')
                  : bannerItem?.genre?.trim() ?? '',
               contentId: bannerItem?.video_id,
               contentRef: bannerItem?.series_ref || bannerItem?.movie_ref,
            },
         }

         compiledElements.push(this.item)
      }

      return {
         title: this.title,
         type: this.type,
         elements: compiledElements,
         totalRailCount: totalRailCount,
         isInViewDetection: this.isInViewDetection,
      }
   }
}
