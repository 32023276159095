// random number core
import randomstring from 'randomstring'

// redux core
import { createAppAsyncThunk } from '@/store/redux/createAppAsyncThunk'

export const fetchSearchAsync = createAppAsyncThunk(
   'feed/fetchSearchResults',
   async (search: { railType: string; query: string; skip: number; limit: number }, { rejectWithValue }) => {
      const { railType, query, skip, limit } = search

      try {
         let SearchAPIEndpoint = `${process.env.NEXT_PUBLIC_BASE_URL}/api/secure/search?`
         SearchAPIEndpoint += `skip=${skip}&limit=${limit}`
         SearchAPIEndpoint += `&query=${encodeURIComponent(query)}`
         const searchResponse = await fetch(SearchAPIEndpoint, {
            cache: 'no-store',
            headers: {
               'X-Request-ID': randomstring.generate(20),
            },
         })
         const searchResponseBody = await searchResponse.json()

         if (searchResponseBody?.success === false && skip === 0) {
            throw { ...searchResponseBody }
         } else if (searchResponseBody?.success === false && skip > 0) {
            return { searchResponseBody: {}, railType }
         }

         return { searchResponseBody, railType }
      } catch (err: any) {
         return rejectWithValue(err)
      }
   },
)

export const fetchPaginatedSearchAsync = createAppAsyncThunk(
   'feed/fetchPaginatedSearchResults',
   async (search: { railType: string; query: string; skip: number; limit: number }, { rejectWithValue }) => {
      const { railType, query, skip, limit } = search
      try {
         let SearchAPIEndpoint = `${process.env.NEXT_PUBLIC_BASE_URL}/api/secure/search?`
         SearchAPIEndpoint += `skip=${skip}&limit=${limit}`
         SearchAPIEndpoint += `&query=${encodeURIComponent(query)}`
         const searchResponse = await fetch(SearchAPIEndpoint, {
            cache: 'no-store',
            headers: {
               'X-Request-ID': randomstring.generate(20),
            },
         })
         const searchResponseBody = await searchResponse.json()

         if (searchResponseBody?.success === false) {
            throw { ...searchResponseBody }
         }

         return { searchResponseBody, railType }
      } catch (err: any) {
         return rejectWithValue(err)
      }
   },
)

export const fetchPopularSearchAsync = createAppAsyncThunk(
   'feed/fetchPopularSearch',
   async (search: { railType: string }, { rejectWithValue }) => {
      const { railType } = search

      try {
         const searchResponse = await fetch(`${process.env.NEXT_PUBLIC_BASE_URL}/api/secure/feed?page=search`, {
            cache: 'no-store',
            headers: {
               'X-Request-ID': randomstring.generate(20),
            },
         })
         const popularResponseBody = await searchResponse.json()

         if (popularResponseBody?.success === false) {
            throw { ...popularResponseBody }
         }

         return { popularResponseBody, railType }
      } catch (err: any) {
         return rejectWithValue(err)
      }
   },
)

export const fetchGenreAssetsAsync = createAppAsyncThunk(
   'feed/fetchGenreAssets',
   async (genre: { types: string }, { rejectWithValue }) => {
      const { types } = genre
      try {
         const genreResponse = await fetch(
            `${process.env.NEXT_PUBLIC_BASE_URL}/api/secure/feed/genre-assets?type=${types}`,
            {
               cache: 'no-store',
            },
         )
         const genreResponseBody = await genreResponse.json()
         if (genreResponseBody?.success === false) {
            throw { ...genreResponseBody }
         }

         return await genreResponseBody
      } catch (err: any) {
         return rejectWithValue(err)
      }
   },
)

export const ymalLookupAsync = createAppAsyncThunk(
   'feed/fetchYmal',
   async (search: { assetId: string; genre: any; categoryTag: string }, { rejectWithValue }) => {
      const { assetId, genre, categoryTag } = search

      try {
         const endpoint = `${
            process.env.NEXT_PUBLIC_BASE_URL
         }/api/secure/YouMayAlsoLike?assetId=${assetId}&genre=${genre}&categoryTag=${encodeURIComponent(
            categoryTag as string,
         )}`
         const feedResponse = await fetch(endpoint)
         const feedResponseBody = await feedResponse.json()
         if (feedResponseBody?.success === false) {
            throw { ...feedResponseBody }
         }
         return { feedResponseBody }
      } catch (err: any) {
         return rejectWithValue(err)
      }
   },
)
