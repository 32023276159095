import { createAppAsyncThunk } from '@/store/redux/createAppAsyncThunk'
import { ReduxState, ReduxDispatch } from '../../../store'
import JsonError from '@/app/api/_helpers/common/JsonError'

export const fetchPromoProgressAsync = createAppAsyncThunk<
   any,
   { assetIds: Array<number>; railRowIndex: number },
   { state: ReduxState; rejectWithValue: any; dispatch: ReduxDispatch }
>('movie/fetchPromoProgressAsync', async (detail, { getState, rejectWithValue, dispatch }) => {
   let { assetIds, railRowIndex } = detail
   try {
      const response = await fetch(`${process.env.NEXT_PUBLIC_BASE_URL}/api/secure/feed/watchprogress`, {
         body: JSON.stringify({
            assetIds,
         }),
         method: 'POST',
      })

      await dispatch(fetchMovieDetailAsync({ id: assetIds[0] }))

      const { data } = await response.json()
      const {
         movie: { movieLanding, movieDetail },
      } = getState()

      return {
         promoProgress: data,
         currentPromoRail: movieLanding[railRowIndex],
         railRowIndex,
         movieDetail: movieDetail,
      }
   } catch (err: any) {
      return rejectWithValue(err)
   }
})

export const fetchMovieDetailAsync = createAppAsyncThunk(
   'feed/fetchMovieDetail',
   async (movie: { id: number }, { rejectWithValue }) => {
      // Expecting 'id' from assetIds
      const { id } = movie
      try {
         const feedResponse = await fetch(`${process.env.NEXT_PUBLIC_BASE_URL}/api/secure/movie/${id}`)
         if (feedResponse.status === 502) {
            throw new JsonError(false, feedResponse.status, feedResponse.statusText, {
               code: '',
               message: '',
            })
         }
         const feedResponseBody = await feedResponse.json()

         if (feedResponseBody?.success === false) {
            throw { ...feedResponseBody }
         }
         return { feedResponseBody }
      } catch (err: any) {
         return rejectWithValue(err)
      }
   },
)

type FetchPromoRailWatchprogressAsyncArgs = {
   assetIds: Array<number>
   railRowIndex: number
}

export const fetchPromoProgressMovieAsyncQueue =
   ({ assetIds, railRowIndex }: FetchPromoRailWatchprogressAsyncArgs) =>
   async (dispatch: ReduxDispatch) => {
      await dispatch({
         type: fetchPromoProgressAsync.pending.type,
         meta: { queue: true },
      })

      try {
         const response = await dispatch(fetchPromoProgressAsync({ assetIds, railRowIndex }))

         await dispatch({
            type: fetchPromoProgressAsync.fulfilled.type,
            payload: response.payload,
            meta: { queue: true },
         })
      } catch (error: any) {
         await dispatch({
            type: fetchPromoProgressAsync.rejected.type,
            error: error.message,
            meta: { queue: true },
         })
      }
   }
