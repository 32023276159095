// helper
import CommonHelper from '@/app/_helper/global-helper/CommonHelper'
import InformationPageHelper from '@/app/_helper/global-helper/InformationPageHelper'

// utility
import { getTransformedImageUrl } from '@/app/_utility/image-transformation'
// type
import { PreWatchlistProps, PreWatchlistElementsProps } from './type'

// images
import movieFallback from '@/public/images/card-movie-fallback.png'
import seriesFallback from '@/public/images/card-series-fallback.png'

/**
 * PosterHelper helps generate poster-horizontal or
 * poster-vertical related data and manage relavant calculations before
 * storing declarative data into redux store.
 */
export default class WatchlistHelper {
   commonHelper!: CommonHelper
   informationPageHelper: InformationPageHelper
   title: string = ''
   type: string = ''
   item = {} as any
   elements = [] as any[]
   isInViewDetection = false
   showViewAll = false
   playlistId = ''
   preCompiledDetail = [] as PreWatchlistElementsProps[]

   constructor(preCompiledDetail: PreWatchlistProps) {
      this.commonHelper = new CommonHelper()
      this.informationPageHelper = new InformationPageHelper()
      this.title = preCompiledDetail?.title as string
      this.type = preCompiledDetail?.rail_type as string
      this.showViewAll = preCompiledDetail?.showViewAll as boolean
      this.preCompiledDetail = preCompiledDetail?.elements
   }

   /**
    * Generates fallback image per content type
    * @param {string} contentType - content type for conditional cases
    * @returns {StaticImageData} - fallback image src object
    */
   computeFallbackImage(contentType: string) {
      return contentType === CommonHelper.CONTENT_TYPE.EPISODE || contentType === CommonHelper.CONTENT_TYPE.SERIES
         ? seriesFallback.src
         : movieFallback.src
   }

   /**
    * Generates designated image per content type along with dimensions
    * @param {object} railType - rail type for conditional cases
    * @param {object} railItem - individual rail item from rail collection
    * @returns {object} Compiled, UI declarative collection of image and dimension
    */
   computeImage(railType: string, railItem: { [key: string]: any }) {
      let dynamicImage = ''
      let dynamicImageDimension: { width: number; height: number; borderRadius: number } = {
         width: CommonHelper.IMAGE_SIZES['poster_horizontal'].high.width,
         height: CommonHelper.IMAGE_SIZES['poster_horizontal'].high.height,
         borderRadius: CommonHelper.IMAGE_SIZES['poster_horizontal'].high.borderRadius,
      }
      dynamicImage = getTransformedImageUrl(
         railItem?.hPoster,
         CommonHelper.IMAGE_SIZES['poster_horizontal'].high.width,
         CommonHelper.IMAGE_SIZES['poster_horizontal'].high.height,
         CommonHelper.IMAGE_SIZES['poster_horizontal'].high.borderRadius,
      )
      return { 'image': dynamicImage, 'dimension': dynamicImageDimension }
   }

   /**
    * Generate expected link that encompasses respective rail card
    * @param {object} railItem - individualrail item from rail collection
    * @returns {string} calculated link
    */
   computeLink(railItem: { [key: string]: any }, badgeType: string) {
      let calculatedLink = ''

      switch (railItem.content_type) {
         case CommonHelper.CONTENT_TYPE.MOVIE:
            calculatedLink = `/movies/${railItem.assetId}`
            break
         case CommonHelper.CONTENT_TYPE.SERIES:
            calculatedLink = `/series/${railItem.assetId}`
            break
      }

      return calculatedLink
   }

   /**
    * Generate expected category and label for respective rail card
    * @param {string} playbackStartDate - day when streaming start
    * @param {string} playbackEndDate - day when streaming end
    * @param {string} comingSoonDateTime - day when streaming is yet to be started being announced
    * @returns {object} Compiled, UI declarative collection of label and category
    */
   computeTimeline(playbackStartDateTime: string, playbackEndDateTime: string, comingSoonDateTime: string) {
      const timeDifference = this.informationPageHelper.mediaReleaseTimelineDiff(
         playbackStartDateTime,
         playbackEndDateTime,
         comingSoonDateTime,
      )
      let label = ''
      let category = ''
      if (timeDifference.comingSoonDifference >= 0 && timeDifference.startDifference <= 0) {
         category = CommonHelper.BADGE_TYPE.COMINGSOON
         label = `COMING SOON`
      } else {
         const remainingDuration = Math.abs(timeDifference.endDifference)
         if (this.informationPageHelper.hasPlaybackStartedWithinXDays(playbackStartDateTime)) {
            category = CommonHelper.BADGE_TYPE.NEW
            label = `NEW`
         }
         if (
            remainingDuration > CommonHelper.CONSTANTS.DAY_IN_SECONDS &&
            remainingDuration <=
               this.commonHelper.convertDaytoSecond(InformationPageHelper.CONSTANTS.CONTENT_EXPIRY_WINDOW) &&
            Math.sign(timeDifference.endDifference) == -1
         ) {
            category = CommonHelper.BADGE_TYPE.LEAVING
            label = `LEAVING IN  ${this.informationPageHelper.getRemainingDays(remainingDuration)}  `
         } else if (
            remainingDuration <= CommonHelper.CONSTANTS.DAY_IN_SECONDS &&
            Math.sign(timeDifference.endDifference) == -1
         ) {
            category = CommonHelper.BADGE_TYPE.LEAVING
            label = 'LEAVING TODAY'
         }
      }
      return { category, label }
   }

   /**
    * Tailor raw response into UI declarative object
    * @param-none
    * @returns-none
    */
   tailoredCollection() {
      const compiledElements = []

      for (let posterIndex = 0; posterIndex < this.preCompiledDetail.length; posterIndex++) {
         const posterItem = this.preCompiledDetail[posterIndex]
         const { image, dimension } = this.computeImage(this.type, posterItem)
         const timelineAnnouncement = this.computeTimeline(
            posterItem.playback_start_date as string,
            posterItem.playback_end_date as string,
            posterItem?.coming_soon_date,
         )
         this.item = {
            primaryText: '',
            secondaryText: '',
            contentType: posterItem.content_type,
            id: Number(posterItem?.id),
            card: {
               link: this.computeLink(posterItem, timelineAnnouncement?.category) ?? '',
               metaLink: this.computeLink(posterItem, timelineAnnouncement?.category) ?? '',
               linkAriaLabel: `${posterItem?.title},${posterIndex + 1} of ${this.preCompiledDetail.length} `,
               picture: image,
               dimension,
               title: posterItem?.title as string,
               isPlayable: this.commonHelper.isValidPlayBackStartDate(
                  this.preCompiledDetail[posterIndex].playback_start_date,
               ),
               progress: 0,
               isLazyloadDisabled: false,
               timelineAnnouncement: timelineAnnouncement,
               isContentExpired: CommonHelper.moment(posterItem.playback_end_date)
                  .utc()
                  .isBefore(CommonHelper.moment().utc()),
               playbackEndDate: posterItem.playback_end_date,
               genres: Array.isArray(posterItem?.genre)
                  ? posterItem?.genre?.join(',')
                  : posterItem?.genre?.trim() ?? '',
               contentId: String(posterItem?.assetId || 0),
               contentRef: String(posterItem?.seriesRef || posterItem?.movieRef || ''),
            },
         }
         if (this.showViewAll && posterIndex === this.preCompiledDetail.length - 1) {
            this.item = {
               primaryText: '',
               secondaryText: '',
               contentType: posterItem.content_type,
               card: {
                  link: `/watchlist`,
                  linkAriaLabel: ` View All, ${posterIndex + 1} of ${this.preCompiledDetail.length}`,
                  picture: '',
                  dimension: {
                     width: 0,
                     height: 0,
                     borderRadius: 0,
                  },
                  isLazyloadDisabled: false,
                  title: 'View All',
                  isPlayable: false,
                  progress: 0,
                  isContentExpired: false,
               },
            }
         }
         compiledElements.push(this.item)
      }

      return {
         title: this.title,
         type: this.type,
         elements: compiledElements,
         isInViewDetection: this.isInViewDetection,
      }
   }
}
