export enum RAIL_VARIANT {
   POSTER_HORIZONTAL = 'poster_horizontal',
   POSTER_VERTICAL = 'poster_vertical',
   CONTINUE_WATCHING = 'continue_watching',
   GENRE = 'genre',
   SPOTLIGHT = 'spotlight',
   HERO_CAROUSEL = 'hero_carousel',
   EPISODIC_RAIL = 'episodic_rail',
   THEMATIC = 'thematic',
   MY_WATCHLIST = 'my_list',
   COLLECTIONS = 'collections_rail',
   SINGULAR_PROMO = 'singular_promo',
}
