export const dynamic = 'force-dynamic'
export const fetchCache = 'force-no-store'
// redux core
import { createAppAsyncThunk } from '@/store/redux/createAppAsyncThunk'

export const fetchCapraConfigAsync = createAppAsyncThunk(
   'config/fetchCapraConfig',
   async (config, { rejectWithValue }) => {
      try {
         const response = await fetch(`${process.env.NEXT_PUBLIC_BASE_URL}/api/config`, { cache: 'no-store' })
         const responseBody = await response.json()
         if (responseBody?.success === false) {
            throw { ...responseBody }
         }
         return responseBody
      } catch (err: any) {
         return rejectWithValue(err)
      }
   },
)
