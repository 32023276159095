// redux
import type { ReduxState } from '@/store/redux'
import { createSelector } from 'reselect'

// helper
import InformationPageHelper from '@/app/_helper/global-helper/InformationPageHelper'

const informationPageHelper = new InformationPageHelper()

export const selectMovie = (state: ReduxState) => state.movie

export const selectMovieFetchStatus = (state: ReduxState) => state.movie.movieLandingStatus

export const selectMovieDetailFetchStatus = (state: ReduxState) => state.movie.movieDetailAsyncStatus

export const selectMovieDetailAsyncError = (state: ReduxState) => state.movie.movieDetailAsyncError

export const selectMovieAllFetched = (state: ReduxState) => state.movie.movieLandingFetchRockBottom

export const selectMovieDetail = createSelector([selectMovie], (series) => {
   const { movieDetail } = series
   return movieDetail
})

export const selectIsMovieWatchProgressSuccess = createSelector([selectMovie], (movie) => {
   const { watchProgressStatus } = movie
   return watchProgressStatus === 'complete' || watchProgressStatus === 'failed'
})

export const selectMovieWatchProgress = createSelector([selectMovie], (movie) => {
   const hasWatchedHistory =
      (movie.watchProgress?.watchPercentage ?? 0) > 0 && (movie.watchProgress?.watchPercentage ?? 0) < 98
   const strTimeLeft = informationPageHelper.calculateTimeLeft(
      movie.watchProgress?.progress,
      movie.watchProgress?.videoDuration,
   )
   return {
      progressDuration: hasWatchedHistory === true ? (movie.watchProgress?.progress ?? 0) / 1000 : 0,
      historyProgress: movie.watchProgress?.watchPercentage,
      hasHistory: hasWatchedHistory,
      timeLeft: strTimeLeft ? strTimeLeft.replace('min', 'minute').replace('hrs', 'hours') : '',
   }
})

export const selectMovieExtras = createSelector([selectMovie], (movie) => {
   const { extras } = movie
   return extras
})

export const selectComputedMovieExtras = createSelector([selectMovie], (movie) => {
   const { extras, extrasWatchProgress } = movie
   let computedExtras = []
   for (let extra of extras) {
      let temporaryCalculatedExtra = { ...extra }
      for (let extrasWatchProgressItem of extrasWatchProgress) {
         if (Number(extrasWatchProgressItem.assetId) === Number(extra.watchId)) {
            const strTimeLeft = informationPageHelper.calculateTimeLeft(
               extrasWatchProgressItem.progress,
               extrasWatchProgressItem.videoDuration,
            )
            temporaryCalculatedExtra = {
               ...extra,
               progressPercentage: extrasWatchProgressItem.watchPercentage,
               progressDuration: (extrasWatchProgressItem.progress ?? 0) / 1000,
               timeLeft: strTimeLeft ? strTimeLeft.replace('min', 'minutes').replace('hrs', 'hours') : '',
            }
            break
         } else {
            temporaryCalculatedExtra = { ...extra, progressPercentage: 0, progressDuration: 0 }
         }
      }
      computedExtras.push(temporaryCalculatedExtra)
   }
   return computedExtras
})

export const selectMovieLanding = createSelector([selectMovie], (movie) => {
   const { movieLanding } = movie
   return movieLanding
})

export const selectMoviePromoProgressStatus = createSelector([selectMovie], (movie) => {
   const { promoProgressStatus } = movie
   return promoProgressStatus
})
export const selectMovieFeedAsyncError = (state: ReduxState) => state.movie.movieLandingAsyncError
