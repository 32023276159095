import CommonHelper from '../CommonHelper'

// config
import { SHOW_NEW_LABEL_FROM_PSSD_LENGTH } from '@/configuration/global.config'

import { RAIL_VARIANT } from '../../../_utility/rail-variants'

// images
import K4Label from '@/public/images/4k_label.svg?url'
import DolbyVisionLabel from '@/public/images/dolby_vision_label.svg?url'
import DolbyAtmosLabel from '@/public/images/dolby_atmos_label.svg?url'
import Hdr10 from '@/public/images/HDR_10.svg?url'
import AdLabel from '@/public/images/ad_label.svg?url'
import FullHd from '@/public/images/full_hd.svg?url'
import SurroundSound from '@/public/images/surround_sound.svg?url'
import CcLabel from '@/public/images/cc_label.svg?url'

// types
import { ISeasonsBySeries, ICastCrewListItem } from './InformationPageHelper'

export default class InformationPageHelper {
   commonHelper!: CommonHelper
   static instance: InformationPageHelper | null = null
   static readonly UNIQUE_CONTENT_TYPE_KEY = {
      [RAIL_VARIANT.POSTER_HORIZONTAL]: 'h',
      [RAIL_VARIANT.POSTER_VERTICAL]: 'v',
      [RAIL_VARIANT.HERO_CAROUSEL]: 'hc',
      [RAIL_VARIANT.CONTINUE_WATCHING]: 'c',
      [RAIL_VARIANT.SPOTLIGHT]: 's',
      [RAIL_VARIANT.EPISODIC_RAIL]: 'eh',
   }

   static readonly CONSTANTS = {
      CONTENT_EXPIRY_WINDOW: 15,
      MAX_STARRING: 10,
      MAX_DIRECTORS: 2,
      MAX_EXEC_PRODUCERS: 4,
      MAX_WRITERS: 2,
      TRUNCATE_NAME: 10,
   }

   static readonly mediaQualityLabels = {
      '4k_full_hd': {
         path: K4Label,
         width: 27,
         height: 27,
         title: '4K',
      },
      'dolby_vision': {
         path: DolbyVisionLabel,
         width: 54,
         height: 27,
         title: 'Dolby Vision',
      },
      'dolby_atmos': {
         path: DolbyAtmosLabel,
         width: 54,
         height: 27,
         title: 'Dolby Atmos',
      },
      'HDR_10': {
         path: Hdr10,
         width: 51,
         height: 20,
         title: 'HDR 10',
      },
      'ad_dv': {
         path: AdLabel,
         width: 28,
         height: 27,
         title: 'ad dv',
      },
      'hd_sd': {
         path: FullHd,
         width: 30,
         height: 19,
         title: 'Full HD',
      },
      'surround': {
         path: SurroundSound,
         width: 26,
         height: 19,
         title: 'Surround Sound',
      },
      'cc': {
         path: CcLabel,
         width: 28,
         height: 27,
         title: 'Caption',
      },
   }

   constructor() {
      if (InformationPageHelper.instance) {
         return InformationPageHelper.instance
      }

      this.commonHelper = new CommonHelper()

      InformationPageHelper.instance = this
   }

   calculateTimeLeft(progress: number, duration: number) {
      const remainingTime = duration - progress
      if (remainingTime <= 1500) return ''

      let milliseconds = remainingTime
      let seconds = Math.floor(milliseconds / 1000)
      let minutes = Math.ceil(seconds / 60)
      let hours = Math.floor(minutes / 60)

      seconds %= 60
      minutes %= 60
      hours %= 24

      let remainingTimeString = ''

      if (hours > 0) {
         remainingTimeString += `${hours} ${hours > 1 ? 'hrs' : 'hr'} `
      }

      if (minutes >= 0 || hours > 0) {
         if (minutes == 0) {
            minutes = 1
         }
         remainingTimeString += `${minutes} ${minutes > 1 ? 'mins' : 'min'}`
      }

      return remainingTimeString ? remainingTimeString.trim() + ' Left' : ''
   }

   getRoundedDuration(milliseconds: number) {
      const momentDuration = CommonHelper.moment.duration(milliseconds)
      const totalMinutes = Math.ceil(momentDuration.asMinutes())

      if (totalMinutes < 60) {
         let minuteNotation = totalMinutes > 1 ? 'mins' : 'min'
         return `${totalMinutes} ${minuteNotation}`
      } else {
         let remaningHours = Math.floor(totalMinutes / 60)
         let remainingMinutes = totalMinutes % 60
         let hourNotation = remaningHours > 1 ? 'hrs' : 'hr'
         let minuteNotation = remainingMinutes > 1 ? 'mins' : 'min'
         let calculatedTime = `${remaningHours} ${hourNotation}`
         if (remainingMinutes > 0) calculatedTime += ` ${remainingMinutes} ${minuteNotation}`
         return calculatedTime
      }
   }

   mediaReleaseTimelineDiff(startDate: string, endDate: string, comingSoonDate?: string) {
      const currentDate = CommonHelper.moment().utc()
      const startAt = startDate ? CommonHelper.moment(startDate).utc() : null
      const endsAt = endDate ? CommonHelper.moment(endDate).utc() : null
      const comingSoon = comingSoonDate ? CommonHelper.moment(comingSoonDate).utc() : null
      const comingSoonDifference = CommonHelper.moment(currentDate).utc().diff(comingSoon) / 1000
      const startDifference = CommonHelper.moment(currentDate).utc().diff(startAt) / 1000
      const endDifference = CommonHelper.moment(currentDate).utc().diff(endsAt) / 1000
      return { currentDate, startAt, endsAt, startDifference, endDifference, comingSoonDifference }
   }

   hasPlaybackStartedWithinXDays(date: string) {
      if (typeof date !== 'string') return false

      const uniqueDate = CommonHelper.moment(date).utc()
      const startDate = uniqueDate.clone()
      const endDate = uniqueDate.clone().add(SHOW_NEW_LABEL_FROM_PSSD_LENGTH - 1, 'days')
      const currentDate = CommonHelper.moment().utc()
      return currentDate.isBetween(startDate, endDate, 'days', '[]')
   }

   isCategoryTagExist(categoryTags: string, category: string) {
      const tagArray = categoryTags?.split(',').map((tag) => tag.trim())
      return tagArray?.indexOf(category) !== -1 ? true : false
   }

   isDateOlderThanToday(date: string) {
      const previousDate = CommonHelper.moment(date).utc()
      return previousDate.isBefore(CommonHelper.moment().utc(), 'day')
   }

   getTabSectionByIndex(tabIndex: string) {
      let tabSection
      switch (tabIndex) {
         case '0':
            tabSection = 'info'
            break
         case '1':
            tabSection = 'cast-crew'
            break
         default:
            tabSection = 'info'
      }
      return tabSection
   }

   getTabIndexByTabSection(tabSection: string) {
      let tabIndex
      switch (tabSection) {
         case 'info':
            tabIndex = '0'
            break
         case 'cast-crew':
            tabIndex = '1'
            break
         default:
            tabIndex = '0'
      }
      return tabIndex
   }

   newSeasonTimelineDiff(newSeasonDate: string, startDate: string, endDate: string) {
      const currentDate = CommonHelper.moment().utc()
      const startAt = CommonHelper.moment(startDate).utc()
      const endsAt = CommonHelper.moment(endDate).utc()
      const newseasonAt = CommonHelper.moment(newSeasonDate).utc()
      const startDifference = CommonHelper.moment(newSeasonDate).utc().diff(startAt) / 1000
      const endDifference = CommonHelper.moment(newSeasonDate).utc().diff(endsAt) / 1000
      return { currentDate, startAt, endsAt, startDifference, endDifference, newseasonAt }
   }

   getRemainingDays(remainingDuration: number) {
      let dayCount = Math.floor(remainingDuration / CommonHelper.CONSTANTS.DAY_IN_SECONDS)
      let dayCountLabel = dayCount > 1 ? 'Days' : 'Day'
      return `${dayCount}  ${dayCountLabel}`
   }

   createSeasonCount(seasonCount: string) {
      const count = Number(seasonCount)
      return isNaN(count) === false ? `${Number(count)} SEASON${Number(count) > 1 ? 'S' : ''}` : undefined
   }

   createProductionYearRange(yearFrom = '', yearTo = '', seasonCount = '0') {
      const count = Number(seasonCount)
      return yearFrom !== '' && yearTo !== ''
         ? `${CommonHelper.moment(yearFrom).year()} ${
              yearTo.length > 0 && count > 1 ? ' - ' + CommonHelper.moment(yearTo).year() : ''
           }`
         : ''
   }

   isComingSoon(playbackStartDate: string, comingSoonDate: string) {
      if (typeof playbackStartDate !== 'string' || typeof playbackStartDate !== 'string') return false
      return CommonHelper.moment().isBetween(
         CommonHelper.moment(comingSoonDate).utc(),
         CommonHelper.moment(playbackStartDate).utc(),
         'second',
         '[]',
      )
   }

   // all info page
   selectSeasonIndices(seriesDetails: any) {
      const { seasonInfo, hasExtra } = seriesDetails

      const lengthOfSeasons = seasonInfo?.length
      if (lengthOfSeasons === 0) return []
      const seasonAndEpisodes = seasonInfo?.map((item: ISeasonsBySeries) => ({
         seasonNo: item.seasonNo,
      }))
      if (hasExtra === true) {
         seasonAndEpisodes?.splice(lengthOfSeasons!, 0, { seasonNo: 'extras' })
      }
      return seasonAndEpisodes
   }

   createMediaQualityLabels(mediaQualityProperties: { [key: string]: string }) {
      const qualityLabel = []
      for (const [key, value] of Object.entries(mediaQualityProperties)) {
         if (value.toLowerCase() !== 'n' && key in InformationPageHelper.mediaQualityLabels === true) {
            qualityLabel.push({
               title: InformationPageHelper.mediaQualityLabels[
                  key as keyof typeof InformationPageHelper.mediaQualityLabels
               ].title,
               picture:
                  InformationPageHelper.mediaQualityLabels[key as keyof typeof InformationPageHelper.mediaQualityLabels]
                     .path,
               width: InformationPageHelper.mediaQualityLabels[
                  key as keyof typeof InformationPageHelper.mediaQualityLabels
               ].width,
            })
         }
      }
      return qualityLabel
   }

   createCastCrewList(item: any, maxCount: number) {
      if (item.length <= 0) {
         return ''
      }
      const list = item.filter((el: string, idx: number) => {
         if (idx < maxCount) {
            return el
         }
      })
      return list.join(', ')
   }

   /**
    * Generates category and label
    *
    * @param playbackStartDate playback start date (PSSD) from brightcove
    * @param playbackEndDate playback end date (PSED) from brightcove
    * @param comingSoonDateTime coming soon date from brightcove
    * @returns {object} label and category
    */
   computeTimeline(
      playbackStartDateTime: string,
      playbackEndDateTime: string,
      comingSoonDateTime: string,
      isOriginal: boolean,
      railType?: string,
   ) {
      const timeDifference = this.mediaReleaseTimelineDiff(
         playbackStartDateTime,
         playbackEndDateTime,
         comingSoonDateTime,
      )

      let label = ''
      let category = ''

      if (timeDifference.startDifference <= 0) {
         if (isOriginal) {
            category = CommonHelper.BADGE_TYPE.PREMIERING
            label = `PREMIERING ${CommonHelper.moment(timeDifference.startAt).format('MMMM D')}`
         } else {
            category = CommonHelper.BADGE_TYPE.AVAILABLE
            label = `AVAILABLE ${CommonHelper.moment(timeDifference.startAt).format('MMMM D')}`
         }
      } else {
         const remainingDuration = Math.abs(timeDifference.endDifference)
         if (this.hasPlaybackStartedWithinXDays(playbackStartDateTime)) {
            category = CommonHelper.BADGE_TYPE.NEW
            label = `NEW`
         }
         if (
            remainingDuration > CommonHelper.CONSTANTS.DAY_IN_SECONDS &&
            remainingDuration <=
               this.commonHelper.convertDaytoSecond(InformationPageHelper.CONSTANTS.CONTENT_EXPIRY_WINDOW) &&
            Math.sign(timeDifference.endDifference) == -1
         ) {
            category = CommonHelper.BADGE_TYPE.LEAVING
            label = `LEAVING IN  ${this.getRemainingDays(remainingDuration)}  `
         } else if (
            remainingDuration <= CommonHelper.CONSTANTS.DAY_IN_SECONDS &&
            Math.sign(timeDifference.endDifference) == -1
         ) {
            category = CommonHelper.BADGE_TYPE.LEAVING
            label = 'LEAVING TODAY'
         }
      }
      return { category, label }
   }

   compiledCastCrewList(details: any) {
      let list: Array<ICastCrewListItem> = []

      if (details.actors && details.actors.length) {
         list.push({
            caption: 'STARRING',
            commaSeparatedArtists: this.createCastCrewList(
               details.actors,
               InformationPageHelper.CONSTANTS.MAX_STARRING,
            ),
         })
      }
      if (details.directors && details.directors.length) {
         list.push({
            caption: 'DIRECTORS',
            commaSeparatedArtists: this.createCastCrewList(
               details.directors,
               InformationPageHelper.CONSTANTS.MAX_DIRECTORS,
            ),
         })
      }
      if (details.exec_producers && details.exec_producers.length) {
         list.push({
            caption: 'EXECUTIVE PRODUCERS',
            commaSeparatedArtists: this.createCastCrewList(
               details.exec_producers,
               InformationPageHelper.CONSTANTS.MAX_EXEC_PRODUCERS,
            ),
         })
      }

      if (details.writers && details.writers.length) {
         list.push({
            caption: 'WRITERS',
            commaSeparatedArtists: this.createCastCrewList(
               details.writers,
               InformationPageHelper.CONSTANTS.MAX_WRITERS,
            ),
         })
      }
      return list
   }
}
