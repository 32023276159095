// helpers
import CommonHelper from '@/app/_helper/global-helper/CommonHelper'
import InformationPageHelper from '@/app/_helper/global-helper/InformationPageHelper'
import PosterHelper from '@/app/_helper/rail-helper/PosterHelper'

// types
import { IPreCompiledSearch, IPreCompiledPopularSearch } from './SearchHelper'

export default class SearchHelper {
   static instance: SearchHelper | null = null
   commonHelper!: CommonHelper
   informationPageHelper!: InformationPageHelper
   posterHelper!: PosterHelper

   // ::TODO:: Pagination story  https://thecapraproject.atlassian.net/browse/KING-1830
   //    static readonly contentType = {
   //       [RAIL_TYPE.POSTER_HORIZONTAL]: {
   //          label: 'poster_horizontal',
   //          results: 20,
   //       },
   //       [RAIL_TYPE.POSTER_VERTICAL]: {
   //          label: 'poster_vertical',
   //          results: 18,
   //       },
   //    }

   constructor() {
      if (SearchHelper.instance) {
         return SearchHelper.instance
      }

      this.commonHelper = new CommonHelper()
      this.informationPageHelper = new InformationPageHelper()

      SearchHelper.instance = this
   }

   createSearchData(railType: string, preCompiledSearch: IPreCompiledSearch[]) {
      let computedSearchListItem = []
      for (let searchItem of preCompiledSearch) {
         switch (railType) {
            case CommonHelper.RAIL_VARIANT.POSTER_HORIZONTAL:
               /* eslint-disable camelcase */
               this.posterHelper = new PosterHelper({
                  rail_type: CommonHelper.RAIL_VARIANT.POSTER_HORIZONTAL,
                  elements: [
                     {
                        video_id: searchItem.videoId,
                        id: searchItem.videoId,
                        content_type: searchItem.contentType,
                        duration: 0,
                        genre: [],
                        rating: '',
                        release_date: '',
                        isDirectVideoLink: true,
                        ...searchItem,
                     },
                  ],
                  banners: [],
                  id: '',
                  playlistId: '',
                  title: '',
                  showViewAll: false,
               })
               /* eslint-disable camelcase */
               computedSearchListItem.push(this.posterHelper.tailoredCollection()?.elements[0])
               break
         }
      }
      return computedSearchListItem
   }

   createPopularSearchData(railType: string, preCompiledSearch: IPreCompiledPopularSearch[]) {
      let computedSearchListItem = []
      for (let searchItem of preCompiledSearch) {
         switch (railType) {
            case CommonHelper.RAIL_VARIANT.POSTER_HORIZONTAL:
               /* eslint-disable camelcase */
               this.posterHelper = new PosterHelper({
                  rail_type: CommonHelper.RAIL_VARIANT.POSTER_HORIZONTAL,
                  elements: [{ ...searchItem }],
                  banners: [],
                  id: '',
                  playlistId: '',
                  title: '',
                  showViewAll: false,
               })
               /* eslint-disable camelcase */
               computedSearchListItem.push(this.posterHelper.tailoredCollection()?.elements[0])
               break
         }
      }
      return computedSearchListItem
   }
}
