import CommonHelper from '@/app/_helper/global-helper/CommonHelper'
import InformationPageHelper from '@/app/_helper/global-helper/InformationPageHelper'

import { getTransformedImageUrl } from '@/app/_utility/image-transformation'

import {
   IPreCompiledSeries,
   IPreCompiledEpisodes,
   IPreCompiledExtras,
   ISeasonsBySeries,
} from './SeriesInformationPageHelper'

import fallbackSeriesBanner from '@/public/images/fallback-banner-series.png'

export default class SeriesInformationPageHelper {
   commonHelper!: CommonHelper
   informationPageHelper!: InformationPageHelper

   static instance: SeriesInformationPageHelper | null = null

   static readonly CONSTANTS = {
      CONTENT_EXPIRY_WINDOW: 15,
      NEW: 'NEW',
      NEW_SEASON: 'NEW_SEASON',
      NEW_EPISODE: 'NEW_EPISODE',
   }

   constructor() {
      if (SeriesInformationPageHelper.instance) {
         return SeriesInformationPageHelper.instance
      }
      this.commonHelper = new CommonHelper()
      this.informationPageHelper = new InformationPageHelper()
      SeriesInformationPageHelper.instance = this
   }

   computeTimeline(
      startDate: string,
      endDate: string,
      comingSoonDate: string,
      isOriginal: boolean,
      newSeasonDate: string | null,
      dayOfTheWeek: string | null,
   ) {
      const timeDifference = this.informationPageHelper.mediaReleaseTimelineDiff(startDate, endDate, comingSoonDate)
      let label = ''
      let category = ''

      if (timeDifference.startDifference <= 0) {
         if (isOriginal) {
            category = CommonHelper.BADGE_TYPE.PREMIERING
            label = `Series Premier ${CommonHelper.moment(timeDifference.startAt).format('MMMM D')}`
         } else {
            category = CommonHelper.BADGE_TYPE.AVAILABLE
            label = `AVAILABLE ${CommonHelper.moment(timeDifference.startAt).format('MMMM D')}`
         }
      } else {
         if (newSeasonDate) {
            let newSeasonDateDifference = this.informationPageHelper.newSeasonTimelineDiff(
               newSeasonDate,
               startDate,
               endDate,
            )
            if (newSeasonDateDifference.startDifference >= 0 && newSeasonDateDifference.endDifference <= 0) {
               category = CommonHelper.BADGE_TYPE.NEWSEASON
               if (isOriginal) {
                  label = `Season Premier ${CommonHelper.moment(newSeasonDate).format('MMMM D')}`
               } else {
                  label = `New Season ${CommonHelper.moment(newSeasonDate).format('MMMM D')}`
               }
            } else if (dayOfTheWeek) {
               category = CommonHelper.BADGE_TYPE.NEWEPISODE
               label = `New Episode ${dayOfTheWeek}`
            }
         } else {
            if (dayOfTheWeek) {
               category = CommonHelper.BADGE_TYPE.NEWEPISODE
               label = `New Episode ${dayOfTheWeek}`
            } else {
               const remainingDuration = Math.abs(timeDifference.endDifference)
               if (
                  remainingDuration >= 86400 &&
                  remainingDuration <=
                     this.commonHelper.convertDaytoSecond(InformationPageHelper.CONSTANTS.CONTENT_EXPIRY_WINDOW) &&
                  Math.sign(timeDifference.endDifference) == -1
               ) {
                  let daysLabel = Math.floor(remainingDuration / 86400) > 1 ? 'DAYS' : 'DAY'
                  category = CommonHelper.BADGE_TYPE.LEAVING
                  label = `LEAVING IN ${this.informationPageHelper.getRemainingDays(remainingDuration)}  `
               } else if (remainingDuration <= 86400 && Math.sign(timeDifference.endDifference) == -1) {
                  category = CommonHelper.BADGE_TYPE.LEAVING
                  label = 'LEAVING TODAY'
               }
            }
         }
      }
      return { category, label }
   }

   createInfomationPageData(preCompiledSeries: IPreCompiledSeries) {
      const poster = (() => {
         return preCompiledSeries?.images?.hero_image?.indexOf('res.cloudinary.com') !== -1
            ? getTransformedImageUrl(
                 preCompiledSeries?.images?.hero_image,
                 CommonHelper.IMAGE_SIZES['hero_carousel']['high']['width'],
                 CommonHelper.IMAGE_SIZES['hero_carousel']['high']['height'],
                 CommonHelper.IMAGE_SIZES['hero_carousel']['high']['borderRadius'],
              )
            : ''
      })()

      const seriesLogo = (() => {
         return preCompiledSeries?.images?.logo?.indexOf('res.cloudinary.com') !== -1
            ? getTransformedImageUrl(
                 preCompiledSeries?.images?.logo,
                 CommonHelper.IMAGE_SIZES['logo']['high']['width'],
                 CommonHelper.IMAGE_SIZES['logo']['high']['height'],
                 CommonHelper.IMAGE_SIZES['logo']['high']['borderRadius'],
                 true,
              )
            : ''
      })()

      const timelineAnnouncement = (() => {
         return this.computeTimeline(
            preCompiledSeries.playback_start_date,
            preCompiledSeries.playback_end_date,
            preCompiledSeries.coming_soon_date,
            preCompiledSeries.isOriginal,
            preCompiledSeries.new_season_date,
            preCompiledSeries.dayOfTheWeek,
         )
      })()

      const castCrew = (() => {
         return [
            {
               title: 'Info',
               description: preCompiledSeries.synopsis,
            },
            {
               title: 'Cast & Crew',
               description: this.informationPageHelper.compiledCastCrewList(preCompiledSeries),
            },
         ]
      })()

      const hasNoCastCrew = (() => {
         return (
            preCompiledSeries.synopsis?.trim().length === 0 &&
            this.informationPageHelper.compiledCastCrewList(preCompiledSeries).length === 0
         )
      })()

      const isTrailer = (() => {
         return (
            !preCompiledSeries.hasVideo ||
            preCompiledSeries.content_type?.trim()?.toLowerCase() === 'series_trailer' ||
            CommonHelper.moment(preCompiledSeries.playback_start_date).utc().isAfter(CommonHelper.moment())
         )
      })()

      const releaseGenre = (() => {
         return [
            preCompiledSeries?.season_count &&
               this.informationPageHelper.createSeasonCount(preCompiledSeries.season_count!),
            !!preCompiledSeries?.initial_release_year?.trim() &&
               !!preCompiledSeries?.latest_release_year?.trim() &&
               this.informationPageHelper.createProductionYearRange(
                  preCompiledSeries.initial_release_year,
                  preCompiledSeries.latest_release_year,
                  preCompiledSeries.season_count,
               ),
            !!preCompiledSeries?.genre && preCompiledSeries.genre[0],
            !!preCompiledSeries?.rating && preCompiledSeries.rating,
         ]
            .filter(Boolean)
            .join(' · ')
      })()

      const isSeriesContentExpired = (() => {
         return CommonHelper.moment(preCompiledSeries?.playback_end_date)
            .utc()
            .isBefore(CommonHelper.moment().utc())
      })()

      const seasonIndices = ((seriesDetails) => {
         const { seasonInfo, hasExtra } = seriesDetails

         const lengthOfSeasons = seasonInfo?.length
         if (lengthOfSeasons === 0) return []
         const seasonAndEpisodes = seasonInfo?.map((item: ISeasonsBySeries) => ({
            seasonNo: item.seasonNo,
         }))
         if (hasExtra === true) {
            seasonAndEpisodes?.splice(lengthOfSeasons!, 0, { seasonNo: 'extras' })
         }
         return seasonAndEpisodes
      })(preCompiledSeries)

      return {
         id: preCompiledSeries.id,
         poster,
         fallbackPoster: fallbackSeriesBanner,
         mediaQualityLabel: this.informationPageHelper.createMediaQualityLabels(preCompiledSeries.properties!),
         seriesLogo,
         seriesTitle: preCompiledSeries.name,
         seriesDescription: preCompiledSeries.synopsis,
         seriesDescriptionLength: preCompiledSeries.synopsis?.split('').length,
         timelineAnnouncement,
         castCrew,
         hasNoCastCrew,
         isTrailer,
         hasExtra: preCompiledSeries.hasExtra,
         hasVideo: preCompiledSeries.hasVideo,
         hasTrailer: preCompiledSeries.hasTrailer,
         releaseGenre,
         genre: preCompiledSeries.genre,
         isSeriesContentExpired,
         seriesRef: preCompiledSeries.series_ref,
         seasonIndices,
         trailerId: preCompiledSeries.trailerID,
         trailerTitle: preCompiledSeries.trailerName,
         categoryTags: preCompiledSeries.category_tags,
         rating: preCompiledSeries.rating,
      }
   }

   createSeasonsEpisodes(preCompiledSeasonsEpisodes: IPreCompiledEpisodes[] & IPreCompiledExtras[], index: string) {
      const compiledSeasons = []

      const isAvailable = (playbackStartDate: any, comingSoonDate: any) => {
         const playbackStart = playbackStartDate ? CommonHelper.moment(playbackStartDate).utc() : null
         const comingSoon = comingSoonDate ? CommonHelper.moment(comingSoonDate).utc() : null
         const now = CommonHelper.moment().utc()
         const comingSoonDifference = CommonHelper.moment(now).utc().diff(comingSoon) / 1000
         const startDifference = CommonHelper.moment(now).utc().diff(playbackStart) / 1000
         const checkAvaiability = comingSoonDifference >= 0 && startDifference <= 0 ? true : false
         return checkAvaiability
      }

      const computeTimelineEpisodes = (
         playbackStartDateTime: string,
         playbackEndDateTime: string,
         comingSoonDateTime?: string,
      ) => {
         const timeDifference = this.informationPageHelper.mediaReleaseTimelineDiff(
            playbackStartDateTime,
            playbackEndDateTime,
            comingSoonDateTime,
         )
         let label = ''
         let category = ''

         if (timeDifference.comingSoonDifference >= 0 && timeDifference.startDifference <= 0) {
            category = CommonHelper.BADGE_TYPE.COMINGSOON
            label = `COMING SOON`
         } else {
            const remainingDuration = Math.abs(timeDifference.endDifference)
            if (this.informationPageHelper.hasPlaybackStartedWithinXDays(playbackStartDateTime)) {
               category = CommonHelper.BADGE_TYPE.NEW
               label = `NEW`
            }
            if (
               remainingDuration > CommonHelper.CONSTANTS.DAY_IN_SECONDS &&
               remainingDuration <=
                  this.commonHelper.convertDaytoSecond(InformationPageHelper.CONSTANTS.CONTENT_EXPIRY_WINDOW) &&
               Math.sign(timeDifference.endDifference) == -1
            ) {
               category = CommonHelper.BADGE_TYPE.LEAVING
               label = `LEAVING IN  ${this.informationPageHelper.getRemainingDays(remainingDuration)}  `
            } else if (
               remainingDuration <= CommonHelper.CONSTANTS.DAY_IN_SECONDS &&
               Math.sign(timeDifference.endDifference) == -1
            ) {
               category = CommonHelper.BADGE_TYPE.LEAVING
               label = 'LEAVING TODAY'
            }
         }
         return { category, label }
      }

      for (let i = 0; i < preCompiledSeasonsEpisodes.length; i++) {
         if (index === 'extras') {
            compiledSeasons.push({
               poster: getTransformedImageUrl(
                  preCompiledSeasonsEpisodes[i].stillFrame ?? preCompiledSeasonsEpisodes[i]?.hPoster,
                  CommonHelper.IMAGE_SIZES['still_frame'].high.width,
                  CommonHelper.IMAGE_SIZES['still_frame'].high.height,
                  CommonHelper.IMAGE_SIZES['still_frame'].high.borderRadius,
               ),
               title: preCompiledSeasonsEpisodes[i].name,
               synopsis: preCompiledSeasonsEpisodes[i].description,
               episodeNo: '0',
               duration: this.informationPageHelper.getRoundedDuration(preCompiledSeasonsEpisodes[i].duration),
               releaseDate: '',
               id: preCompiledSeasonsEpisodes[i].id,
               progressDuration: 0,
               progressPercentage: 0,
               timeLeft: '',
               isEpisodeContentExpired: CommonHelper.moment(preCompiledSeasonsEpisodes[i].playback_end_date)
                  .utc()
                  .isBefore(CommonHelper.moment().utc()),
               playbackEndDate: preCompiledSeasonsEpisodes[i].playback_end_date,
               rating: preCompiledSeasonsEpisodes[i]['custom_fields']['rating'],
            })
         } else {
            compiledSeasons.push({
               poster: getTransformedImageUrl(
                  preCompiledSeasonsEpisodes[i].stillFrame,
                  CommonHelper.IMAGE_SIZES['still_frame'].high.width,
                  CommonHelper.IMAGE_SIZES['still_frame'].high.height,
                  CommonHelper.IMAGE_SIZES['still_frame'].high.borderRadius,
               ),
               title: preCompiledSeasonsEpisodes[i].title,
               synopsis: preCompiledSeasonsEpisodes[i].synopsis,
               episodeNo: preCompiledSeasonsEpisodes[i].episodeNo,
               duration: this.informationPageHelper.getRoundedDuration(preCompiledSeasonsEpisodes[i].duration),
               releaseDate: preCompiledSeasonsEpisodes[i].releaseDate
                  ? CommonHelper.moment(preCompiledSeasonsEpisodes[i].releaseDate).format('MMM DD, YYYY')
                  : '',
               id: preCompiledSeasonsEpisodes[i].id,
               progressDuration: 0,
               progressPercentage: 0,
               timeLeft: '',
               isComingSoon: isAvailable(
                  preCompiledSeasonsEpisodes[i].playback_start_date,
                  preCompiledSeasonsEpisodes[i].coming_soon_date,
               ),
               airDate: preCompiledSeasonsEpisodes[i].playback_start_date
                  ? CommonHelper.moment(preCompiledSeasonsEpisodes[i].playback_start_date).format('MMM DD, YYYY')
                  : '',
               isEpisodeContentExpired: CommonHelper.moment(preCompiledSeasonsEpisodes[i].playback_end_date)
                  .utc()
                  .isBefore(CommonHelper.moment().utc()),
               playbackEndDate: preCompiledSeasonsEpisodes[i].playback_end_date,
               timelineAnnouncement: computeTimelineEpisodes(
                  preCompiledSeasonsEpisodes[i].playback_start_date,
                  preCompiledSeasonsEpisodes[i].playback_end_date,
                  preCompiledSeasonsEpisodes[i].coming_soon_date,
               ),
               rating: preCompiledSeasonsEpisodes[i].rating,
            })
         }
      }

      return compiledSeasons
   }
}
