// redux core
import { createAppAsyncThunk } from '@/store/redux/createAppAsyncThunk'

export const fetchProfileAsync = createAppAsyncThunk(
   'user/fetchProfiles',
   async (profile: { currentProfileId?: string }, { rejectWithValue }) => {
      try {
         const { currentProfileId } = profile
         const profilerResponse = await fetch(`${process.env.NEXT_PUBLIC_BASE_URL}/api/secure/profile`, {
            cache: 'no-store',
         })

         const profilerResponseBody = await profilerResponse.json()
         if (profilerResponseBody.success === false) {
            return rejectWithValue(profilerResponseBody.errorCode)
         }
         return { ...profilerResponseBody, currentProfileId }
      } catch (err: any) {
         return rejectWithValue(err)
      }
   },
)

export const updateProfileAsync = createAppAsyncThunk(
   'user/updateProfile',
   async (profile: { picUrl: string; profileId: string }, { rejectWithValue }) => {
      try {
         const response = await fetch(`${process.env.NEXT_PUBLIC_BASE_URL}/api/secure/profile`, {
            method: 'PUT',
            body: JSON.stringify({
               ...profile,
            }),
         })
         return await response.json()
      } catch (err: any) {
         return rejectWithValue(err)
      }
   },
)

export const switchProfileAsync = createAppAsyncThunk(
   'user/switchProfile',
   async (profile: { profileId: string }, { rejectWithValue }) => {
      try {
         await fetch(`${process.env.NEXT_PUBLIC_BASE_URL}/api/secure/profile/swap`, {
            method: 'POST',
            body: JSON.stringify({
               newProfileId: profile.profileId,
            }),
         })

         return { profileId: profile.profileId }
      } catch (err: any) {
         return rejectWithValue(err)
      }
   },
)

export const initialAvatarAsync = createAppAsyncThunk(
   'user/initialAvatar',
   (imgObj: { imgURL: string; imgKey: string }) => {
      return imgObj
   },
)

export const signOutAsync = createAppAsyncThunk('user/signout', () => {
   return true
})

export const getActiveSubscriptionAsync = createAppAsyncThunk(
   'user/activeSubscription',
   async (data: {}, { rejectWithValue }) => {
      try {
         const response = await fetch(`${process.env.NEXT_PUBLIC_BASE_URL}/api/account/activesubscriptions`, {
            method: 'POST',
            body: JSON.stringify({
               ...data,
            }),
         })
         return await response.json()
      } catch (err: any) {
         return rejectWithValue(err)
      }
   },
)

export const getPaymentMethodsAsync = createAppAsyncThunk(
   'user/paymentMethods',
   async (data: {}, { rejectWithValue }) => {
      try {
         const response = await fetch(`${process.env.NEXT_PUBLIC_BASE_URL}/api/account/getpaymentmethods`, {
            method: 'POST',
            body: JSON.stringify({
               ...data,
            }),
         })
         return await response.json()
      } catch (err: any) {
         return rejectWithValue(err)
      }
   },
)

export const getReasonCodesAsync = createAppAsyncThunk('user/getReasonCodes', async (data, { rejectWithValue }) => {
   try {
      const response = await fetch(`${process.env.NEXT_PUBLIC_BASE_URL}/api/account/getreasoncodes`, {
         method: 'POST',
         body: JSON.stringify({
            data,
         }),
      })
      return await response.json()
   } catch (err: any) {
      return rejectWithValue(err)
   }
})

export const removeSubscriptionAsync = createAppAsyncThunk(
   'user/removeSubscription',
   async (data: { serviceID: string; serviceType: string }, { rejectWithValue }) => {
      try {
         const response = await fetch(`${process.env.NEXT_PUBLIC_BASE_URL}/api/account/removesubscription`, {
            method: 'POST',
            body: JSON.stringify({
               serviceID: data.serviceID,
               serviceType: 'PRODUCT',
            }),
         })

         return await response.json()
      } catch (err: any) {
         return rejectWithValue(err)
      }
   },
)

export const reasonForCancellationAsync = createAppAsyncThunk(
   'user/reasonForCancellation',
   async (data: { ordProdId: string; reasons: [] }, { rejectWithValue }) => {
      try {
         const response = await fetch(`${process.env.NEXT_PUBLIC_BASE_URL}/api/account/reasonforcancellation`, {
            method: 'POST',
            body: JSON.stringify({
               ordProdId: data.ordProdId,
               reasons: data.reasons,
            }),
         })

         return await response.json()
      } catch (err: any) {
         return rejectWithValue(err)
      }
   },
)
