// redux core
import { createAppAsyncThunk } from '@/store/redux/createAppAsyncThunk'

export const recordWatchProgressAsync = createAppAsyncThunk(
   'player/recordWatchProgress',
   async (player: { watchProgress: any }, { rejectWithValue }) => {
      const { watchProgress } = player
      try {
         await fetch(`${process.env.NEXT_PUBLIC_BASE_URL}/api/secure/player/updatewatchprogress`, {
            method: 'PUT',
            body: JSON.stringify(watchProgress),
         })
         // return await response.json()
      } catch (err: any) {
         return rejectWithValue(err)
      }
   },
)

export const recordUpNextMetaAsync = createAppAsyncThunk(
   'player/recordUpNextMeta',
   async (player: { videoId: string }, { rejectWithValue }) => {
      const { videoId } = player
      try {
         const upnextResponse = await fetch(
            `${process.env.NEXT_PUBLIC_BASE_URL}/api/secure/player/nextepisode?videoId=${videoId}`,
         )
         const upnextResponseBody = await upnextResponse.json()
         return upnextResponseBody
      } catch (err: any) {
         return rejectWithValue(err)
      }
   },
)
