// helpers
import CommonHelper from '@/app/_helper/global-helper/CommonHelper'
import InformationPageHelper from '@/app/_helper/global-helper/InformationPageHelper'
import PosterHelper from '@/app/_helper/rail-helper/PosterHelper'

// types
import { RAIL_TYPE, IPreCompiledViewAll } from './ViewallHelper.d'

export default class ViewallHelper {
   static instance: ViewallHelper | null = null
   commonHelper!: CommonHelper
   informationPageHelper!: InformationPageHelper
   posterHelper!: PosterHelper

   static readonly contentType = {
      [RAIL_TYPE.POSTER_HORIZONTAL]: {
         label: 'poster_horizontal',
         results: 20,
      },
      [RAIL_TYPE.POSTER_VERTICAL]: {
         label: 'poster_vertical',
         results: 18,
      },
      [RAIL_TYPE.EPISODIC_RAIL]: {
         label: 'episodic_rail',
         results: 20,
      },
   }

   constructor() {
      if (ViewallHelper.instance) {
         return ViewallHelper.instance
      }

      this.commonHelper = new CommonHelper()
      this.informationPageHelper = new InformationPageHelper()

      ViewallHelper.instance = this
   }

   createViewAllData(railType: string, preCompiledViewAll: IPreCompiledViewAll[]) {
      let computedPlaylistItem = []
      for (let viewAllItem of preCompiledViewAll) {
         switch (railType) {
            case CommonHelper.RAIL_VARIANT.POSTER_HORIZONTAL:
               /* eslint-disable camelcase */

               this.posterHelper = new PosterHelper({
                  rail_type: CommonHelper.RAIL_VARIANT.POSTER_HORIZONTAL,
                  elements: [{ video_id: viewAllItem.id, ...viewAllItem }],
                  banners: [],
                  id: '',
                  playlistId: '',
                  title: '',
                  showViewAll: false,
               })
               /* eslint-disable camelcase */
               computedPlaylistItem.push(this.posterHelper.tailoredCollection()?.elements[0])
               break

            case CommonHelper.RAIL_VARIANT.POSTER_VERTICAL:
               /* eslint-disable camelcase */
               this.posterHelper = new PosterHelper({
                  rail_type: CommonHelper.RAIL_VARIANT.POSTER_VERTICAL,
                  elements: [{ video_id: viewAllItem.id, ...viewAllItem }],
                  banners: [],
                  id: '',
                  playlistId: '',
                  title: '',
                  showViewAll: false,
               })
               /* eslint-disable camelcase */
               computedPlaylistItem.push(this.posterHelper.tailoredCollection()?.elements[0])
               break
            case CommonHelper.RAIL_VARIANT.EPISODIC_RAIL:
               /* eslint-disable camelcase */

               this.posterHelper = new PosterHelper({
                  rail_type: CommonHelper.RAIL_VARIANT.POSTER_HORIZONTAL,
                  elements: [{ video_id: viewAllItem.id, ...viewAllItem }],
                  banners: [],
                  id: '',
                  playlistId: '',
                  title: '',
                  showViewAll: false,
               })
               /* eslint-disable camelcase */
               computedPlaylistItem.push(this.posterHelper.tailoredEpisodeCollection()?.elements[0])
               break
         }
      }
      return computedPlaylistItem
   }
}
