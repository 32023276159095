// redux
import type { ReduxState } from '@/store/redux'
import { createSelector } from 'reselect'

// helper
import InformationPageHelper from '@/app/_helper/global-helper/InformationPageHelper'

const informationPageHelper = new InformationPageHelper()

export const selectSeries = (state: ReduxState) => state.series

export const selectSeriesFetchStatus = (state: ReduxState) => state.series.seriesLandingStatus

export const selectSeriesDetailsFetchStatus = (state: ReduxState) => state.series.seriesDetailAsyncStatus

export const selectSeriesDetailAsyncError = (state: ReduxState) => state.series.seriesDetailAsyncError
export const selectSeriesAllFetched = (state: ReduxState) => state.series.seriesLandingFetchRockBottom
export const selectSeasonsAsyncStatus = (state: ReduxState) => state.series.seasonsAsyncStatus
export const selectSeasonsAsyncError = (state: ReduxState) => state.series.seasonsAsyncError
export const selectSeriesDetail = createSelector([selectSeries], (series) => {
   const { seriesDetail } = series
   return seriesDetail
})

/**
 * Tailored seasons of series
 */
export const selectSeasons = createSelector([selectSeries], (series) => {
   const { seasons } = series
   return seasons
})

/**
 * Tailored history watchec progress of series
 */
export const selectSeriesWatchedHistory = createSelector([selectSeries], (series) => {
   const { watchProgress, seasons } = series
   let episodename = ''
   if (seasons && watchProgress) {
      for (let [seasonIndex, season] of Object.entries(seasons)) {
         episodename = season[watchProgress.episodeNumber - 1]?.title
      }
   }
   const objWatchProgress = Object.assign({ episodename: episodename }, watchProgress)
   return objWatchProgress
})

/**
 * Calculate tailored seasons with history progress for UI
 */
export const selectSeasonsWithMetrics = createSelector([selectSeries], (series) => {
   const { seasons, episodeWatchProgress } = series
   let calculatedSeasonsEpisode: { [key: string]: any } = {}
   if (seasons) {
      for (let [seasonIndex, season] of Object.entries(seasons)) {
         calculatedSeasonsEpisode[seasonIndex] = []
         let temporaryEpisodePlaceholder = []
         let watchProgressCollection = episodeWatchProgress[seasonIndex] ?? []
         for (let seasonItem of season) {
            for (let watchProgressItem of watchProgressCollection) {
               if (Number(watchProgressItem.assetId) === Number(seasonItem.id)) {
                  const strTimeLeft = informationPageHelper.calculateTimeLeft(
                     watchProgressItem.progress,
                     watchProgressItem.videoDuration,
                  )
                  seasonItem = {
                     ...seasonItem,
                     progressPercentage: watchProgressItem.watchPercentage,
                     progressDuration: watchProgressItem.progress / 1000,
                     timeLeft: strTimeLeft ? strTimeLeft.replace('min', 'minute').replace('hrs', 'hours') : '',
                     rating: seasonItem.rating,
                  }
                  break
               } else {
                  seasonItem = { ...seasonItem, progressPercentage: 0, progressDuration: 0, timeLeft: '' }
               }
            }
            temporaryEpisodePlaceholder.push(seasonItem)
         }
         calculatedSeasonsEpisode[seasonIndex] = temporaryEpisodePlaceholder
      }
   }
   return calculatedSeasonsEpisode
})

/**
 * For no history progress, calculate first season, episode
 */
export const selectFirstSeasonFirstEpisode = createSelector([selectSeries], (series) => {
   const { seasons } = series
   let firstEpisode = null
   if (seasons) {
      if (Array.isArray(seasons[1]) && Object.keys(seasons[1][0]).length > 0) {
         firstEpisode = {
            episodeTitle: seasons[1][0]['title'],
            episodeWatchId: seasons[1][0]['id'],
            isEpisodeContentExpired: seasons[1][0]['isEpisodeContentExpired'],
            playbackEndDate: seasons[1][0]['playbackEndDate'],
         }
      }
   }
   return firstEpisode
})

export const selectAllEpisodeAvailability = createSelector([selectSeries], (series) => {
   const { seasons } = series
   let episodeAvailability: any = []
   if (seasons) {
      seasons[series.activeSeasonsEpisodesTab]?.forEach((episode: any) => {
         episodeAvailability.push(episode)
      })
   }
   return episodeAvailability
})
/**
 * calculate expiry state of historically watched content,
 * in comparison seasons & episode object array
 * */
export const selectIsSeriesHistoryContentExpired = createSelector(
   [selectSeasons, selectSeriesWatchedHistory],
   (seasons, watchProgress) => {
      let isEpisodeContentExpired = false
      for (let [seasonIndex, season] of Object.entries(seasons)) {
         for (let episodeItem of season) {
            if (
               watchProgress.episodeId === Number(episodeItem['id']) &&
               episodeItem['isEpisodeContentExpired'] === true
            ) {
               isEpisodeContentExpired = true
               break
            }
         }
      }

      return isEpisodeContentExpired
   },
)

export const selectWatchProgressStatus = createSelector([selectSeries], (series) => {
   const { watchProgressStatus } = series
   return watchProgressStatus
})

// success status of series history fetch
export const selectIsSeriesHistoryFetchedSuccessfully = createSelector([selectSeries], (series) => {
   const { watchProgressStatus } = series
   return watchProgressStatus === 'complete'
})

// failed status of series history fetch
export const selectIsSeriesHistoryFetchedFailed = createSelector([selectSeries], (series) => {
   const { watchProgressStatus } = series
   return watchProgressStatus === 'failed'
})

export const selectIsSeriesWatchProgressSuccess = createSelector([selectSeries], (series) => {
   const { watchProgressStatus } = series
   return watchProgressStatus === 'complete' || watchProgressStatus === 'failed'
})

export const selectSeriesLanding = createSelector([selectSeries], (series) => {
   const { seriesLanding } = series
   return seriesLanding
})

export const selectWatchProgressAsyncError = createSelector([selectSeries], (series) => {
   const { watchProgressAsyncError } = series
   return watchProgressAsyncError
})

// export const selectSeriesSinglePromo = createSelector([selectSeries], (series) => {
//    const { singlePromoProgress } = series
//    return singlePromoProgress
// })
export const selectSeriesPomoProgressStatus = createSelector([selectSeries], (series) => {
   const { promoProgressStatus } = series
   return promoProgressStatus
})
