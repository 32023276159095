import InformationPageHelper from '@/app/_helper/global-helper/InformationPageHelper'
import CommonHelper from '../../../../_helper/global-helper/CommonHelper'
import ContinueWatchingHelper from '../../../../_helper/rail-helper/ContinueWatchingHelper'
import HeroCarouselHelper from '../../../../_helper/rail-helper/HeroCarouselHelper'
import PosterHelper from '../../../../_helper/rail-helper/PosterHelper'
import GenreHelper from '../../../../_helper/rail-helper/GenreHelper'
import ThematicHelper from '@/app/_helper/rail-helper/ThematicHelper'
import YmalHelper from '@/app/_helper/rail-helper/YmalHelper'
import WatchlistHelper from '@/app/_helper/rail-helper/WatchlistHelper'
import CollectionsHelper from '@/app/_helper/rail-helper/CollectionsHelper'
import {
   IFeedRail,
   IPreCompiledHeroCarousel,
   IPreCompiledPoster,
   IContinueWatchingRail,
   CompiledFeedCollectionProps,
   EpisodicRailWatchedProgressProps,
   PreCompiledThematicPosterProps,
   PreCompiledYmalPosterProps,
   PreCompiledYmalElementProps,
   PreCompiledWatchlistProps,
} from './FeedHelper'
import SingularPromoHelper from '@/app/_helper/rail-helper/SingularPromo'

export default class FeedHelper {
   commonHelper!: CommonHelper
   continueWatchingHelper!: ContinueWatchingHelper
   informationPageHelper!: InformationPageHelper
   heroCarouselHelper!: HeroCarouselHelper
   posterHelper!: PosterHelper
   genreHelper!: GenreHelper
   thematicHelper!: ThematicHelper
   ymalHelper!: YmalHelper
   watchlistHelper!: WatchlistHelper
   collectionsHelper!: CollectionsHelper
   singularPromoHelper!: SingularPromoHelper

   static instance: FeedHelper | null = null
   readonly feedResponse = [] as IFeedRail[]

   constructor() {
      if (FeedHelper.instance) {
         return FeedHelper.instance
      }
      this.informationPageHelper = new InformationPageHelper()
      this.commonHelper = new CommonHelper()
      FeedHelper.instance = this
   }

   /**
    *
    * @param {object} feedResponse - feed api response
    * @returns {Array} Compiled, UI declarative collection of rail array
    */
   createFeedData(feedResponse: IFeedRail[]) {
      // loop through the rails and generate tailored data for feed page
      let overallRails = []
      for (let railItem of feedResponse) {
         if (railItem?.elements?.length === 0) continue

         switch (railItem.rail_type) {
            case CommonHelper.RAIL_VARIANT.HERO_CAROUSEL:
               this.heroCarouselHelper = new HeroCarouselHelper(railItem as IPreCompiledHeroCarousel)

               overallRails.push(this.heroCarouselHelper.tailoredCollection())
               break

            case CommonHelper.RAIL_VARIANT.POSTER_HORIZONTAL:
               this.posterHelper = new PosterHelper(railItem as IPreCompiledPoster)
               overallRails.push(this.posterHelper.tailoredCollection())
               break

            case CommonHelper.RAIL_VARIANT.POSTER_VERTICAL:
               this.posterHelper = new PosterHelper(railItem as IPreCompiledPoster)
               overallRails.push(this.posterHelper.tailoredCollection())
               break

            case CommonHelper.RAIL_VARIANT.CONTINUE_WATCHING:
               this.continueWatchingHelper = new ContinueWatchingHelper(railItem as IContinueWatchingRail)
               overallRails.push(
                  this.continueWatchingHelper.tailoredCollection(CommonHelper.RAIL_VARIANT.CONTINUE_WATCHING),
               )
               break

            case CommonHelper.RAIL_VARIANT.EPISODIC_RAIL:
               this.continueWatchingHelper = new ContinueWatchingHelper(railItem as IContinueWatchingRail)
               overallRails.push(
                  this.continueWatchingHelper.tailoredCollection(CommonHelper.RAIL_VARIANT.EPISODIC_RAIL),
               )
               break

            case CommonHelper.RAIL_VARIANT.GENRE:
               this.genreHelper = new GenreHelper(railItem as any)
               overallRails.push(this.genreHelper.tailoredCollection())
               break

            case CommonHelper.RAIL_VARIANT.THEMATIC:
               this.thematicHelper = new ThematicHelper(railItem as PreCompiledThematicPosterProps)
               overallRails.push(this.thematicHelper.tailoredCollection())
               break

            case CommonHelper.RAIL_VARIANT.MY_WATCHLIST:
               this.watchlistHelper = new WatchlistHelper(railItem as PreCompiledWatchlistProps)
               overallRails.push(this.watchlistHelper.tailoredCollection())
               break

            case CommonHelper.RAIL_VARIANT.COLLECTIONS:
               this.collectionsHelper = new CollectionsHelper(railItem as any)
               overallRails.push(this.collectionsHelper.tailoredCollection())
               break

            case CommonHelper.RAIL_VARIANT.SINGULAR_PROMO:
               this.singularPromoHelper = new SingularPromoHelper(railItem as any)
               overallRails.push(this.singularPromoHelper.tailoredCollection())
               break
         }
      }

      return overallRails
   }
   /**
    *
    * @param {object} continueWatchngResponse - continue watching api response
    * @returns {object} Compiled, UI declarative continue watching object
    */
   createContinueWatchingData(continueWatchngResponse: IContinueWatchingRail) {
      this.continueWatchingHelper = new ContinueWatchingHelper(continueWatchngResponse)
      return this.continueWatchingHelper.tailoredCollection(CommonHelper.RAIL_VARIANT.CONTINUE_WATCHING)
   }

   /**
    *
    * @param {object} episodicWatchProgress - episodic WatchProgress api response
    * @returns {object} Compiled, UI declarative episodic WatchProgress rail object
    */
   createEpisodicRailData(
      currentEpisodicRail: CompiledFeedCollectionProps,
      episodicRailProgress: EpisodicRailWatchedProgressProps[],
   ) {
      const computedCurrentEpisodicRailElements = currentEpisodicRail?.elements?.map((currentEpisodicRailItem) => {
         let progress = 0
         let resume = 0
         let remainingTime = ''
         let linkAriaLabel = currentEpisodicRailItem.card.linkAriaLabel

         const matchedEpisodeDetail = episodicRailProgress?.filter(
            (episodicRailProgressItem) => episodicRailProgressItem.assetId === currentEpisodicRailItem.videoId,
         )
         if (matchedEpisodeDetail?.length > 0) {
            resume = matchedEpisodeDetail[0]?.progress / 1000
            progress = (matchedEpisodeDetail[0]?.progress / matchedEpisodeDetail[0].videoDuration) * 100
            remainingTime = this.informationPageHelper.calculateTimeLeft(
               matchedEpisodeDetail[0].progress,
               matchedEpisodeDetail[0].videoDuration,
            )
         }

         if (remainingTime?.trim()?.length > 0) {
            const computedString = this.commonHelper.computeAriaRemainingTime(remainingTime)
            const lastCommaIndex = currentEpisodicRailItem.card.linkAriaLabel.lastIndexOf(',')
            const updatedStr = `${currentEpisodicRailItem.card.linkAriaLabel.slice(
               0,
               lastCommaIndex,
            )} ${computedString}${currentEpisodicRailItem.card.linkAriaLabel.slice(lastCommaIndex)}`
            linkAriaLabel = updatedStr
         }
         const link = progress
            ? currentEpisodicRailItem.card.link.replace(/(resume=)[^\&]+/, '$1' + resume)
            : currentEpisodicRailItem.card.link

         return {
            ...currentEpisodicRailItem,
            card: { ...currentEpisodicRailItem.card, link, progress, remainingTime, linkAriaLabel },
         }
      })

      return {
         ...currentEpisodicRail,
         elements: computedCurrentEpisodicRailElements,
      }
   }

   /**
    *
    * @param {object} thematicPosterResponse - thematic poster api response
    * @returns {object} Compiled, UI declarative thematic poster object
    */
   createYmalData(feedResponse: any) {
      this.ymalHelper = new YmalHelper(feedResponse as PreCompiledYmalPosterProps)
      return this.ymalHelper.tailoredCollection()
   }

   /**
    *
    * @param {object} promoWatchProgress - episodic WatchProgress api response
    * @returns {object} Compiled, UI declarative episodic WatchProgress rail object
    */
   createPromoRailData(currentPromoRail: any, promoWatchProgress: any, movieDetail: any) {
      const computedCurrentPromoRailElements = currentPromoRail?.card
      let progress = 0
      let resume = 0
      let remainingTime = ''
      let linkAriaLabel = ''
      let episodeNo = null
      let seriesNumber = null
      let videoId = null
      let link = computedCurrentPromoRailElements.link
      let progressMovie = 0
      let isTrailer = null
      let watchPercentage = null
      let seriesProgressPercentage = null

      if (promoWatchProgress && movieDetail) {
         resume = promoWatchProgress?.progress / 1000
         progress = promoWatchProgress?.progress
         progressMovie = promoWatchProgress[0]?.progress
         remainingTime = this.informationPageHelper.calculateTimeLeft(
            promoWatchProgress.progress,
            promoWatchProgress.videoDuration,
         )
         episodeNo = promoWatchProgress?.episode_no || null
         seriesNumber = promoWatchProgress?.season_number || null
         videoId = promoWatchProgress?.video_id || null
         isTrailer = movieDetail?.isTrailer
         watchPercentage = promoWatchProgress[0]?.watchPercentage
         seriesProgressPercentage = (promoWatchProgress?.progress / promoWatchProgress.videoDuration) * 100
      }
      // :TODO: Need to revisit this  on a11y
      // if (remainingTime?.trim()?.length > 0) {
      //    linkAriaLabel =
      //    computedCurrentPromoRailElements.card.linkAriaLabel + this.commonHelper.computeAriaRemainingTime(remainingTime)
      // }
      link = `/watch?video=${
         computedCurrentPromoRailElements.seriesRef ? promoWatchProgress.video_id : computedCurrentPromoRailElements.id
      }`
      return {
         ...currentPromoRail,
         card: {
            ...computedCurrentPromoRailElements,
            link,
            progress,
            progressMovie,
            episodeNo,
            seriesNumber,
            videoId,
            isTrailer,
            watchPercentage,
            seriesProgressPercentage,
         },
         elements: [],
      }
   }
}
