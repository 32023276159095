// redux core
import { createAppAsyncThunk } from '@/store/redux/createAppAsyncThunk'
import { ReduxState, ReduxDispatch } from '../../../store'

// helper
import SeriesHelper from '@/app/(auth)/series/_helper/SeriesHelper'

const seriesHelperInstance = new SeriesHelper()

export const fetchSeriesAsync = createAppAsyncThunk<
   any,
   { skip: number; limit: number; canonicalName: string },
   { state: ReduxState; rejectWithValue: any }
>('series/fetchSeriesLanding', async (series, { getState, rejectWithValue }) => {
   const { skip, limit, canonicalName } = series

   try {
      let feedAPIEndpoint = `${process.env.NEXT_PUBLIC_BASE_URL}/api/secure/series?skip=${skip}&limit=${limit}`
      feedAPIEndpoint += canonicalName?.length ? `&canonicalName=${canonicalName}` : `&page=series`
      const feedResponse = await fetch(feedAPIEndpoint, {
         cache: 'no-store',
      })

      const feedResponseBody = await feedResponse.json()

      const {
         series: { seriesLanding },
      } = getState()

      if ('data' in feedResponseBody && feedResponseBody?.data?.rails?.length > 0) {
         return [...seriesLanding, ...seriesHelperInstance.createSeriesFeedData(feedResponseBody?.data?.rails)]
      }

      return []
   } catch (err: any) {
      return rejectWithValue(err)
   }
})

type FetchSeriesFeedAsyncQueueArgs = { skip: number; limit: number; canonicalName: string }

export const fetchSeriesFeedAsyncQueue =
   ({ skip, limit, canonicalName }: FetchSeriesFeedAsyncQueueArgs) =>
   async (dispatch: ReduxDispatch) => {
      await dispatch({
         type: fetchSeriesAsync.pending.type,
         meta: { queue: true },
      })

      try {
         const response = await dispatch(fetchSeriesAsync({ skip, limit, canonicalName }))
         await dispatch({
            type: fetchSeriesAsync.fulfilled.type,
            payload: response.payload,
            meta: { queue: true },
         })
      } catch (error: any) {
         await dispatch({
            type: fetchSeriesAsync.rejected.type,
            error: error.message,
            meta: { queue: true },
         })
      }
   }
