// redux core
import { createSlice, type PayloadAction } from '@reduxjs/toolkit'

// types
import { SharedSliceStateProps, SubscriptionProps } from './shared.d'
import { PAYMENT_STATUS } from '@/middleware.d'

export const sharedInitialState: SharedSliceStateProps = {
   isShowCastCrew: false,
   castCrewTabIndex: '0',
   status: 'idle',
   isAuthenticated: false,
   subscription: {} as SubscriptionProps,
   paymentStatus: PAYMENT_STATUS.NUETRAL,
   isNoContent: false,
   translationStatus: 'idle',
   translationAsyncError: null,
   translation: {},
   returnURL: '',
   userTrackSetting: {},
   userLastActivityTime: null,
   isAutoPlayed: false,
   skipBumper: false,
   isPlanChangeAction: false,
   planChangeStatus: PAYMENT_STATUS.NUETRAL,
   maturityAccessAllowedUrl: '',
   allowUpdatingParentalControl: false,
   parentalControlAuthorised: false,
   didHaveParentalUrlParam: false,
   newEmail: '',
   changePinRouteState: false,
}

export const sharedSlice = createSlice({
   name: 'shared',
   initialState: sharedInitialState,
   reducers: {
      toggleCastCrew: (state, actions: PayloadAction<boolean>) => {
         state.isShowCastCrew = actions.payload
      },
      recordCastCrew: (state, actions: PayloadAction<string>) => {
         state.castCrewTabIndex = actions.payload
      },
      recordSubscriptionPlan: (state, actions: PayloadAction<SubscriptionProps>) => {
         state.subscription = actions.payload
      },
      recordAuthenticatedStatus: (state, actions: PayloadAction<boolean>) => {
         state.isAuthenticated = actions.payload
      },
      recordPaymentStatus: (state, actions: PayloadAction<string>) => {
         state.paymentStatus = actions.payload
      },
      recordPlayerNoContent: (state, { payload }) => {
         state.isNoContent = payload
      },
      recordReturnUrl: (state, action) => {
         state.returnURL = action.payload
      },
      recordUserTrackSetting: (state, action) => {
         state.userTrackSetting = action.payload
      },
      recordUserLastActivityTime: (state, action) => {
         state.userLastActivityTime = action.payload
      },
      recordIsAutoPlayed: (state, action) => {
         state.isAutoPlayed = action.payload
      },
      recordSkipBumper: (state, action) => {
         state.skipBumper = action.payload
      },
      recordPlanChangeAction: (state, action) => {
         state.isPlanChangeAction = action.payload
      },
      recordPlanChangeStatus: (state, actions: PayloadAction<string>) => {
         state.planChangeStatus = actions.payload
      },
      recordMaturityAccessAllowedUrl: (state, actions: PayloadAction<string>) => {
         state.maturityAccessAllowedUrl = actions.payload
      },
      recordAllowUpdatingParentalControl: (state, actions: PayloadAction<boolean>) => {
         state.allowUpdatingParentalControl = actions.payload
      },
      recordParentalControlAuthorised: (state, actions: PayloadAction<boolean>) => {
         state.parentalControlAuthorised = actions.payload
      },
      recordDidHaveParentalUrlParam: (state, actions: PayloadAction<boolean>) => {
         state.didHaveParentalUrlParam = actions.payload
      },
      recordNewEmail: (state, action) => {
         state.newEmail = action.payload
      },
      recordChangePinRouteState: (state, actions: PayloadAction<boolean>) => {
         state.changePinRouteState = actions.payload
      },
      resetSharedSlice: () => sharedInitialState,
   },
})
