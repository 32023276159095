import { createAppAsyncThunk } from '@/store/redux/createAppAsyncThunk'
import { ReduxState, ReduxDispatch } from '../../../store'
import JsonError from '@/app/api/_helpers/common/JsonError'
import FeedHelper from '@/app/(auth)/home/_helper/FeedHelper'
const feedHelperInstance = new FeedHelper()
export const fetchResumeWatchingAsync = createAppAsyncThunk<any, {}, { state: ReduxState; rejectWithValue: any }>(
   'feed/fetchResumeWatchingAsync',
   async (resumeWatchingAsync: {}, { getState, rejectWithValue }) => {
      try {
         const feedResponse = await fetch(`${process.env.NEXT_PUBLIC_BASE_URL}/api/secure/resumewatching`, {
            cache: 'no-store',
         })
         if (feedResponse.status === 502) {
            throw new JsonError(false, feedResponse.status, feedResponse.statusText, {
               code: '',
               message: '',
            })
         }

         const feedResponseBody = await feedResponse.json()
         if (feedResponseBody?.success === false) {
            throw { ...feedResponseBody }
         }

         if ('data' in feedResponseBody && feedResponseBody?.data) {
            /* eslint-disable camelcase */
            const computedResumeWatching = feedHelperInstance.createContinueWatchingData({
               title: 'Continue Watching',
               rail_type: 'continue_watching',
               elements: feedResponseBody.data,
            })

            return computedResumeWatching
         }

         return []
      } catch (err: any) {
         return rejectWithValue(err)
      }
   },
)

export const fetchResumeWatchingAsyncQueue = () => async (dispatch: ReduxDispatch) => {
   await dispatch({
      type: fetchResumeWatchingAsync.pending.type,
      meta: { queue: true },
   })

   try {
      const response = await dispatch(fetchResumeWatchingAsync({}))
      await dispatch({
         type: fetchResumeWatchingAsync.fulfilled.type,
         payload: response.payload,
         meta: { queue: true },
      })
   } catch (error: any) {
      await dispatch({
         type: fetchResumeWatchingAsync.rejected.type,
         error: error.message,
         meta: { queue: true },
      })
   }
}
