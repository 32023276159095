// redux core
import { createSlice, type PayloadAction } from '@reduxjs/toolkit'

// thunk
import { fetchCapraConfigAsync } from './configThunk'

// types
import { TConfigSliceState, TAppConfig } from './config.d'

export const configInitialState: TConfigSliceState = {
   status: 'idle',
   details: {} as TAppConfig,
   asyncError: null,
}

export const configSlice = createSlice({
   name: 'config',
   initialState: configInitialState,
   reducers: {
      resetConfigSlice: () => configInitialState,
      resetConfigAsync: (state) => {
         state.asyncError = null
      },
   },
   extraReducers: (builder) => {
      builder
         .addCase(fetchCapraConfigAsync.pending, (state) => {
            state.status = 'loading'
         })
         .addCase(fetchCapraConfigAsync.fulfilled, (state, action) => {
            state.status = 'idle'
            const {
               payload: { data },
            } = action
            state.details = data
         })
         .addCase(fetchCapraConfigAsync.rejected, (state, action) => {
            state.status = 'failed'
            state.asyncError = action.payload
         })
   },
})
