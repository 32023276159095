import { createAppAsyncThunk } from '@/store/redux/createAppAsyncThunk'
import { ReduxState, ReduxDispatch } from '../../../store'
import JsonError from '@/app/api/_helpers/common/JsonError'

export const fetchPromoProgressAsync = createAppAsyncThunk<
   any,
   { assetIds: Array<number>; railRowIndex: number; seriesRef: string },
   { state: ReduxState; rejectWithValue: any; dispatch: ReduxDispatch }
>('series/fetchPromoProgressAsync', async (detail, { getState, rejectWithValue, dispatch }) => {
   let { assetIds, railRowIndex, seriesRef } = detail
   try {
      const response = await fetch(
         `${process.env.NEXT_PUBLIC_BASE_URL}/api/secure/series/watchprogress?seriesRef=${seriesRef}`,
      )
      await dispatch(fetchSeriesDetailAsync({ id: assetIds[0] }))

      const { data } = await response.json()
      const {
         series: { seriesLanding, seriesDetail },
      } = getState()

      return { promoProgress: data, currentPromoRail: seriesLanding[railRowIndex], railRowIndex, seriesDetail }
   } catch (err: any) {
      return rejectWithValue(err)
   }
})

export const fetchSeriesDetailAsync = createAppAsyncThunk(
   'feed/fetchSeriesDetail',
   async (series: { id: number }, { rejectWithValue }) => {
      const { id } = series
      try {
         const feedResponse = await fetch(`${process.env.NEXT_PUBLIC_BASE_URL}/api/secure/series/${id}`)
         if (feedResponse.status === 502) {
            throw new JsonError(false, feedResponse.status, feedResponse.statusText, {
               code: '',
               message: '',
            })
         }
         const feedResponseBody = await feedResponse.json()

         if (feedResponseBody?.success === false) {
            throw { ...feedResponseBody }
         }
         return { feedResponseBody }
      } catch (err: any) {
         return rejectWithValue(err)
      }
   },
)

type FetchPromoRailWatchprogressAsyncArgs = {
   assetIds: Array<number>
   railRowIndex: number
   seriesRef: string
}

export const fetchPromoProgressSeriesAsyncQueue =
   ({ assetIds, railRowIndex, seriesRef }: FetchPromoRailWatchprogressAsyncArgs) =>
   async (dispatch: ReduxDispatch) => {
      await dispatch({
         type: fetchPromoProgressAsync.pending.type,
         meta: { queue: true },
      })

      try {
         const response = await dispatch(fetchPromoProgressAsync({ assetIds, railRowIndex, seriesRef }))
         await dispatch({
            type: fetchPromoProgressAsync.fulfilled.type,
            payload: response.payload,
            meta: { queue: true },
         })
      } catch (error: any) {
         await dispatch({
            type: fetchPromoProgressAsync.rejected.type,
            error: error.message,
            meta: { queue: true },
         })
      }
   }
