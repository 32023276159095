import CommonHelper from '@/app/_helper/global-helper/CommonHelper'
import InformationPageHelper from '@/app/_helper/global-helper/InformationPageHelper'
import PosterHelper from '@/app/_helper/rail-helper/PosterHelper'

import { IFeedRail, IPreCompiledPoster } from './SeriesHelper'
import ContinueWatchingHelper from '@/app/_helper/rail-helper/ContinueWatchingHelper'
import { IContinueWatchingRail, PreCompiledThematicPosterProps } from '../../../home/_helper/FeedHelper/FeedHelper'
import ThematicHelper from '@/app/_helper/rail-helper/ThematicHelper'
import CollectionsHelper from '@/app/_helper/rail-helper/CollectionsHelper'
import SingularPromoHelper from '@/app/_helper/rail-helper/SingularPromo'

export default class SeriesHelper {
   commonHelper!: CommonHelper
   informationPageHelper!: InformationPageHelper
   posterHelper!: PosterHelper
   continueWatchingHelper!: ContinueWatchingHelper
   thematicHelper!: ThematicHelper
   collectionsHelper!: CollectionsHelper
   singularPromoHelper!: SingularPromoHelper
   static instance: SeriesHelper | null = null
   readonly feedResponse = [] as IFeedRail[]

   constructor() {
      if (SeriesHelper.instance) {
         return SeriesHelper.instance
      }
      this.commonHelper = new CommonHelper()
      this.informationPageHelper = new InformationPageHelper()
      SeriesHelper.instance = this
   }

   /**
    *
    * @param {object} feedResponse - feed api response
    * @returns {Array} Compiled, UI declarative collection of rail array
    */
   createSeriesFeedData(feedResponse: IFeedRail[]) {
      // loop through the rails and generate tailored data for feed page
      let overallRails = []
      for (let railItem of feedResponse) {
         switch (railItem.rail_type) {
            case CommonHelper.RAIL_VARIANT.POSTER_HORIZONTAL:
               this.posterHelper = new PosterHelper(railItem as IPreCompiledPoster)
               overallRails.push(this.posterHelper.tailoredCollection())
               break

            case CommonHelper.RAIL_VARIANT.POSTER_VERTICAL:
               this.posterHelper = new PosterHelper(railItem as IPreCompiledPoster)
               overallRails.push(this.posterHelper.tailoredCollection())
               break

            case CommonHelper.RAIL_VARIANT.EPISODIC_RAIL:
               this.continueWatchingHelper = new ContinueWatchingHelper(railItem as IContinueWatchingRail)
               overallRails.push(
                  this.continueWatchingHelper.tailoredCollection(CommonHelper.RAIL_VARIANT.EPISODIC_RAIL),
               )
               break
            case CommonHelper.RAIL_VARIANT.THEMATIC:
               this.thematicHelper = new ThematicHelper(railItem as PreCompiledThematicPosterProps)
               overallRails.push(this.thematicHelper.tailoredCollection())
               break
            case CommonHelper.RAIL_VARIANT.COLLECTIONS:
               this.collectionsHelper = new CollectionsHelper(railItem as any)
               overallRails.push(this.collectionsHelper.tailoredCollection())
               break
            case CommonHelper.RAIL_VARIANT.SINGULAR_PROMO:
               this.singularPromoHelper = new SingularPromoHelper(railItem as any)
               overallRails.push(this.singularPromoHelper.tailoredCollection())
               break
         }
      }

      return overallRails
   }
}
