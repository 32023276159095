import CommonHelper from '@/app/_helper/global-helper/CommonHelper'
import InformationPageHelper from '@/app/_helper/global-helper/InformationPageHelper'

import { getTransformedImageUrl } from '@/app/_utility/image-transformation'

import fallbackMovieBanner from '@/public/images/fallback-banner-movie.png'

// types
import { IPreCompiledMovie, IPreCompiledExtras, IPreCompiledExtrasProgress } from './MovieInformationPageHelper'

export default class MovieInformationPageHelper {
   commonHelper!: CommonHelper
   informationPageHelper!: InformationPageHelper

   static instance: MovieInformationPageHelper | null = null

   constructor() {
      if (MovieInformationPageHelper.instance) {
         return MovieInformationPageHelper.instance
      }
      this.commonHelper = new CommonHelper()
      this.informationPageHelper = new InformationPageHelper()
      MovieInformationPageHelper.instance = this
   }

   createInfomationPageData(preCompiledMovie: IPreCompiledMovie) {
      const poster = (() => {
         return preCompiledMovie?.images?.hero_image?.indexOf('res.cloudinary.com') !== -1
            ? getTransformedImageUrl(
                 preCompiledMovie?.images?.hero_image,
                 CommonHelper.IMAGE_SIZES['hero_carousel']['high']['width'],
                 CommonHelper.IMAGE_SIZES['hero_carousel']['high']['height'],
                 CommonHelper.IMAGE_SIZES['hero_carousel']['high']['borderRadius'],
              )
            : ''
      })()

      const movieLogo = (() => {
         return preCompiledMovie?.images?.logo?.indexOf('res.cloudinary.com') !== -1
            ? getTransformedImageUrl(
                 preCompiledMovie?.images?.logo,
                 CommonHelper.IMAGE_SIZES['logo']['high']['width'],
                 CommonHelper.IMAGE_SIZES['logo']['high']['height'],
                 CommonHelper.IMAGE_SIZES['logo']['high']['borderRadius'],
                 true,
              )
            : ''
      })()

      const timelineAnnouncement = (() => {
         return this.informationPageHelper.computeTimeline(
            preCompiledMovie.playback_start_date,
            preCompiledMovie.playback_end_date,
            preCompiledMovie.coming_soon_date,
            preCompiledMovie.isOriginal,
         )
      })()

      const castCrew = (() => {
         return [
            {
               title: 'Info',
               description: preCompiledMovie.synopsis,
            },
            {
               title: 'Cast & Crew',
               description: this.informationPageHelper.compiledCastCrewList(preCompiledMovie),
            },
         ]
      })()

      const hasNoCastCrew = (() => {
         return (
            preCompiledMovie.synopsis?.trim().length === 0 &&
            this.informationPageHelper.compiledCastCrewList(preCompiledMovie).length === 0
         )
      })()

      const isTrailer = (() => {
         return (
            !preCompiledMovie.hasVideo ||
            preCompiledMovie.content_type?.trim()?.toLowerCase() === 'movie_trailer' ||
            CommonHelper.moment(preCompiledMovie.playback_start_date).utc().isAfter(CommonHelper.moment())
         )
      })()
      const releaseGenre = (() => {
         return [
            !!preCompiledMovie?.duration && this.informationPageHelper.getRoundedDuration(preCompiledMovie.duration!),
            !!preCompiledMovie?.release_date &&
               this.commonHelper.convertDateWithCustomFormat(preCompiledMovie?.release_date, 'YYYY'),
            !!preCompiledMovie?.genre && preCompiledMovie.genre[0],
            !!preCompiledMovie?.rating && preCompiledMovie.rating,
         ]
            .filter(Boolean)
            .join(' · ')
      })()

      const isMovieContentExpired = (() => {
         return CommonHelper.moment(preCompiledMovie?.playback_end_date)
            .utc()
            .isBefore(CommonHelper.moment().utc())
      })()

      return {
         id: preCompiledMovie.id,
         poster,
         fallbackPoster: fallbackMovieBanner,
         mediaQualityLabel: this.informationPageHelper.createMediaQualityLabels(preCompiledMovie.properties!),
         movieLogo,
         movieTitle: preCompiledMovie.name,
         movieDescription: preCompiledMovie.synopsis,
         movieDescriptionLength: preCompiledMovie.synopsis?.split('').length,
         timelineAnnouncement,
         castCrew,
         hasNoCastCrew,
         isTrailer,
         hasExtra: preCompiledMovie.hasExtra,
         hasVideo: preCompiledMovie.hasVideo,
         hasTrailer: preCompiledMovie.hasTrailer,
         trailerId: preCompiledMovie.trailerID,
         trailerTitle: preCompiledMovie.trailerName,
         releaseGenre,
         genre: preCompiledMovie.genre,
         isMovieContentExpired,
         categoryTags: preCompiledMovie.category_tags,
         rating: preCompiledMovie.rating,
         movieRef: preCompiledMovie.movie_ref,
      }
   }

   createMovieExtras(preCompiledExtras: IPreCompiledExtras[]) {
      let computedExtras = []

      for (let i = 0; i < preCompiledExtras.length; i++) {
         const poster = (() => {
            return (
               getTransformedImageUrl(
                  preCompiledExtras[i]['custom_fields']['still_frame'] ??
                     preCompiledExtras[i]['custom_fields']['h_poster'],
                  CommonHelper.IMAGE_SIZES['still_frame'].high.width,
                  CommonHelper.IMAGE_SIZES['still_frame'].high.height,
                  CommonHelper.IMAGE_SIZES['still_frame'].high.borderRadius,
               ) ?? ''
            )
         })()

         const strTimeLeft = (() => {
            return (
               preCompiledExtras[i]?.progress > 0
                  ? this.informationPageHelper.calculateTimeLeft(
                       preCompiledExtras[i]?.progress,
                       preCompiledExtras[i]['videoDuration'],
                    )
                  : ''
            )
               ?.replace('min', 'minute')
               .replace('hrs', 'hours')
         })()

         const isExtraContentExpired = (() => {
            return CommonHelper.moment(preCompiledExtras[i]['custom_fields']['playback_end_date'])
               .utc()
               .isBefore(CommonHelper.moment().utc())
         })()

         computedExtras.push({
            watchId: preCompiledExtras[i]['id'],
            poster,
            name: preCompiledExtras[i]['name'],
            description: preCompiledExtras[i]['description'],
            duration: this.informationPageHelper.getRoundedDuration(preCompiledExtras[i]['duration']),
            timeLeft: strTimeLeft,
            isExtraContentExpired,
            rating: preCompiledExtras[i]['custom_fields']['rating'],
         })
      }

      return computedExtras
   }

   createMovieExtrasProgress(preCompiledExtrasProgress: IPreCompiledExtrasProgress[]) {
      const strTimeLeft =
         preCompiledExtrasProgress[0]?.progress > 0
            ? this.informationPageHelper.calculateTimeLeft(
                 preCompiledExtrasProgress[0]?.progress,
                 preCompiledExtrasProgress[0].videoDuration,
              )
            : ''

      return {
         progress: preCompiledExtrasProgress[0]?.progress ?? 0,
         videoDuration: preCompiledExtrasProgress[0]?.videoDuration ?? 0,
         watchPercentage: preCompiledExtrasProgress[0]?.watchPercentage ?? 0,
         timeLeft: strTimeLeft ? strTimeLeft.replace('min', 'minute').replace('hrs', 'hours') : '',
      }
   }
}
